import r from "@/utils/request";
const request = function () {
  return r(...arguments).then(res => ({ data: res, status: 200 })).catch(e => (e.disableDefaultHandler && e.disableDefaultHandler(), {errcode: 5001}))
}

export default {
  getAwemeDetail({id}) {
    return request({
      url: '/aweme/'+id,
      method: 'GET'
    })
  },
  getAwemeHotwords({id}) {
    return request({
      url: '/aweme-comment/'+id+'/hotwords',
      method: 'GET'
    })
  },
  getAwemeAvg({id}) {
    return request({
      url: '/aweme/author-aweme-avg/',
      method: 'GET',
      params:{
        author_id:id
      }
    })
  },
  getFansStat({id}) {
    return request({
      url: '/aweme-comment/'+id+'/fans-analysis',
      method: 'GET'
    })
  },
  /**
   * 视频评论
   * @param id
   * @returns {AxiosPromise}
   */
  getComment({ id, params={} }) {
    return request({
      url: '/aweme-comment/'+id+'/comments',
      method: 'GET',
      params
    })
  },
  /**
   * 视频情感偏向
   */
  getVideoEmotion({id}) {
    return request({
      url: '/aweme-comment/'+id+'/sentiment-analysis',
      method: 'GET'
    })
  }
}
