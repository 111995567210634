import request from "@/utils/request";
import exportExcel from "@/utils/exportExcel";

export default {
  // 获取标签列表
  getTagList(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/label/list`,
      method: "get",
      params: params
    });
  },
  // 获取标签详情
  getLabelData(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/label/display`,
      method: "get",
      params: params
    });
  },
  // 编辑标签
  saveLabel(data) {
    return request({
      baseURL: "/bfz-dy",
      url: `/label/save`,
      method: "put",
      data
    });
  },
  // 诊断标签
  getLabelDiagnose(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/diagnose/label`,
      method: "get",
      params
    });
  },
  // 诊断意见
  getLabelSuggestion(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/diagnose/suggestion`,
      method: "get",
      params
    });
  },
  // 添加/编辑诊断意见
  saveSuggestion(data) {
    return request({
      baseURL: "/bfz-dy",
      url: `/diagnose/suggest-save`,
      method: "put",
      data
    });
  },
  //标签回显
  getLabelDisplay(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `diagnose/label-display`,
      method: "get",
      params
    });
  },
  //标签筛选结果
  getLabelDiagnoseData(data) {
    return request({
      baseURL: "/bfz-dy",
      url: `/diagnose/sift-dealer`,
      method: "post",
      data
    });
  },
  //选择诊断标签回显
  getSiftDisplay(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/diagnose/sift-display`,
      method: "GET",
      params
    });
  },
  //导出标签筛选结果
  exportLabelDiagnoseData(data) {
    return exportExcel({
      baseURL: "/bfz-dy",
      url: `/diagnose/sift-dealer-export`,
      method: "post",
      data
    });
  },
  // 经销商详情 保持/编辑标签
  saveDiagnoseLabel(data) {
    return request({
      baseURL: "/bfz-dy",
      url: `/diagnose/label-save`,
      method: "put",
      data
    });
  },
};
