
var context = require.context('./', false,/\.js$/);
let services = context.keys().filter(item => item !== './index.js')

let serviceMap = {}
var reg = new RegExp( '(\.\/)|(\.js)' , "g" )
services.forEach(s => {
    serviceMap[s.replace(reg,'')] = context(s).default;
});
export default serviceMap;
