<template>
  <a-upload
    :multiple="true"
    :accept="acceptList"
    :custom-request="customRequest"
    :before-upload="checkUpload"
    :file-list="fileList"
    @change="handleChange"
    :remove="handleRemove"
  >
    <a-button type="primary" :size='btnSize' :disabled='fileList.length ==limitFileLength && isDisabled'>
      <a-icon type="upload" />
      {{ uploadText }}
    </a-button>
  </a-upload>
</template>
<script>
import ossProtype from "@/common/oss.js";
import moment from "moment";
import utils from "@/common/util.js";

let OSS = require("ali-oss");
export default {
  props: {
    //允许上传的类型
    acceptList: {
      type: String,
      default: ".xlsx,.xlsm,.xml,.xlw,.xls,.xlsb,.xlt,.xlr",
    },
    //回显上传的文件
    hasFileList: {
      type: Array,
      default: () => [],
    },
    //允许上传的文件的大小
    limitSize: {
      type: Number,
      default: 10000,
    },
    //允许上传的文件的个数
    limitFileLength: {
      type: Number,
      default: 1,
    },
    //是否打开新窗口预览
    isOpenPreview: {
      type: Boolean,
      default: false,
    },
    //判断后缀显示缩略图
    suffixList: {
      type: Array,
      default: () => ["mp4", "mov", "m4v"],
    },
    // 上传样式
    listType: {
      type: String,
      default: "picture-card",
    },
    // 是否显示已上传文件
    // showUploadList: {
    //   type: Boolean,
    //   default: true
    // },
    // 来自哪里的上传
    isForm: {
      type: String,
      default: "",
    },
    // 上传的按钮文案
    uploadText: {
      type: String,
      default: "手动选择",
    },
    //是否控制上传按钮禁用否
    isDisabled: {
      type: Boolean,
      default: true,
    },
    // 上传按钮大小
    btnSize:{
            type: String,
      default: 'default',
    }
  },
  data() {
    return {
      fileList: [],
      custom_request_type: "xls",
    };
  },
  watch: {
    "hasFileList": {
      handler: function (data) {
        this.fileList = data;
      },
      immediate: true,
    },
  },
  mounted() {
    this.initOSSAuth();
  },
  methods: {
    initOSSAuth() {
      let judge_url = window.location.href
        .split("/")
        .indexOf("baifuzhang.afanticar.com");
      // let bucket = judge_url != -1 ? 'fhl-ai-video' : 'fhl-ai-video-test';
      let bucket = judge_url != -1 ? "afanti-bfz" : "afanti-bfz-dev";
      ossProtype(bucket).then((res) => {
        this.client = res;
      });
    },
    async customRequest({file, onSuccess}) {
      const time = moment()._d;
      // const date = `${ time.getFullYear() }-${ time.getMonth() + 1 }-${ time.getDate() }`;
      const date = new Date().getTime();
      const url = this.custom_request_type + "/" + date + "/" + file.name;
      return new Promise((resolve, reject) => {
        this.client
          .put(url, file)
          .then((data) => {
            onSuccess(data, data.url);
            resolve(data);
          })
          .catch((error) => {
            this.initOSSAuth();
            this.$message.error(`请重新上传或者刷新页面`);
            reject(error);
          });
      });
    },
    checkUpload(file) {
      let fileType = file.name.split(".");
      const fileDate = fileType.slice(-1);
      const isXls = true
      // const isXls =
      //   ["xlsx", "xlsm", "xml", "xlw", "xls", "xlsb", "xlt", "xlr"].indexOf(
      //     fileDate[0],
      //   ) !== -1;
      // if (!isXls) {
      //   this.$message.error("上传的文件格式只能为表格文档相关格式!");
      // }
      // console.log('file.siz:',file.size/1024/1024)
      const isLimit = file.size / 1024 / 1024 < this.limitSize;
      if (!isLimit) {
        this.$message.error(`文件格式不超过${this.limitSize}MB!`);
      }

      // isXls && isLimit ? (this.custom_request_type = "xls") : "";

      return isXls && isLimit;
    },
    handleChange(info) {
      if (info.file.status) {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-this.limitFileLength);

        if (info.file.status === "done") {
          fileList.map((file) => {
            // console.log(utils.fileSuffixTypeUtil(file.xhr, this.suffix_list));
            file.thumbUrl = utils.fileSuffixTypeUtil(file.xhr, this.suffix_list)
              ? this.thumb_url
              : file.xhr;
          });
          this.showUploadList = false;
          this.$emit("handleUrl", info.file);
        }

        this.fileList = fileList;
      } else {
        return;
      }
    },
    handleRemove(file) {
      this.$emit("handleUrl", file, 3);
    },
  },
};
</script>
