import request from '@/utils/request';

export default {

  query (data) {
    return request({
      url: '/aweme/info',
      method: 'GET',
      params: data
    }).then((res) => {
      return {
        data: res.data.list,
        total: res.data.pagination.total
      };
    });
  },
  get (id) {
    return request({
      url: '/aweme/get/' + id,
      method: 'GET'
    });
  },
  queryStatistics (data) {
    return request({
      url: '/aweme/queryStatistics',
      method: 'POST',
      data
    });
  },
  queryStatisticsFullPaging (data) {
    return request({
      url: '/aweme/queryStatisticsFullPaging',
      method: 'POST',
      data
    });
  },
  queryAnalysis (data) {
    return request({
      url: '/aweme/queryAnalysis',
      method: 'POST',
      data
    });
  },
  findGap (data) {
    return request({
      url: '/aweme/findGap',
      method: 'POST',
      data
    });
  },
  queryGap (data) {
    return request({
      url: '/aweme/queryGap',
      method: 'POST',
      data
    });
  },
  queryGapFullPaging (data) {
    return request({
      url: '/aweme/queryGapFullPaging',
      method: 'POST',
      data
    });
  },
  queryComment (data) {
    return request({
      url: '/aweme/queryComment',
      method: 'POST',
      data
    });
  },
  exportGrossExcel (data) {
    return request({
      url: '/aweme/exportGrossExcel/',
      method: 'POST',
      responseType: 'blob',
      data
    });
  },
  exportIncrementExcel (data) {
    return request({
      url: '/aweme/exportIncrementExcel/',
      method: 'POST',
      responseType: 'blob',
      data
    });
  },
  exportReviewExcel (data) {
    return request({
      url: '/aweme/exportReviewExcel/',
      method: 'POST',
      responseType: 'blob',
      data
    });
  },
  exportShortListedAweme (data) {
    return request({
      url: '/aweme/exportShortListedAweme/',
      method: 'POST',
      responseType: 'blob',
      data
    });
  }
};
