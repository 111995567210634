/**
 * Created by zz on 2018/6/3.
 */
import MathUtil from './MathUtil';
import auth from './auth';

import request from './request';
import bus from './bus';
import Dict from './Dict';
import KeyCode from './KeyCode';
import Tool from './Tool';

import validator from './validator';
import indexDB from './indexDB';
export { auth, bus, validator, request, MathUtil, Dict, KeyCode, Tool, indexDB };

// 下载Excel
export const downloadExcel = (blob, fileName = '资料') => {
  const objUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = objUrl;
  a.download = `${fileName}.xlsx`;
  a.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  window.URL.revokeObjectURL(blob);
};

// 根据链接下载文件
export const downloadByUrl = (url) => {
  const a = window.document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.style.display = 'none';
  a.setAttribute('download', '');
  window.document.body.appendChild(a);
  a.click();
  window.document.body.removeChild(a);
};

export function removeDuplicatesByField(arr, field) {
  const uniqueMap = new Map();
  const result = [];

  arr.forEach((item) => {
    const fieldValue = item[field];
    if (!uniqueMap.has(fieldValue)) {
      uniqueMap.set(fieldValue, true);
      result.push(item);
    }
  });

  return result;
}

export function highlightString(inputString, ranges, color = 'yellow') {
  if (!inputString || !ranges || !Array.isArray(ranges)) {
    return inputString;
  }

  // Sort ranges in descending order to avoid interference while modifying the string
  ranges.sort((a, b) => b[0] - a[0]);

  // Iterate through each range and apply the highlight
  for (const range of ranges) {
    const [start, end] = range;

    // Check if the range is valid
    if (start < 0 || end >= inputString.length || start > end) {
      continue;
    }

    // Highlight the substring
    const highlightedPart = `<span style="background-color: ${color}">${inputString.substring(start, end + 1)}</span>`;

    // Replace the original substring with the highlighted one
    inputString = inputString.substring(0, start) + highlightedPart + inputString.substring(end + 1);
  }

  return inputString;
}
