import request from '@/utils/request';

const module = '/hjk';
export default {
  // 获取视频列表
  getVideoDealerList(params) {
    return request({
      baseURL: module,
      url: `/cac/publicPphtController/aweme/statistics/list`,
      method: 'GET',
      params,
    });
  },
};
