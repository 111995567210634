import Layout from "../pages/layout";

/**
 * 审核管理
 */
 export default {
  path: "/auditManage",
  name: "auditManage",
  component: Layout,
  meta: {title: "审核管理", icon: "block"},
  children: [
    // 审核列表
    {
      path: "auditList",
      name: "auditList",
      component: () => import("@/pages/app/auditManage/auditList"),
      meta: {title: "审核列表"}
    },

    // 审核配置
    {
      path: "auditConfig",
      name: "auditConfig",
      component: () => import("@/pages/app/auditManage/auditConfig"),
      meta: {title: "审核配置"}
    },

    // 审核配置日志
    {
      path: "auditConfigLog",
      name: "auditConfigLog",
      component: () => import("@/pages/app/auditManage/auditConfigLog"),
      meta: {title: "审核配置日志"},
      hidden: true
    }
  ]
}
