import request from '@/utils/request'
import exportExcel from '@/utils/exportExcel'

export default {
  // 获取分享链接
  fetchShareUrl(params){
    return request({
      baseURL: '/bfz-dy',
      url:`/aweme/submit/link/task`,
      method:'POST',
      data:params
    })
  },
  // 获取短视频列表
  fetchAwemeList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/aweme/list`,
      method: 'GET',
      params
    })
  },
  // 导出短视频列表
  exportAwemeList(params) {
    return exportExcel({
      baseURL: '/bfz-dy',
      url: `/aweme/list-export`,
      method: 'GET',
      params
    })
  },
  // 获取经销商公共分组下拉
  fetchDealerGroupOptions(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/aweme/dealer-group-options`,
      method: 'GET',
      params
    })
  },
  // 获取播主公共分组下拉
  fetchAuthorGroupOptions(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/aweme/author-group-options`,
      method: 'GET',
      params
    })
  },
  // 获取我的短视频分组列表
  fetchMyAwemeGroupList() {
    return request({
      baseURL: '/bfz-dy',
      url: `/aweme-favorite-group/list`,
      method: 'GET'
    })
  },
  // 收藏短视频
  collectAweme(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/aweme-favorite-group/collect`,
      method: 'POST',
      data: params
    })
  },
  // 新建短视频分组
  addAwemeGroup(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/aweme-favorite-group/save`,
      method: 'POST',
      data: params
    })
  },
  // 作品列表-短视频概况
  fetchAuthorAwemeStatistics(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/aweme/statistics`,
      method: 'GET',
      params
    })
  },
  // 作品列表-短视频概况下视频分页数据
  fetchAuthorAwemeStatisticsList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/aweme/statistics/list`,
      method: 'GET',
      params
    })
  },
  // 作品列表-短视频列表数据导出
  exportAuthorAwemeList(params) {
    return exportExcel({
      baseURL: '/bfz-dy',
      url: `/aweme/excel`,
      method: 'GET',
      params
    })
  },
  //
  fetchAwemeDetailKS(params) {
    const { awemeId } = params

    return request({
      baseURL: '/bfz-ks',
      url: `/aweme/${awemeId}`,
      method: 'GET'
    })
  },
  //
  fetchAwemeAuthorDetailKS(params) {
    const { authorId } = params

    return request({
      baseURL: '/bfz-ks',
      url: `/author/${authorId}`,
      method: 'GET'
    })
  },
  //
  fetchAwemeAuthorAwemeAvgKS(params) {
    return request({
      baseURL: '/bfz-ks',
      url: `/aweme/author-aweme-avg`,
      method: 'GET',
      params
    })
  },
  //
  fetchAwemeHotwordsKS(params) {
    const { awemeId } = params

    return request({
      baseURL: `/bfz-ks`,
      url: `/aweme-comment/${awemeId}/hotwords`,
      method: 'GET'
    })
  },
  //
  fetchAwemeSentimentAnalysisKS(params) {
    const { awemeId } = params

    return request({
      baseURL: '/bfz-ks',
      url: `/aweme-comment/${awemeId}/sentiment-analysis`,
      method: 'GET'
    })
  },
  //
  fetchAwemeCommentKS(params) {
    const { awemeId, ...restParams } = params

    return request({
      baseURL: `/bfz-ks`,
      url: `/aweme-comment/${awemeId}/comments`,
      method: 'GET',
      params: restParams
    })
  }
}
