import request from '@/utils/request'

let baseModule = '/base'
const baseApi = {
    provinceUrl: baseModule + '/areas/v1',
    cityUrl: baseModule + '/areas/v1',
    brandUrl: baseModule + '/brands/v1',
    manufactorUrl: baseModule + '/series/brand-series',
}

let ccModule = '/content-center'
const ccApi = {
  liveDyList:  '/douyin/list',
  liveKsList:  '/kuaishou/list',
  liveAddOss:  '/editing-project-add-oss-resource',
}

export default {
    /**
     * 获取省市
     * level: 1省份，2城市
     *  */
    getProvinceList() {
        return request({
            url: baseApi.provinceUrl,
            method: 'get',
            params: {
                level:1
            }
        })
    },
    getCityList() {
        return request({
            url: baseApi.provinceUrl,
            method: 'get',
            params: {
                level:2
            }
        })
    },
    /**
     * 品牌列表
     **/  
    getBrandList() {
        return request({
            url: baseApi.brandUrl,
            method: 'get'
        })
    },
    /** 
     * 厂商列表
     */
    getManufactorList (params) {
        return request ({
            url: baseApi.manufactorUrl,
            method: 'get',
            params: params
        })
    },

    // 直播列表
    getLiveList(data) {
        let {
            type,
            params
        } = data
        return request({
            baseURL: '/content-center',
            headers: {
                Authorization: ''
            },
            transformRequest: [
                function(data, headers) {
                  delete headers.Authorization;
                  delete headers.Cookie;
                  return data;
                }
            ],
            url: type == 'douyin' ? ccApi.liveDyList : ccApi.liveKsList,
            method: 'get',
            params
        })
    },
    liveAddOss(params) {
        return request ({
            baseURL: '/content-center',
            headers: {
                Authorization: ''
            },
            transformRequest: [
                function(data, headers) {
                  delete headers.Authorization;
                  delete headers.Cookie;
                  return data;
                }
            ],
            url: ccApi.liveAddOss,
            method: 'post',
            data: params
        })
    },
    
}
