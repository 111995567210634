import Layout from "../pages/layout";

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}

/**
 * 创意
 */
export default {
  path: "/creativeIdea",
  name: "creativeIdea",
  component: Layout,
  meta: { title: "创意", icon: "picture" },
  children: [
    {
      path: "polymers",
      name: "polymers",
      component: RouteView,
      meta: {title: "聚合榜单", icon: "crown"},
      children: [
        {
          path: "video_list",
          name: "polymers:video_list",
          component: () => import("@/pages/app/polymers/video_list.vue"),
          meta: {title: "热门视频榜"}
        },
        {
          path: "car_list",
          name: "polymers:car_list",
          component: () => import("@/pages/app/polymers/car_list.vue"),
          meta: {title: "汽车榜"}
        },
        {
          path: "music_list",
          name: "polymers:music_list",
          component: () => import("@/pages/app/polymers/music_list.vue"),
          meta: {title: "音乐榜"}
        },
        {
          path: "dealer_list",
          name: "polymers:dealer_list",
          component: () => import("@/pages/app/polymers/dealer_list.vue"),
          meta: {title: "经销商热门榜"}
        },
        {
          path: "topic_list",
          name: "polymers:topic_list",
          component: () => import("@/pages/app/polymers/topic_list.vue"),
          meta: {title: "热点榜"}
        },
        {
          path: "topic_detail",
          name: "polymers:topic_detail",
          component: () => import("@/pages/app/polymers/topic_detail.vue"),
          meta: {title: "热点详情"},
          hidden: true
        },
        {
          path: "challenge_list",
          name: "polymers:challenge_list",
          component: () => import("@/pages/app/polymers/challenge_list.vue"),
          meta: {title: "挑战榜"}
        },
        {
          path: "challenge_detail",
          name: "polymers:challenge_detail",
          component: () => import("@/pages/app/polymers/challenge_detail.vue"),
          meta: {title: "挑战详情", noPadding: true}
        },
        {
          path: "dealer_detail",
          name: "polymers:dealer_detail",
          component: () => import("@/pages/app/polymers/dealer_deatil.vue"),
          meta: {title: "短视频榜详情", noPadding: true}
        },
        {
          path: "keywords_monitoring",
          name: "keywords_monitoring",
          component: () => import("@/pages/app/polymers/keywords_monitoring.vue"),
          meta: {title: "关键词监控列表"}
        },
        {
          path: "keywords_monitoring_detail",
          name: "polymers:keywords_monitoring_detail",
          component: () => import("@/pages/app/polymers/keywords_monitoring_detail.vue"),
          meta: {title: "关键词监控详情", noPadding: true, hidden: true}
        }
      ]
    },
    {
      path: "creativeCollection",
      name: "creativeCollection",
      component: RouteView,
      meta: { title: "创意采集", icon: "picture" },
      children: [
        {
          path: "materialCollection",
          name: "creativeCollection:materialCollection",
          component: () => import("@/pages/app/creativeCollection/materialCollection"),
          meta: {title: "素材采集"}
        },
        {
          path: "followAccount",
          name: "creativeCollection:followAccount",
          component: () => import("@/pages/app/creativeCollection/followAccount"),
          meta: {title: "关注帐号"}
        }
      ]
    }
  ]
}
