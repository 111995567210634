import request from '@/utils/request'

export default {
  // 获取风火轮用户列表
  fetchFhlUserList (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/dealer/list`,
      method: 'GET',
      params
    })
  },

  // 获取风火轮用户详情
  fetchFhlUserDetail (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/dealer/details`,
      method: 'GET',
      params
    })
  },

  // 获取媒体帐号列表
  fetchFhlUserAuthorList (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/dealer/authorList`,
      method: 'GET',
      params
    })
  },

  // 更新风火轮用户基本信息
  updateFhlUserBaseInfo (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/dealer/update`,
      method: 'PUT',
      data: params
    })
  },

  // 更新风火轮用户默认媒体帐号
  updateFhlUserDefaultAuthor (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/dealer/author`,
      method: 'PUT',
      data: params
    })
  },

  // 更新风火轮用户店端配置
  updateFhlUserStoreConfig (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/dealer/update/store_config`,
      method: 'PUT',
      data: params
    })
  },

  // 更新风火轮用户奖励政策
  updateFhlUserIncentivePolicy (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/dealer/update/incentive_policy`,
      method: 'PUT',
      data: params
    })
  },

  // 获取媒体帐号变更记录
  fetchAccountLog (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/dealer/update_maccount_log`,
      method: 'GET',
      params
    })
  },

  // 解绑风火轮跟微信的关系
  unbinding(params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/dealer/unbind`,
      method: 'DELETE',
      params
    })
  }
}
