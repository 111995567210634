<template>
  <a-result
    status="success"
    :title="title"
    :sub-title="`${$moment().format('YYYY-MM-DD hh:mm:ss')}`"
  >
  </a-result>
</template>
<script>
export default {
  data(){
    return{
      title:'抖音授权登录成功！'
    }
  },
  created(){
    console.log('this.$route.query.type',this.$route.query.type)
    if(this.$route.query.type==0){
      this.title='抖音授权登录成功！'
    }else{
      this.title='快手授权登录成功！'
    }
  }
}
</script>

