import request from '@/utils/request'
let baseModule = '/vkong-new'
export default {
    // 标签列表
    getTagList(params){
        return request({
            baseURL: baseModule,
            url:'/vk/tag/list',
            method:'get',
            params
        })
    },
    // 删除标签
    deleteTag(id){
        return request({
            baseURL: baseModule,
            url:`/vk/tag/${id}`,
            method:'delete',
            
        })
    },
    // 创建更新标签
    createAndEdit(data){
        return request({
            baseURL: baseModule,
            url:'/vk/tag/save-or-update',
            method:'post',
            data
        })
    },
    // 添加群聊列表
    addGroupChat(params) {
        return request({
            baseURL: baseModule,
            url:'/vk/tag-chatroom/list-chatroom',
            method:'get',
            params
        })
    },
    // 群广播列表
    getGroupMessage(params){
        return request({
            baseURL:baseModule,
            url:'/vk/msg-send-window/list',
            method:'get',
            params
        })
    },
    // 删除群发记录
    deleteGroupRecord(id){
        return request({
            baseURL:baseModule,
            url:`/vk/msg-send-window/${id}`,
            method:'DELETE',
        })
    },
    // 标签相关群信息
    tagAboutChatList(params){
        return request({
            baseURL:baseModule,
            url:`/vk/tag/${params.id}`,
            method:'get',
            params:{exclude_chatroom_ids:params.exclude_chatroom_ids},
        })
    },
    // 发送消息
    sendMessage(data){
       return request({
           baseURL:baseModule,
           url:'/vk/msg-send-record/send-immediately',
           method:'post',
           data
       })
    },
    // 创建广播
    createGroupMessage(data){
        return request({
            baseURL:baseModule,
            url:'/vk/msg-send-window/create',
            method:'post',
            data
        })
    },
    // 保存编辑广播
    saveEditGroup(data){
        return request({
            baseURL:baseModule,
            url:'/vk/msg-send-window/update',
            method:'post',
            data
        })
    },
    // 编辑广播初始记录
    editRecordGroup(params){
        return request({
            baseURL:baseModule,
            url:'/vk/msg-send-contacts/list-by-window',
            method:'get',
            params
        })
    },
    // 额度查询次数
    checkCount(){
        return request({
            baseURL:baseModule,
            url:'/vk/msg-send-record/residue-degree',
            method:'get',
        })
    },
    // 发送成功记录
    getSendRecord(params){
        return request({
            baseURL:baseModule,
            url:'/vk/msg-send-record/list',
            method:'get',
            params
        })
    },
    // 实时查询发送状态
    getSendStatus(params){
        return request({
            baseURL:baseModule,
            url:'/vk/msg-send-status/list',
            method:'get',
            params
        })
    },
    // 即时重试发送失败的信息
    againSend(params){
        return request({
            baseURL:baseModule,
            url:'/vk/msg-send-record/retry-send-immediately',
            method:'get',
            params
        })
    }
}