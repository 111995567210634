import request from '@/utils/request'

export default {

  query(data) {
    return request({
      url: '/live/query',
      method: 'POST',
      data
    })
  },
  get(id) {
    return request({
      url: '/live/get/' + id,
      method: 'GET'
    })
  },
  queryStatistics(data) {
    return request({
      url: '/live/queryStatistics',
      method: 'POST',
      data
    })
  },
  queryStatisticsFullPaging(data) {
    return request({
      url: '/live/queryStatisticsFullPaging',
      method: 'POST',
      data
    })
  },
  findGap(data) {
    return request({
      url: '/live/findGap/',
      method: 'POST',
      data
    })
  },
  queryComment(data) {
    return request({
      url: '/live/queryComment',
      method: 'POST',
      data
    })
  },
  exportExcel(data) {
    return request({
      url: '/live/exportExcel/',
      method: 'POST',
      responseType: 'blob',
      data
    })
  },
  exportReviewExcel(data) {
    return request({
      url: '/live/exportReviewExcel/',
      method: 'POST',
      responseType: 'blob',
      data
    })
  },
}
