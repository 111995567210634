import Vue from 'vue'
import Sticky from './sticky'
import TableSticky from './antd-table-sticky'

Vue.use(Sticky)

//快捷键指令
Vue.directive('keycode', {
  bind: function (el, binding, vnode) {

    el.setAttribute('keycode', binding.value)
  }
})
//权限指令
function flushDomPerm(el,binding,vnode){
  /*
  let type = (typeof binding.value)
  let flag = false;
  let permClass = 'permHidden';
  switch (type) {
    case 'string':
      flag = Vue.prototype.$hasPermission(binding.value);
      break;
    case 'object':
      let roles = binding.value.roles || [];
      let resCodes = binding.value.resources || [];
      if (roles.length > 0) {

        for (let i = 0; i < roles.length; i++) {
          let r = roles[i]
          if (Vue.prototype.$hasRole(r)) {
            flag = true;
            break;
          }
        }
      }
      if (resCodes.length > 1) {

        for (let i = 0; i < resCodes.length; i++) {
          let r = resCodes[i]
          if (Vue.prototype.$hasPermission(r)) {
            flag = true;
            break;
          }
        }
      }
      
  }
  console.log(binding.value+':'+el.getAttribute('perm')+':'+flag)
  */
  let permClass = 'permHidden';
  let flag = Vue.prototype.$hasPermission(el.getAttribute('rescode'));
  
  if(flag){
    removeClass(el,permClass);
  } else {
    
    addClass(el,permClass);
    el.style.display='none';
    el.style.visibility='hidden';
  }
}
function removeElement(_element){
  var _parentElement = _element.parentNode;
  if(_parentElement){
         _parentElement.removeChild(_element);
  }
}
Vue.directive('perm', {
  bind(el, binding, vnode) {
    el.setAttribute('rescode',binding.value);
    addClass(el,'permHidden');
    
    flushDomPerm(el,binding,vnode);
    
  },
  componentUpdated: function (el, binding, vnode) {
    // addClass(el,'permHidden');
    
    // flushDomPerm(el,binding,vnode);
  }
})
Vue.directive('protected', (el, binding, vnode) => {
  let rescode = binding.value;
  
  el.setAttribute('rescode',rescode);
  if(!rescode){
    return ;
  }
  let flag = Vue.prototype.$hasPermission(el.getAttribute('rescode'));
  if(!flag){
    addClass(el,'permProtected')
    el.innerHTML = '敏感受控';
  } else {
    removeClass(el,'permProtected')
  }
  
});


//只读查看指令
function hasClass(element, csName) {

  (element.className || '').match(RegExp('(\\s|^)' + csName + '(\\s|$)')); //使用正则检测是否有相同的样式
}

function addClass(element, csName) {
  
  if (!hasClass(element, csName)) {
    element.className += ' ' + csName;
  }
}

function removeClass(element, csName) {
  element.className = (element.className || '').replace(RegExp('(\\s|^)' + csName + '(\\s|$)'), ' '); //利用正则捕获到要删除的样式的名称，然后把他替换成一个空白字符串
}

function flushDomDisplay(el, binding) {
  let className = 'displayForm'
  if (binding.oldValue == binding.value) {
    return;
  }
  
  if (!!binding.value) {
    addClass(el, className)
    let inputs = el.querySelectorAll('input,textarea,select');
    inputs.forEach(input => {
      if (!hasClass(input, 'readOnly')) { //用户定义为只读的，不处理
        input.setAttribute('readOnly', 'readOnly');
      }
    })
  } else {
    removeClass(el, className)
    let inputs = el.querySelectorAll('input,textarea,select');
    inputs.forEach(input => {
      if (!hasClass(input, 'readOnly')) { //用户定义为只读的，不处理
        input.removeAttribute('readOnly')
      }
    })
  }
}
Vue.directive('display', {
  bind: function (el, binding, vnode) {
    let inputs = el.querySelectorAll('input,textarea,select');
    inputs.forEach(input => {
      if (input.hasAttribute('readOnly')) { //用户定义为只读的，不处理
        addClass(input, 'readOnly');
      }
    })
    flushDomDisplay(el, binding)
  },
  inserted: function (el, binding, vnode) {
    
  },
  componentUpdated(el, binding, vnode) {
    flushDomDisplay(el, binding);
  }
})

//对话框拖动

// v-dialogDrag: 弹窗拖拽
Vue.directive('dialogDrag', {
  bind(el, binding, vnode, oldVnode) {
    const dialogHeaderEl = el.querySelector('.el-dialog__header');
    const dragDom = el.querySelector('.el-dialog');
    dialogHeaderEl.style.cursor = 'move';
    // 获取到的值带px 正则匹配替换
    let styL, styT;
    let disX = 0;
    let disY = 0;
      
    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);
    let onMouseDown = (e)=>{
      // 鼠标按下，计算当前元素距离可视区的距离
      disX = e.clientX - dialogHeaderEl.offsetLeft;
      disY = e.clientY - dialogHeaderEl.offsetTop;

      
      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (sty.left.includes('%')) {
        styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100);
        styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100);
      } else {
        styL = +sty.left.replace(/\px/g, '');
        styT = +sty.top.replace(/\px/g, '');
      };

      document.removeEventListener('mousemove',onMouseMove);
      document.addEventListener('mousemove',onMouseMove);
    }
    let onMouseMove = (e)=>{
      // 通过事件委托，计算移动的距离 
      const l = e.clientX - disX;
      const t = e.clientY - disY;

      // 移动当前元素  
      dragDom.style.left = `${l + styL}px`;
      dragDom.style.top = `${t + styT}px`;

    }
    // dragDom.onmouseup = function (e) {
    //   dragDom.onmousemove = null;
    //   dragDom.onmouseup = null;

    // };
    let onMouseUp = (e)=>{
      document.removeEventListener('mousemove',onMouseMove);
    }
    el.unbindEventListener = ()=>{
      dialogHeaderEl.removeEventListener('mousedown',onMouseDown);
      dragDom.removeEventListener('mousemove',onMouseMove);
    }
    dialogHeaderEl.addEventListener('mousedown',onMouseDown);
    document.addEventListener('mousemove',onMouseMove);
    dragDom.addEventListener('mouseup',onMouseUp);
  },
  unbind (el) {
    if(el.unbindEventListener){
      el.unbindEventListener();
    }
  }
})

// v-dialogDragWidth: 弹窗宽度拖大 拖小
Vue.directive('dialogDragWidth', {
  bind(el, binding, vnode, oldVnode) {
    const dragDom = binding.value.$el.querySelector('.el-dialog');

    el.onmousedown = (e) => {

      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - el.offsetLeft;

      document.onmousemove = function (e) {
        e.preventDefault(); // 移动时禁用默认事件

        // 通过事件委托，计算移动的距离 
        const l = e.clientX - disX;
        dragDom.style.width = `${l}px`;
      };

      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    }
  }
})


//数字输入
/**  
 * 格式化数字显示方式   
 * 用法  
 * formatNumber(12345.999,'#,##0.00');  
 * formatNumber(12345.999,'#,##0.##');  
 * formatNumber(123,'000000');  
 * @param num  
 * @param pattern  
 */  
function formatNumber(num,pattern){    
  var strarr = num?num.toString().split('.'):['0'];    
  var fmtarr = pattern?pattern.split('.'):[''];    
  var retstr='';    
    
  // 整数部分    
  var str = strarr[0];    
  var fmt = fmtarr[0];    
  var i = str.length-1;      
  var comma = false;    
  for(var f=fmt.length-1;f>=0;f--){    
    switch(fmt.substr(f,1)){    
      case '#':    
        if(i>=0 ) retstr = str.substr(i--,1) + retstr;    
        break;    
      case '0':    
        if(i>=0) retstr = str.substr(i--,1) + retstr;    
        else retstr = '0' + retstr;    
        break;    
      case ',':    
        comma = true;    
        retstr=','+retstr;    
        break;    
    }    
  }    
  if(i>=0){    
    if(comma){    
      var l = str.length;    
      for(;i>=0;i--){    
        retstr = str.substr(i,1) + retstr;    
        if(i>0 && ((l-i)%3)==0) retstr = ',' + retstr;     
      }    
    }    
    else retstr = str.substr(0,i+1) + retstr;    
  }    
    
  retstr = retstr+'.';    
  // 处理小数部分    
  str=strarr.length>1?strarr[1]:'';    
  fmt=fmtarr.length>1?fmtarr[1]:'';    
  i=0;    
  for(var f=0;f<fmt.length;f++){    
    switch(fmt.substr(f,1)){    
      case '#':    
        if(i<str.length) retstr+=str.substr(i++,1);    
        break;    
      case '0':    
        if(i<str.length) retstr+= str.substr(i++,1);    
        else retstr+='0';    
        break;    
    }    
  }    
  return retstr.replace(/^,+/,'').replace(/\.$/,'');    
}  
Vue.directive('numberInt', {
  bind: function (el, binding, vnode) {
    let tagName = el.tagName;
    let element = null;
    let isinput = false;
    if('INPUT'==tagName.toUpperCase()){
      element = el;
      isinput = true;
    } else {
      element = el.getElementsByTagName('input')[0];
    }
    const len = binding.value  // 初始化设置
    function setValue(val){
      if(isinput){
        element.value=val;
      } else {
        vnode.data.model.callback(val);
      }
    }
    function onKeyup(){
      if(element.value==null||element.value==''){
        setValue('');
      } else if (isNaN(element.value)) {
        
        setValue(0);
      } else {
        let tmp = '';
        for(let i=0;i<len;i++){
          tmp+='#';
        }
        let format = '0';
        if(tmp!=''){
          format = format+'.'+tmp;
        }

        let val = formatNumber(Number(element.value),format);
        if(element.value.endsWith('.')){
          setValue(val+'.');
        } else {
          setValue(val);
        }
      }
    }
    el.unbindEventListener = () => {
      element.removeEventListener('keyup', onKeyup)
    }
    element.addEventListener('keyup', onKeyup);
      
      
  },
  unbind (el) {
    if(el.unbindEventListener){
      el.unbindEventListener();
    }
  }
})

//==== poper
// v-: 弹窗拖拽
Vue.directive('poperover', {
  bind(el, binding, vnode, oldVnode) {
    
    
    
    let onMouseClick = (e)=>{
      // document.removeEventListener('mousemove',onMouseMove);
      el.style.display='none';
    }
    el.unbindEventListener = ()=>{
      document.removeEventListener('click',onMouseClick);
    }
    document.addEventListener('click',onMouseClick);
  },
  unbind (el) {
    if(el.unbindEventListener){
      el.unbindEventListener();
    }
  }
})

// antd表格表头吸顶
Vue.directive('tableSticky', TableSticky)
