import request from "@/utils/request";

export default {
  // 获取订阅消息品牌列表
  fetchAuditPrincipalApi(){
    return request({
      baseURL: "/bfz-dy",
      url: `mediaAccountAudit/audit/principal/list	`,
      method: "GET"
    })
  },
  // 添加订阅消息
  fetchAuditNoticeApi(params){
    return request({
      baseURL:"/bfz-dy",
      url: `mediaAccountAudit/audit/notice/edit`,
      method:"POST",
      data:params
    })
  },
  // 订阅消息详情
  fetchAuditNoticeDetailApi(){
    return request({
      baseURL:"/bfz-dy",
      url:`mediaAccountAudit/audit/notice/detail`,
      method:"GET"
    })
  },
  // 获取审核列表
  fetchAuditList(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/mediaAccountAudit/list`,
      method: "POST",
      data: params
    });
  },

  // 获取审核不通过原因列表
  fetchAuditReason() {
    return request({
      baseURL: "/bfz-dy",
      url: `/mediaAccountAudit/audit/reason`,
      method: "GET"
    });
  },

  // 获取媒体号绑定的经销商信息
  fetchAuditDealer(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/mediaAccountAudit/audit/dealer`,
      method: "GET",
      params
    })
  },

  // 获取媒体号信息
  fetchAuditAuthorInfo(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/mediaAccountAudit/audit/getAuthorInfo`,
      method: "GET",
      params
    })
  },

  // 审核媒体号
  audit(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/mediaAccountAudit/audit`,
      method: "POST",
      data: params
    });
  },

  // 批量审核通过媒体号
  batchAudit(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/mediaAccountAudit/audit/batch`,
      method: "POST",
      data: params
    });
  },

  // 导出审核列表
  exportAuditList(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/mediaAccountAudit/list/export`,
      method: "POST",
      data: params,
      responseType: 'blob'
    })
  },

  // 获取免审配置列表
  fetchAuditConfigList(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/mediaAccountAudit/verifyConfig`,
      method: "GET",
      params
    });  
  },

  // 添加免审配置
  addAuditConfig(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/mediaAccountAudit/verifyConfig`,
      method: "POST",
      data: params
    });
  },

  // 移除免审配置
  removeAuditConfig(params) {
    const { id } = params;

    return request({
      baseURL: "/bfz-dy",
      url: `/mediaAccountAudit/verifyConfig/cancel/${id}`,
      method: "GET"
    });
  },

  // 获取免审配置操作日志列表
  fetchAuditConfigLogList(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/mediaAccountAudit/verifyConfigLog`,
      method: "GET",
      params
    });
  }
}
