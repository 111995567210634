<template>
  <a-modal
    :title="headerTips"
    :visible="showModal"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :maskClosable="false"
    :destroyOnClose="true"
  >
    <a-form :form="form">
      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        :label="labelTips"
      >
        <div class="textarea-wrapper">
          <a-textarea
            style="width: 80%"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            v-decorator="[
              'cause',
              {
                rules: [{ required: checkNick, message: errTips }],
              },
            ]"
            :placeholder="placeholderTips"
            :maxLength="200"
            @change="changeDes"
            class="m-textarea"
          />
          <span class="m-count">{{ desCount }}</span>
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

export default {
  props: {
    modalType: {
      type: Number,
      require: true,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modalType: {
      handler(val) {
        this.checkNick = false;
        switch (val) {
          case 1:
            this.headerTips = "拒绝工单";
            this.placeholderTips = "可填写拒绝工单原因";
            this.labelTips = "原因";
            this.errTips = "请填写拒绝工单原因";
            this.checkNick = true;
            break;
          case 2:
            this.headerTips = "审核驳回";
            this.placeholderTips = "可填写审核驳回的原因";
            this.labelTips = "原因";
            break;
          case 3:
            this.headerTips = "取消工单";
            this.placeholderTips = "请填写取消工单的原因";
            this.errTips = "请填写取消工单的原因";
            this.labelTips = "原因";
            this.checkNick = true;
            break;
          case 4:
            this.headerTips = "评价";
            this.placeholderTips = "请评价对本次工单交付的情况，以便改善工作";
            this.labelTips = "评价";
            this.errTips = "请评价对本次工单交付的情况，以便改善工作";
            this.checkNick = true;
            break;
          default:
            break;
        }
      },
    },
  },
  data() {
    return {
      headerTips: "",
      checkNick: false,
      formItemLayout,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      placeholderTips: "",
      errTips: "请评价对本次工单交付的情况，以便改善工作",
      labelTips: "",
      desCount: "0/200",
    };
  },
  methods: {
    initFrom() {
      this.desCount = "0/200";
    },
    handleOk(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
          this.$emit("saveOneInput", values.cause);
        }
      });
    },
    handleCancel(e) {
      this.$emit("closeModal", 2);
    },
    changeDes(e) {
      let value = e.target.value;
      this.desCount = `${value.length}/200`;
    },
  },
};
</script>
<style lang="scss" scoped>
.textarea-wrapper {
  position: relative;
  display: block;

  .m-textarea {
    padding: 8px 12px;
    height: 100%;
  }

  .m-count {
    color: #808080;
    background: #fff;
    position: absolute;
    font-size: 12px;
    bottom: 8px;
    right: 12px;
  }
}
</style>