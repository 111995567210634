import request from "@/utils/request";

export default {
  getActivityList(params) {
    return request({
      url: "/training/activities/kpi",
      method: "GET",
      params: params
    });
  },
  getDealersList(params) {
    return request({
      url: "/training/dealer/kpi",
      method: "GET",
      params: params
    });
  },
  getKpiInfo(params) {
    return request({
      url: "/training/kpi/info",
      method: "GET",
      params: params
    });
  },
  getKPIListList(params) {
    return request({
      url: "/training/kpi/infos",
      method: "GET",
      params: params
    });
  },
  getStudyList(params) {
    return request({
      url: "/activities/study/list",
      method: "GET",
      params: params
    });
  },
  getModuleListList(params) {
    return request({
      url: "/activities/template/list",
      method: "GET",
      params: params
    });
  },
  getTemplateDetail(params) {
    return request({
      url: "/activities/template/detail",
      method: "GET",
      params: params
    });
  },
  getGroupSelect(params) {
    return request({
      url: "/activities/account/group/select",
      method: "GET",
      params: params
    });
  },
  getModuleMameList(module_ids) {
    return request({
      baseURL: "/cmd-admin",
      url: `/afanti/train/kpi/module-name?module_ids=${ module_ids }`,
      method: "get"
    });
  },
  getDealerInfo(params) {
    return request({
      url: "/dealer/info",
      method: "GET",
      params: params
    });
  },
  getAwemeSummary(params) {
    return request({
      url: "/dealer/aweme/summary",
      method: "GET",
      params: params
    });
  },
  getAwemeListTen(params) {
    return request({
      url: "/dealer/aweme/rank",
      method: "GET",
      params: params
    });
  },
  getAuthorList(params) {
    return request({
      url: "/dealer/author",
      method: "GET",
      params: params
    });
  },
  getLiveList(params) {
    return request({
      url: "/dealer/live/rank",
      method: "GET",
      params: params
    });
  },
  getList (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity`,
      method: 'get',
      params: params
    })
  },
};
