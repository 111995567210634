<template>

  <a-form-model :model="entity" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" ref="form" label-width="100px">
    <!--<a-form-model-item label="商户" prop="merchantId">-->
      <!--{{entity.merchantId}}-->
    <!--</a-form-model-item>-->
    <a-form-model-item label="姓名">
      {{entity.name}}
    </a-form-model-item>
    <a-form-model-item label="电话" prop="mobileno">
      {{entity.mobile}}
    </a-form-model-item>
    <a-form-model-item label="邮箱" prop="email">
      {{entity.email}}
    </a-form-model-item>
    <a-form-model-item label="备注" prop="remark">
      {{entity.remark}}
    </a-form-model-item>
  </a-form-model>

</template>

<script>
  import {auth} from "@/utils";

  export default {
    data() {

      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.entity.checkPass !== '') {
            this.$refs.form.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.entity.newPass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        entity: {
          sysAccount: {}
        },
      };
    },
    methods: {
      init(options) {
        this.entity = _.cloneDeep(this.$router.app.user)
        // this.userId = options.userId;
        // this.$api.customer.user.currentUser().then(r=>{
        //     this.entity = r;
        // })
      }
    },
    mounted() {
      this.$on('init', this.init)
    }
  }
</script>
