import request from "@/utils/request";

let module = "/ai-video-admin";

export default {
  // 获取素材更新数
  fetchMaterialUpdateCount(params) {
    return request({
      baseUrl: "/bfz-dy",
      url: `/meterial/creativity/update-count`,
      method: "get",
      params
    });
  },
  // 获取素材列表
  fetchMaterialList(params) {
    return request({
      baseUrl: "/bfz-dy",
      url: `/meterial/creativity/list`,
      method: "get",
      params
    });
  },
  // 获取帐号列表
  fetchAccountList(params) {
    return request({
      baseUrl: "/bfz-dy",
      url: `/follow/account/list`,
      method: "get",
      params
    });
  },
  // 添加关注帐号
  addFollowAccount(params) {
    return request({
      baseUrl: "/bfz-dy",
      url: `/follow/account/add`,
      method: "post",
      data: params
    });
  },
  // 更新关注帐号
  updateFollowAccount(params) {
    return request({
      baseUrl: "/bfz-dy",
      url: `/follow/account/update`,
      method: "put",
      data: params
    });
  },
  // 获取关注帐号状态
  fetchFollowAccountState(params) {
    return request({
      baseUrl: "/bfz-dy",
      url: `/follow/account/state`,
      method: "get",
      params
    });
  },
  // 品牌
  getBrandsList(params) {
    return request({
      baseURL: "/bfz-dy",
      url: `/base/select/brand`,
      method: "get",
      params: {
        brandName: params
      }
    });
  },
  // 车系
  getSeries(manufactor_id) {
    return request({
      baseURL: "/bfz-dy",
      url: `/base/select/series`,
      method: "get",
      params: {
        brand_id: manufactor_id
      }
    });
  },
};
