import request from '@/utils/request';
import { BFZ_BFF, AFT_AIGC } from '@/constant/api';

export default {
  // 新增prompt
  addPromptDebug(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/insert`,
      method: 'POST',
      data,
    });
  },
  // 修改prompt
  updatePromptDebug(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/update`,
      method: 'PUT',
      data,
    });
  },
  // 运行prompt
  runPromptDebug(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/run`,
      method: 'POST',
      data,
    });
  },
  // 获取prompt调试列表
  getPromptDebugList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/list`,
      method: 'POST',
      data,
    });
  },
  // 获取prompt调试详情
  getPromptDebugDetail(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/selectByPromptId/${id}`,
      method: 'GET',
    });
  },
  // 获取prompt调试历史列表
  getPromptDebugHistoryList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/log/list`,
      method: 'POST',
      data,
    });
  },
  // 获取prompt调试版本列表
  getPromptDebugVersionList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/version/list`,
      method: 'POST',
      data,
    });
  },

  // 新增prompt组合
  addPromptCombo(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/combination/insert`,
      method: 'POST',
      data,
    });
  },
  // 修改prompt组合
  updatePromptCombo(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/combination/update`,
      method: 'POST',
      data,
    });
  },
  // 获取prompt组合列表
  getPromptComboList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/combination/list`,
      method: 'POST',
      data,
    });
  },
  // 获取prompt组合详情
  getPromptComboDetail(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/combination/${id}`,
      method: 'GET',
    });
  },
  // 运行prompt组合
  runPromptCombo(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/combination/run`,
      method: 'POST',
      data,
    });
  },
  // 获取prompt组合历史列表
  getPromptComboHistoryList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/combination/log/list`,
      method: 'POST',
      data,
    });
  },
  // 获取prompt组合历史详情
  getPromptComboHistoryDetail(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/prompt/combination/log/${id}`,
      method: 'GET',
    });
  },
  // 获取AIGC模型列表
  getAiContentModelList() {
    return request({
      baseURL: AFT_AIGC,
      url: `/aigc_model_info/group_by_first_category`,
      method: 'get',
    });
  }
};
