<template>
  <div id="appBody">
    <a-config-provider :locale="locale">
      <router-view></router-view>
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { auth } from '@/utils';
import Cookies from 'js-cookie';

export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
    };
  },
  watch: {
    $route() {
      this.flushTitle();
    },
  },
  methods: {
    resize() {
      this.$bus.$emit('resize');
    },
    flushTitle() {
      document.title = (this.$route.meta || {}).title || '';
      document.title = `${document.title}${document.title ? ' - ' : ''}百夫长`;
    },
  },

  mounted() {
    this.flushTitle();
    // 登陆成功处理
    this.$bus.$on('app:logged', async () => {
      if (sessionStorage.redirectUrl) {
        await auth.setCookieUserId();
        const url = sessionStorage.redirectUrl;
        sessionStorage.redirectUrl = '';
        window.location.href = url;
      } else {
        this.$router.push('/');
        auth.setCookieUserId();
      }
    });
    this.$bus.$on('app:logout', () => {
      auth.removeToken();
      this.$router.app.isLogged = false;
      this.$router.app.user = null;
      // window.location.href = Consts.LOGOUT_URL
      // this.$nextTick(() => {
      //   this.$router.push("/login");
      // });
    });
    // 获取授权信息成功
    this.$bus.$on('app:user:set', () => {});
  },
  created() {
    if (localStorage.getItem('token_val') && !Cookies.get('token_val')) {
      auth.setCookieToken(localStorage.getItem('token_val'));
    }
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.resize);
  },
};
</script>

<style>
/* 查看大图插件-图片标题颜色 */
.viewer-title {
  color: #fff !important;
  opacity: 1 !important;
}
/* wangeeditor-表情面板宽度 */
.w-e-panel-container {
  width: 434px !important;
}
</style>
