<template>
  <div>
    <a-modal
      title="详情"
      :visible="showDtlModal"
      :footer="null"
      @cancel="handleCancel"
      :maskClosable="false"
    >
      <div></div>
      <div class="flex-be">
        <p class="code-l">工单编号:</p>
        <p>{{ detailData.orderCode }}</p>
      </div>
      <div class="flex-be">
        <p class="code-l">关联客户:</p>
        <p>{{ detailData.customerName }}</p>
      </div>
      <div class="flex-be">
        <div class="code-l">发起人:</div>
        <div>
          <div>
            {{ detailData.creatorName }}
          </div>
          <p>{{ detailData.department }}</p>
        </div>
      </div>
      <div class="flex-be">
        <p class="code-l">期望交付时间:</p>
        <p>{{ detailData.expectedFinishDate }}</p>
      </div>
      <div class="flex-be">
        <p class="code-l">创建时间:</p>
        <p>{{ detailData.ctime }}</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    showDtlModal: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  methods: {
    showModal() {
      this.visible = true;
    },

    handleCancel(e) {
      this.$emit("closeModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-be {
  display: flex;
}
.code-l {
  width: 120px;
  padding-right: 20px;
  text-align: right;
  color: #333;
}
</style>
