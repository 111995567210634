<template>
  <div class="container">
    <div class="content">
      <h1>点击跳转扫码授权</h1>
      <div class="btn">
        <a-button type="primary" @click="clickShouQuan" size="large" :loading='btnLoading'>点我</a-button>
      </div>
      <a-button type="link" @click="clickContact" style="margin-left: -40px;margin-top: 10px">联系技术支持</a-button>
    </div>
    <a-modal
        title="联系技术支持"
        :visible="qwVisible"
        :footer="null"
        @cancel="qwVisible = false"
    >
      <div class="qw">
        <img src="../../../assets/images/qw.png" alt="企业微信二维码">
      </div>
    </a-modal>

    <a-modal
      :visible="fhlVisible"
      :closable="false"
      :footer="null"
      :width="300"
    >
      <div class="fhl-modal-content">
        <p class="fhl-tips">
          此授权工具有效期较短，若需保持长期有效，扫码使用风火轮App进行授权。
        </p>
        <img class="fhl-code" :src="fhlCodeUrl" alt="">
        <a-button class="fhl-btn" type="primary" @click="fhlVisible = false">
          关闭
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import QRCode from "qrcode";
import api from "@/api/Edit.js";

const fhlUrl = process.env.VUE_APP_H5_HOST + "/download_guide";

export default {
  data() {
    return {
      btnLoading: false,
      qwVisible: false,
      fhlVisible: false,
      fhlCodeUrl: ''
    };
  },
  async created() {
    this.fhlVisible = true;
    this.fhlCodeUrl = await this.generateQRCode(fhlUrl, 200);
  },
  methods: {
    clickShouQuan () {
      this.btnLoading = true;
      // let baseUrl = process.env.NODE_ENV == 'production' ?'https://baifuzhang.afanticar.com':'https://baifuzhang.frontend.dev.afanticar.net'
      console.log('window.location.host:', window.location.host);
      let baseUrl = window.location.host;
      let payload = {
        front_url: 'https://' + baseUrl + '/result/success?type=0',
        author_id: null,
        system_ids: null
      };
      api.getDouyinShouquan(payload).then(res => {
        this.btnLoading = false;
        if (res.errcode == 0) {
          window.location.href = res.data;
        } else {
          this.$message.error(res.errmsg);
        }

      }).catch(res => {
        this.btnLoading = false;
        console.log(res);
      });
    },
    clickContact () {
      this.qwVisible = true;
    },
    // 生成二维码
    generateQRCode(url, size) {
      let opts = {
        type: 'image/jpeg',
        margin: 2,
        width: size,
        height: size,
        color: {
          dark: '#000',
          light: '#FFF'
        },
        quality: 0.3,
        errorCorrectionLevel: 'H'
      };

      return new Promise((resolve, reject) => {
        QRCode.toDataURL(url, opts, (err, codeUrl) => {
          if (err) reject(err);

          resolve(codeUrl);
        })
      })
    }
  }
};
</script>

<style scoped lang="less">
html, body {
  overflow: hidden;
  height: 100%;
}

.container {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url('../../../assets/images/douyincode.jpg') center top;
}

.content {
  width: 400px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -200px;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 2;
  padding: 50px;
  box-sizing: border-box;
}

.content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -ms-filter: blur(20px);
  -o-filter: blur(20px);
  filter: blur(20px);
  z-index: -3;
  margin: -30px;
  background-image: url('../../../assets/images/douyincode.jpg');
  background-position: center top;
  background-size: cover;
  background-attachment: fixed;
}

.content h1 {
  text-align: center;
  /* margin-bottom: 20px; */
}

.content p {
  text-indent: 2em;
  line-height: 1.7;
}

.btn {
  text-align: center;
}

.qw {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  img {
    width: 60%;
    height: 60%;
    border-radius: 22px;
  }
}

.fhl-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .fhl-tips {
    margin: 0 0 16px;
  }

  .fhl-code {
    margin: 0 0 16px;
    width: 200px;
    height: 200px;
  }
}
</style>
