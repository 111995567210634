/**
 * sticky
 */
import { Tool } from '@/utils';
const VueSticky = {
  params: [
    // sticky 元素固定相对屏幕高度
    'top',
    // fixed 时 元素的z-index
    'zIndex',
    'fixTarget'//固定目标（不设置，则整个容器fix),
  ],
  componentUpdated(el, binding, vnode) {

    let stickyTop = binding.value.stickyTop || 0;
    let fixTargetSelector = binding.value.fixTarget;
    const zIndex = binding.value.zIndex || 1000;
    
    let orignRect = el.getBoundingClientRect();
    vnode.orignRect = orignRect;
    
    let fixTarget = el.firstElementChild
    if(fixTargetSelector){ //暂时支持ID选择
      fixTarget = Tool.selectDom(fixTargetSelector);
    }
    vnode.childOrignStyle = fixTarget.style;//记录最原始样式

    Tool.addClass(el, 'sticky');
    let active = false

    const check = () => {
      let rect = el.getBoundingClientRect();
      let right = rect.right;

      let stickyRight = binding.value.stickyRight === undefined ? (document.body.offsetWidth - right) : binding.value.stickyRight;

      let offsetLeft = rect.left;
      let offsetTop = rect.top
      if (offsetTop <= stickyTop) {
        if (active) return
        if (!el.style.height) {
          el.style.height = el.clientHeight + 'px'
        }
        fixTarget.style.right = `${stickyRight}px`;
        fixTarget.style.left = `${offsetLeft}px`;
        fixTarget.style.zIndex = zIndex;
        fixTarget.style.top = `${stickyTop}px`;
        Tool.addClass(fixTarget, 'sticky-fixed');
        el.setAttribute('fixed',true);
        active = true
      } else {
        if (!active) return
        /*
        offsetLeft = vnode.orignRect.left;
        stickyRight = vnode.orignRect.right;
        stickyTop = vnode.orignRect.top;

        fixTarget.style.right = `${stickyRight}px`;
        fixTarget.style.left = `${offsetLeft}px`;
        fixTarget.style.top = `${stickyTop}px`;
        fixTarget.style.zIndex = vnode.orignZIndex;
        */
        //复原现场
        el.style = vnode.rootOrignStyle;
        fixTarget.style = vnode.childOrignStyle;
        Tool.removeClass(fixTarget, 'sticky-fixed');
        el.removeAttribute('fixed');
        active = false
      }
    }

    var timer
    vnode.__listenAction = () => {
      if (timer) return
      timer = setInterval(check, 30)
    }

    window.addEventListener('scroll', vnode.__listenAction)
  },
  unbind(el, binding, vnode) {
    if (el.__listenAction) {
      window.removeEventListener('scroll', vnode.__listenAction)
    }
  }
}

export default {
  install(Vue) {
    Vue.directive('sticky', VueSticky)
  }
}