<template>

  <a-form-model :model="entity" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" :rules="rules" ref="form">
    <a-form-model-item label="旧密码" prop="old_pass">
      <a-input type="password" v-model="entity.old_pass"></a-input>
    </a-form-model-item>
    <a-form-model-item label="密码" prop="new_pass">
      <a-input type="password" v-model="entity.new_pass" auto-complete="off"></a-input>
    </a-form-model-item>
    <a-form-model-item label="确认密码" prop="check_pass">
      <a-input type="password" v-model="entity.check_pass" auto-complete="off"></a-input>
    </a-form-model-item>
    <a-form-model-item>
      <a-button type="primary" @click="submit">确 定</a-button>
    </a-form-model-item>
  </a-form-model>

</template>

<script>
  import {auth} from "@/utils";

  export default {
    data() {

      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.entity.check_pass !== '') {
            this.$refs.form.validateField('check_pass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.entity.new_pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        entity: {
          old_pass: '',
          new_pass: '',
          check_pass: ''
        },
        rules: {
          old_pass: [
            {required: true, message: '请输入旧密码', trigger: 'blur'}
          ],
          new_pass: [
            {required: true, validator: validatePass, trigger: 'blur'}
          ],
          check_pass: [
            {required: true, validator: validatePass2, trigger: 'blur'}
          ],
        }
      };
    },
    methods: {
      submit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.$api.AppService.modifyPassword(this.entity).then(rsp => {
              if (rsp.errcode == 5004) {
                this.$message.error(rsp.errmsg)
                return false
              }
              this.$emit('saved')
            })
          }
        });
      }
    }
  }
</script>
