/**
 * components 第一层目录存放最为基础的无状态组件，类如表格
 * widgets 目录存放多处复用的组件，类如业务下拉控件
 */
import Vue from 'vue';
import vTable from './v-table.vue'
import echarts from './echarts.vue'
import vChecktags from './v-checktags'
import vStatistics from './v-statistics'
import vText from './v-text'
import vUpload from './v-upload'

Vue.component('v-table', vTable);
Vue.component('v-checktags', vChecktags);
Vue.component('v-statistics', vStatistics);
Vue.component('v-text', vText);
Vue.component('v-upload', vUpload);
Vue.component('echarts', echarts);
export {
  vTable,
  echarts,
  vUpload
}
