import request from "@/utils/request";

export default {
  // 获取项目列表
  getProjectList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/project/list`,
      method: "get",
      params: params
    });
  },
  // 获取项目编辑回显
  getProjectDisplay(id) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/project/display/${ id }`,
      method: "get",
    });
  },
  // 获取项目详情
  getProjectDetail(projectId) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/project/detail/${ projectId }`,
      method: "get",
    });
  },
  // 保存项目
  saveProject(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/project/save`,
      method: "put",
      data
    });
  },
  // 获取分组
  getGroupList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      // baseURL: "/bfz-app",
      url: `/pms/trainee/dealer/group/list`,
      method: "get",
      params: params
    });
  },
  // 学员分组添加
  addTrainee(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/add`,
      method: "put",
      data
    });
  },
  // 获取学员列表
  getTraineeList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/pageList`,
      method: "get",
      params: params
    });
  },
  // 保存考核周期
  saveCycles(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      // url: `/pms/cycle/${ params.projectId }/cycles`,
      url: `/pms/cycle/operate/${ params.businessId }`,
      method: "post",
      data: params.list
    });
  },
  // 获取考核周期列表
  getCycleList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/cycle/cycleList`,
      method: "get",
      params: params
    });
  },
  // 获取播主主营账号数量
  getAuthoreCount(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: "/pms/trainee/author/main/count",
      method: "get",
      params,
    });
  },
  // 获取授权状态主营账号
  getAuthorizationSituation(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: "/pms/trainee/authorization/situation",
      method: "get",
      params,
    });
  },
  // 移出分组
  deleteGroup(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: "/pms/trainee/delete",
      method: "delete",
      data,
    });
  },
  // 获取跟进人员 部分下拉数据
  getFollowerOptions(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/selectOption/follower/options`,
      method: "get",
      params: params
    });
  },
  // 获取跟进人员列表
  getFollowerList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/follower/list`,
      method: "get",
      params: params
    });
  },
  // 导出跟进人员列表
  exportFollowerList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/follower/list/export`,
      method: "get",
      responseType: "blob",
      params: params
    });
  },
  // 添加跟进人员
  saveFollower(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/follower`,
      method: "post",
      data
    });
  },
  // 删除跟进人员
  delFollower(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/follower`,
      method: "delete",
      data
    });
  },
  // 获取关联分组
  getFollowerGroupDetail(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/follower/group/detail`,
      method: "get",
      params: params
    });
  },
  // 获取学员列表（经销商）
  getTraineelGroup(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/follower/trainee/group`,
      method: "get",
      params: params
    });
  },
  // 关联分组
  saveFollowerGroupMapping(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/follower/group/mapping`,
      method: "post",
      data
    });
  },
  // 获取学员kpi列表
  getKpiList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/kpi/list`,
      method: "get",
      params: params
    });
  },
  // 获取kpi指标详情
  getKpiResultDetailList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/kpi/detail`,
      method: "get",
      params: params
    });
  },
  // 导出Kpi列表
  exportKpiList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/kpi/list/export`,
      method: "get",
      responseType: "blob",
      params: params
    });
  },
  // 导出Kpi结果明细列表
  exportKpiDetailList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/kpi/detail/list/export`,
      method: "get",
      responseType: "blob",
      params: params
    });
  },
  // 获取kpi概览
  getKpiOverview(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/kpi/overview`,
      method: "get",
      params: params
    });
  },
  // Kpi扎帐列表
  getKpiBillList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/kpi/bill/list`,
      method: "get",
      params: params
    });
  },
  // 查询项目跟进人员列表下拉框
  getKpiFollowerList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/kpi/follower/list`,
      method: "get",
      params: params
    });
  },
  // 查询项目统计及更新时间
  getProjectUpdateTime(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/kpi/kpiUpdateTime`,
      method: "get",
      params: params
    });
  },
  // 获取学员详情kpi概览
  getKpiDetailOverview(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/kpi/detail/overview`,
      method: "get",
      params: params
    });
  },
  // 查询学员kpi结果详情列表
  getKpiDetailList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/kpi/result/detail/list`,
      method: "get",
      params: params
    });
  },
  // 指标添加模块的下拉
  getAssessmentNormColumnListList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/assessment/norm/columnList`,
      method: "get",
      params: params
    });
  },
  // 查kpi指标列表
  getKpiAssessmentNormList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/assessment/norm/page`,
      method: "get",
      params: params
    });
  },
  //保存指标
  saveKpiAssessmentNormList(data, businessId) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/assessment/norm/${ businessId }`,
      method: "post",
      data
    });
  },
  //编辑指标
  putKpiAssessmentNormList(data, businessId, assessmentNormId) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/assessment/norm/${ businessId }/${ assessmentNormId }`,
      method: "put",
      data
    });
  },
  //获取指标详情
  getKpiAssessmentNormDetail(assessmentNormId, businessId) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/assessment/norm/${ businessId }/${ assessmentNormId }`,
      method: "get",
    });
  },
  //删除指标
  delKpiAssessmentNorm(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/assessment/norm/${ params.businessId }/${ params.assessmentNormId }`,
      method: "delete",
    });
  },
  // 查学员kpi列表
  getTraineeKpiList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/kpi/config/page`,
      method: "get",
      params
    });
  },
  // 保存学员kpi列表
  saveTraineeKpiData(data, businessId) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/kpi/config/update/${ businessId }`,
      method: "put",
      data
    });
  },
  // 查跟进人kpi列表
  getFollowerKpiList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/follower/kpi/list`,
      method: "get",
      params: params
    });
  },
  // 导出跟进人kpi结果明细
  exportFollowerKpiDetail(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/follower/kpi/list/detail/export`,
      method: "get",
      responseType: "blob",
      params: params
    });
  },
  // 导出跟进人kpi结果
  exportFollowerKpiList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/follower/kpi/list/export`,
      method: "get",
      responseType: "blob",
      params: params
    });
  },
  // 学员kpi搜索条件，周期联动跟进人下拉条件筛选数据接口
  getTraineeOptions(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/selectOption/trainee/kpi/follower/options`,
      method: "get",
      params: params
    });
  },
  // 学员kpi结果明细概览【kpi完成度和跟进人信息】
  getTraineeKpiRate(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/kpi/detail/kpiRate`,
      method: "get",
      params: params
    });
  },
  // 指标显示名下拉列表
  getKpiPageList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/assessment/norm/page/list`,
      method: "get",
      params: params
    });
  },


  // 媒体号
  getAuthorPageList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/author/pageList`,
      method: "get",
      params
    });
  },
  // 播主分组添加
  saveAuthor(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/author/add`,
      method: "put",
      data
    });
  },
  // 播主分组删除
  delAuthor(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/author/delete`,
      method: "delete",
      data
    });
  },
  //播主(主营账号和蓝v账号)数量
  getAuthorMainCount(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/author/main/count`,
      method: "get",
      params
    });
  },

  //KPI考核设置
  // 项目自定义周配置操作（新增）
  setOperateWeek(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/cycle/operate/isShowWeek`,
      method: "post",
      data
    });
  },
  // 获取自定义周的详情
  getWeekList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/cycle/isShowWeek`,
      method: "get",
      params
    });
  },
  // 项目自定义日配置操作（新增）
  setOperateDay(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/cycle/operate/isShowDay`,
      method: "post",
      data
    });
  },
  // 获取自定义日的详情
  getDayList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/cycle/isShowDay`,
      method: "get",
      params
    });
  },

  //判断添加分组等按钮是否可点击
  judgeProjectAddGroup(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/project/judgeProjectAddGroup`,
      method: "get",
      params
    });
  },

  // kpi新页面相关接口
  getWeekOptions(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/selectOption/traineeKpi/options/week`,
      method: "get",
      params: params
    });
  },
  getTraineeGroupOptions(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/selectOption/traineeKpi/options/traineeGroup`,
      method: "get",
      params: params
    });
  },
  getTraineeOptionsNew(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/selectOption/traineeKpi/options/trainee`,
      method: "get",
      params: params
    });
  },
  getKpiUpdateTime(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/traineeKpi/detail/kpiUpdateTime`,
      method: "get",
      params: params
    });
  },
  exportWeekDetail(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/traineeKpi/detail/week/export`,
      method: "get",
      responseType: "blob",
      params: params
    })
  },

  
  // 媒体传播 \ 线索成交列表
  getPmsProjectBusinessNorm(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/sysAdmin/pms/bfzPmsProjectBusinessNorm/page`,
      method: "get",
      params
    });
  },
  // 获取指标元数据列表
  getNormMetaList() {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/sysAdmin/pms/bfzPmsProjectAssessmentNormMetadata/getColumnList`,
      method: "get",
    });
  },
  //提交指标
  submitPmsProjectBusinessNorm(body) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/sysAdmin/pms/bfzPmsProjectBusinessNorm`,
      method: body.method,
      data: body.data
    });
  },
  //获取指标详情
  getPmsProjectBusinessNormDetail(id) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/sysAdmin/pms/bfzPmsProjectBusinessNorm/${id}`,
      method: "get",
    });
  },
  //删除指标
  deletePmsProjectBusinessNormDetail(id) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/sysAdmin/pms/bfzPmsProjectBusinessNorm/${id}`,
      method: "delete",
    });
  },
  // 查询项目已有主播分组列表
  getAuthorGroupList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/author/groupList`,
      method: "get",
      params
    });
  },
  //查询项目已有学员分组列表
  getTraineeGroupList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/trainee/groupList`,
      method: "get",
      params
    });
  },

  
  //考核目标分解
  resolveNormTarget(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/sysAdmin/pms/bfzPmsProjectAssessmentNormMetadata/resolveNormTarget`,
      method: 'post',
      data
    });
  },
  //通过指标id获取指标的分解详情
  getResolveDetailByNormId(id) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/sysAdmin/pms/bfzPmsProjectAssessmentNormMetadata/getResolveDetailByNormId/${id}`,
      method: "get",
    });
  },

  //项目指标延迟统计增加或修改
  saveOrUpdateDelayDay(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/project/saveOrUpdateDelayDay`,
      method: 'get',
      params
    });
  },
  //项目指标延迟统计查询
  selectDelayDay(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/project/selectDelayDay`,
      method: 'get',
      params
    });
  },

  // kpi新页面相关接口
  getWeekOptions(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/selectOption/traineeKpi/options/week`,
      method: "get",
      params: params
    });
  },
  getTraineeGroupOptions(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/selectOption/traineeKpi/options/traineeGroup`,
      method: "get",
      params: params
    });
  },
  getTraineeOptionsNew(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/selectOption/traineeKpi/options/trainee`,
      method: "get",
      params: params
    });
  },
  getKpiUpdateTime(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/traineeKpi/detail/kpiUpdateTime`,
      method: "get",
      params: params
    });
  },
  exportWeekDetail(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/traineeKpi/detail/week/export`,
      method: "get",
      responseType: "blob",
      params: params
    });
  },

  //查询产出统计
  getRecentDayOutput(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/project/recentDayOutput`,
      method: 'get',
      params
    });
  },
  //提交产出统计
  submitRecentDayOutput(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pms/project/recentDayOutput`,
      method: 'post',
      data
    });
  },
};
