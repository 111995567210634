// import nr from '@/utils/NetworldRequest'
// const openHost = process.env.VUE_APP_OPENDATA_API
// const url = `${openHost}/mirror-data`
// const re = (new nr({needMessage: false})).getAxios()
// const openNr = (new nr({
//   baseURL: url
// })).getAxios()
import r from "@/utils/request";
const request = function () {
  return r(...arguments).then(res => ({ data: res, status: 200 })).catch(e => (e.disableDefaultHandler && e.disableDefaultHandler(), {errcode: 5001}))
}

// function request(data) {
//   return re(data).then((res) => {
//     if (res.data.errcode) {
//       let msg = "网络不通畅，请重试"
//       // Message({
//       //   message: msg,
//       //   type: 'error',
//       //   duration: 6 * 1000
//       // })
//     }
//
//     return res
//   })
// }

/*
 *直播信息
 */
const liveMessage = '/live/info'

/*
 *  直播间直播时评论分析，车型跟品牌
 */
const commentAnalysis = '/live-polling/comment/analysis'

/* 
 *评论中热度前5的车型
 */
const commentHotCar = '/live-polling/series/hot'

/* 
 *直播观众性别分析
 */
const audience = '/live-polling/audience/analysis/gender'

/* 
*评论中热词
*/
const hotWord = '/live-polling/comment/hotwords'

/* 
*直播观众地区分布
*/
const audienceAdress = '/live-polling/audience/analysis/area'

/* 
*直播间直播时点赞数的总量，增量变化趋势
*/
// const diggTrend = '/live-polling/digg/trend'

/* 
*直播间直播时评论数的总量，增量变化趋势
*/
const commentTrend = '/live-polling/comment/trend'

/* 
*直播间直播时用户、礼物的变化趋势
*/
const liveRoomTrend = '/live/live-room-trend/'

export default {
  getLiveMessage({ roomId }) {
    return request({
      url: `${liveMessage}/${roomId}`
    })
  },
  getCommentAnalysis(param) {
    return request({
      url: commentAnalysis,
      params: {
        room_id: param
      }
    })
  },
  getHotCarType(param) {
    return request({
      url: commentHotCar,
      params: {
        room_id: param
      }
    })
  },

  getAudience(param) {
    return request({
      url: audience,
      params: {
        room_id: param
      }
    })
  },
  // 直播分析线条图
  getAnalysisChart(param){
    return request({
      url: `/live/statistical/chart/${param}`
    })
  },
  getHotWord(param){
    return request({
      url: hotWord,
      params:{
        room_id:param
      }
    })
  },
  getAudienceAdress(param){
    return request({
      url: audienceAdress,
      params:{
        room_id:param
      }
    })
  },
  // getDiggTrend(param){
  //     return openNr.get(diggTrend,{
  //         params:{
  //             room_id : param
  //         }
  //     })
  // },
  getCommentTrend(param){
      return request({
        url: commentTrend,
          params:{
            room_id : param
          }
      })
  },
  getLiveRoomTrend(param){
    return request({
      url: `${liveRoomTrend}${param}`
    })
  },
  // // 获取mqtt配置
  // getMqttConfig({
  //   platformType,
  //   anchorId,
  //   deviceId
  // }) {
  //   return request({
  //     url: `/mqtt/live/mqttClientSetting`,
  //     method: 'GET',
  //     // baseURL: process.env.VUE_APP_OPENDATA_API,
  //     params: {
  //       platformType,
  //       anchorId,
  //       deviceId
  //     }
  //   });
  // },
  // 实时直播视频
  getLiveVideo(params){
    return request({
      url: `/live/${params}`
    })
  },
  //获取新增粉丝数与在线观看数
  getMqttFans({
    platformType,
    roomId,
  }){
    return request({
      url: `/mqtt/live/increase-fans-watching-count`,
      method: 'GET',
      // baseURL: process.env.VUE_APP_OPENDATA_API,
      params: {
        platformType,
        roomId
      }
    });
  },
  // 获取mqtt配置
  getMqttConfig({
    platformType,
    authorId,
    deviceId
  }) {
    return request({
      url: `/mqtt/live/mqttClientSetting`,
      method: 'GET',
      params: {
        platformType,
        authorId,
        deviceId
      }
    });
  },
  // 获取基本信息
  getInfo({
    room_id
  }) {
    return request({
      url: `/mirror-data/douyin/live/live/${room_id}`,
      method: 'GET',
    });
  },
  // 添加直播室id
  addLiveRoom({
    roomId
  }) {
    return request({
      url: `/mqtt/live/add-live-room`,
      method: 'POST',
      params: {
        roomId
      }
    });
  },
}
