import request from '@/utils/request'

/**
 * 跟拍模板列表
 */
export default {
  templateList (param) {
    return request({
      baseURL: '/ai-video-admin',
      url: `/paper/list`,
      method: 'get',
      params: param
    })
  },
  deteleTemplate (param) {
    return request({
      baseURL: '/ai-video-admin',
      url: `/paper/delete`,
      method: 'delete',
      data: param
    })
  },
  classify () {
    return request({
      baseURL: '/ai-video-admin',
      url: `/module/classify`,
      method: 'get'
    })
  },
  formList () {
    return request({
      baseURL: '/ai-video-admin',
      url: `/module/form`,
      method: 'get'
    })
  },
  getOssToken () {
    return request({
      baseURL: '/ai-video-admin',
      url: `/material/get-sts-token`,
      method: 'get'
    })
  },
  templateDetail (param) {
    return request({
      baseURL: '/ai-video-admin',
      url: `/paper/${param}`,
      method: 'get'
    })
  },
  // 更新模板
  updataTemplate (params) {
    return request({
      baseURL: '/ai-video-admin',
      url: `/paper/save`,
      method: 'post',
      data: params
    })
  },
  // 品牌列表
  brandsList (params) {
    return request({
      baseURL: '/ai-video-admin',
      url: `/module/brands`,
      method: 'get',
      params: {
        brandName: params
      }
    })
  },
  // 车系
  getSeries (manufactor_id) {
    return request({
      baseURL: '/ai-video-admin',
      url: `/effect/seriesList`,
      method: 'get',
      params: {
        manufactor_id: manufactor_id
      }
    })
  },
  // 经销商列表
  dealerList (params) {
    return request({
      baseURL: '/ai-video-admin',
      url: `/module/dealer`,
      method: 'get',
      params: {
        dealerName: params
      }
    })
  }
}
