import api from '@/api/core/Console'

const {
  getProvinceList,
  getBrandList,
  getCityList,
  getManufactorList,
  getLiveList,
  liveAddOss
} = api

export default {
  namespaced: true,
  state: {
    brandSelectList: [],
    provinceList: [],
    cityList: [],
    manufactorList:[],

    tableLoading: false,
    liveTableList: [],
    livePagination: {
      current: 1,
      page_size: 10,
      total: 0,
    },
  },
  actions: {
    getBrandList(ctx, payload) {
      const {
        commit
      } = ctx
      return getBrandList(payload)
        .then((res) => {
          commit('changeState', {
            data: res,
            name: 'brandSelectList'
          })
        })
    },
    getProvinceList(ctx, payload) {
      const {
        commit
      } = ctx
      return getProvinceList()
        .then((res) => {
          commit('changeState', {
            data: res,
            name: 'provinceList'
          })
        })
    },
    getCityList(ctx, payload) {
      const {
        commit
      } = ctx
      return getCityList()
        .then((res) => {
          commit('changeState', {
            data: res,
            name: 'cityList'
          })
        })
    },
    getManufactorList(ctx, payload) {
      const {
        commit
      } = ctx
      return getManufactorList(payload)
        .then((res) => {
          if (res) {
            commit('changeState', {
              data: res.manufactors,
              name: 'manufactorList'
            })
          } else {
            commit('changeState', {
              data: [],
              name: 'manufactorList'
            })
          }
        })
    },

    getLiveList(ctx, payload) {
      const {
        commit
      } = ctx
      commit('changeState', {
        data: true,
        name: 'tableLoading'
      })
      return getLiveList(payload)
        .then((res) => {
          console.log('返回的res', res)
          commit('changeState', {
            data: res.data.list,
            name: 'liveTableList'
          })
          commit('changeState', {
            data: res.data.pagination,
            name: 'livePagination'
          })
          commit('changeState', {
            data: false,
            name: 'tableLoading'
          })
        }).catch(() => {
          commit('changeState', {
            data: false,
            name: 'tableLoading'
          })
        })
    },
    liveAddOss(ctx, payload) {
      const { commit } = ctx
      return new Promise((resolve, reject) => {
        liveAddOss(payload)
          .then((res) => {
            if(res.code == 200) {
              resolve(res)
            } else {
              reject(res)
            }
          })
        })
    }
  },
  mutations: {
    changeState(state, payload) {
      const {
        data,
        name
      } = payload
      state[name] = data
    }
  }
}