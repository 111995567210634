import request from '@/utils/request'

export default {
    //获取经销商信息
    getDealerDetail(params) {
        return request({
            baseURL: '/cmd-admin',
            url: '/admin/dongchedi/dealer/base/code',
            method: 'GET',
            params
        })
    },
    //获取主页信息
    getUserDetail(params) {
        return request({
            baseURL: '/cmd-admin',
            url: '/admin/dongchedi/dealer/page',
            method: 'GET',
            params
        })
    },
    //绑定
    submitDetail(data) {
        return request({
            baseURL: '/cmd-admin',
            url: '/admin/dongchedi/dealer',
            method: 'POST',
            data
        })
    }
}