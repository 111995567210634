import request from '@/utils/request'

export default {
  /**
   * 获取省市
   * level: 1省份，2城市
   *  */
  getProvinceList() {
    return request({
      baseURL: '/base',
      url: `/areas/v1`,
      method: 'get',
      params: {
        level: 1
      }
    })
  },
  getCityList() {
    return request({
      baseURL: '/base',
      url: `/areas/v1`,
      method: 'get',
      params: {
        level: 2
      }
    })
  },
  /**
   * 品牌列表v1
   **/
  getBrandList() {
    return request({
      baseURL: '/base',
      url: `/brands/v1`,
      method: 'get'
    })
  },
  /**
   * 厂商列表
   */
  getManufactorList(params) {
    return request({
      baseURL: '/base',
      url: `/series/brand-series`,
      method: 'get',
      params: params
    })
  },
  /**
   * 无参数省市区
   *
   */
  getAreasTree() {
    return request({
      baseURL: '/base',
      url: `/areas/list-all`,
      method: 'get'
    })
  },
  /***
   *品牌列表
   *  */
  getBrandNewList(params) {
    return request({
      baseURL: '/ai-video-admin',
      url: '/module/brands',
      method: 'get',
      params: {
        brandName: params
      }
    })
  },
  /***
   * 大区
   */
  getRegionList(manufactor_id) {
    return request({
      baseURL: '/ai-video-admin',
      url: `/comment-list/get/region/${manufactor_id}`,
      method: 'get'
    })
  },
  /** 
   * 风火轮认证
   */
  getApproveList() {
    return request({
      baseURL: '/bfz-dy',
      url: '/bfz-dy/report/user/business/select/certificate-list',
      method: 'get'
    })
  },
  /** 
   * 风火轮勋章
   */
  getMedalList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: '/bfz-dy/report/user/business/select/medal-list',
      method: 'get',
      params
    })
  },
  /** 
   * 风火轮培训计划
   */
  getTrainList() {
    return request({
      baseURL: '/bfz-dy',
      url: '/bfz-dy/report/user/business/select/train-list',
      method: 'get'
    })
  },
  /***
   * 风火轮学习计划
   */
  getStudyPlan(params) {
    return request({
      baseURL: '/bfz-dy',
      url: '/bfz-dy/report/user/business/select/plan-list',
      method: 'get',
      params
    })
  },
  /* 
   *分组管理相关接口
   */
  getGroupList(params) {
    return request({
      baseURL: '/cas',
      url: '/ums/group/page',
      method: 'get',
      params
    })
  },
  /****
   * 主体下拉
   */
  getPrincipalNew() {
    return request({
      baseURL: '/bfz-dy',
      url: '/bfz-dy/report/user/business/select/principal-list',
      method: 'get',
    })
  },
  /*** 
   * 主体匹配的大区
   */
  getPrincipalRegin(params) {
    return request({
      baseURL: '/bfz-dy',
      url: '/bfz-dy/report/user/business/select/area-list',
      method: 'get',
      params
    })
  },
  /**
   * 筛选主题
   * */ 
  getFiltrationSubject(params){
    return request({
      baseURL: '/bfz-dy',
      url: '/bfz-dy/report/user/business/select/principal-list',
      method: 'get',
      params
    })
  },
  /**
   * 模板下拉
   */
  getTemplateList(){
    return request({
      baseURL: '/bfz-dy',
      url: '/report/user/business/select/module-list',
      method: 'get',
    })
  }
}
