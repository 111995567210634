export default {
  namespaced: true,
  state: {
    TemporaryParameters: {}
  },
  getters: {
    getTemporaryParameters(state) {
      return state.TemporaryParameters;
    }
  },
  mutations: {
    SET_TEMPORARY_PARAMETERS(state, data) {
      state.TemporaryParameters = data;
    }
  },

  actions: {
    setTemporaryParameters({commit}, data) {
      commit('SET_TEMPORARY_PARAMETERS', data);
    }
  }
}
