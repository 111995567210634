import request from '@/utils/request'

export default {

  getAuthUrl(params) {
    return request({
      url: '/openAuth/url',
      method: 'GET',
      params
    })
  },
  getAuthStatus(authorId) {
    return request({
      url: '/openAuth/status/' + authorId,
      method: 'GET',
    })
  },
  query(data){
    return request({
      url: '/serviceAuthor/query',
      method: 'POST',
      data
    })
  },
  getQRCode(authorId){
    return request({
      url: '/serviceAuthor/getQRCode/' + authorId,
      method: 'POST',
    })
  },
  getScanCodeStatus(authorId, token, cancelToken){
    return request({
      url: '/serviceAuthor/getScanCodeStatus/' + authorId,
      method: 'POST',
      params: { token },
      cancelToken,
      timeout: 62000
    })
  },
  getStatus(authorId){
    return request({
      url: '/serviceAuthor/status/' + authorId,
      method: 'GET',
    })
  }
}
