import request from '@/utils/request'

export default {
  getList (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity`,
      method: 'get',
      params: params
    })
  },
  exportExcelModel (extra_title) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity/dealer/excel-template?extra_title= ${extra_title}`,
      method: 'get',
      responseType: 'blob'
    })
  },
  getPrincipals () {
    return request({
      baseURL: '/base',
      url: `/principal/list/v2`,
      method: 'get'
    })
  },
  editTraining (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity`,
      method: params.method,
      data: params.data
    })
  },
  deteleTraining (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity/${params.id}`,
      method: 'delete',
      params: params.data
    })
  },
  addActivitesGroup (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activites/group/create-group`,
      method: 'post',
      data: params.list
    })
  },
  deteleGroup (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activites/group/${params.id}`,
      method: 'delete',
      params: params.data
    })
  },
  //添加经销商
  addDealer (params) {
    return request({
      baseURL: '/cmd-admin',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: `/afanti/train/activity/dealer`,
      method: 'post',
      data: params
    })
  },
  downloadDealerError (params) {
    return request({
      baseURL: '/cmd-admin',
      responseType: 'blob',
      url: `/afanti/train/activity/dealer/exception-message`,
      method: 'get',
      params: params
    })
  },
  getDealerList (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity/dealer`,
      method: 'get',
      params: params
    })
  },
  getGroupList (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activites/group`,
      method: 'get',
      params: params
    })
  },
  bindDealerGroup (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/activites/group/dealer/associate-dealer`,
      method: 'post',
      data: params
    })
  },
  saveExcel (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity/dealer/save-excel`,
      method: 'get',
      params: params
    })
  },
  getAccountList (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity/dealer/search-author`,
      method: 'get',
      params: params
    })
  },
  checkAuthor (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity/dealer/check-author`,
      method: 'get',
      params: params
    })
  },
  saveAccount (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity/dealer/save-author`,
      method: 'post',
      data: params
    })
  },
  deleteRowKey (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity/dealer/remove-author`,
      method: 'delete',
      params: params
    })
  },
  putRemarksObj (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity/dealer/remarks`,
      method: 'put',
      data: params
    })
  },
  getFollowIdList (emp_name) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity/dealer/follow?emp_name=${emp_name}`,
      method: 'get'
    })
  },
  AddFollow (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity/dealer/follower-dealer`,
      method: 'post',
      data: params
    })
  },
  getGroupNameList (params) {
    return request({
      baseURL: '/business-mix',
      url: `/account/group/dealer-group/page`,
      method: 'get',
      params: params
    })
  },
  getDetailList (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity/dealer/activity-dealer/detail`,
      method: 'post',
      data: params
    })
  },
  saveDealer (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/activity/dealer/activity-dealer`,
      method: 'POST',
      data: params
    })
  },
}
