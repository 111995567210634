import Vue from 'vue'
import Router from 'vue-router'
import { constantRouter } from './constRouter'
import asyncRouter from './asyncRouter'

Vue.use(Router)

export const routers = constantRouter.concat(asyncRouter)

let router = new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({y: 0}),
  routes: routers
})

export default router
