import request from '@/utils/request'
export default {
    wchatCode(params) {
        return request({
            baseURL: '/wx-enterprise',
            url: '/wx/qrcode/oauth',
            method: 'get',
            params
        })
    },
    // 企微绑定认证
    qyWechat(data) {
        return request({
            baseURL: '/cas',
            url: '/ums/binging/qy/wechat',
            method: 'post',
            data,
        })
    },
    // 退出监控
    cancelRegulation(params) {
        return request({
            url: `/bfz-dy/monit-author/quit/${params}`,
            method: 'DELETE',
        })
    },
    // 保存监控主播
    saveAuthor(data) {
        return request({
            url: '/bfz-dy/monit-author/save',
            method: 'post',
            data
        })
    },
    //主播管理 - 列表
    getAuthorList(params) {
        return request({
            baseURL: '/cmd-admin',
            url: '/bi/' + params.type + '/author/list',
            method: 'get',
            params: params.data,
        })
    },
    // 活动列表
    getActivityList(params) {
        return request({
            baseURL: '/cmd-admin',
            url: '/afanti/train/activity',
            method: 'get',
            params: params
        })
    },
    // 经销商列表
    getDealerList(params) {
        return request({
            baseURL: '/cmd-admin',
            url: '/afanti/train/activity/dealer',
            method: 'get',
            params: params
        })
    },
    // 查看是否绑定企微
    judgeWechat() {
        return request({
            url: '/operator/detail',
            method: 'get'
        })
    },
    // 主播列表
    authorList(params) {
        return request({
            url: '/monit-author/list',
            method: 'get',
            params
        })
    },
    // 续期
    continueMonitor(id) {
        return request({
            url: `/monit-author/renewal/${id}`,
            method: 'get',
        })
    },
    // 查看规则
    getRuleList(id) {
        return request({
            url: `/monit-author/viewrules/${id}`,
            method: 'get'
        })
    },
    // 退出监控
    cancelMonitor(id) {
        return request({
            url: `/monit-author/quit/${id}`,
            method: 'delete'
        })
    },
    // 主播视频列表
    getAuthorVideoList(params) {
        return request({
            url: '/monit-author/aweme/list',
            method: 'get',
            params,
        })
    },
    // 监控视频数量
    getMonitVideo() {
        return request({
            url: '/monit-author/aweme/monit/size',
            method: 'get'
        })
    },
    // 监控主播数
    getMonitAuthor() {
        return request({
            url: '/monit-author/monit/size',
            method: 'get'
        })
    },
    // 保存监控主播
    saveAuthorMonit(data) {
        return request({
            url: '/monit-author/save',
            method: 'post',
            data
        })
    },
    // 监控规则下拉
    ruleGruopList() {
        return request({
            url: '/monit-author/monitoringRule',
            method: 'get'
        })
    }

}