import api from '@/api/proxy/NewVideo'
import anchor_api from '@/api/proxy/anchor'
import _ from 'lodash'

const {
  getAwemeDetail,
  getAwemeHotwords,
  getAwemeAvg,
  getFansStat,
  getComment,
  getVideoEmotion
} = api

const {
  getAnchor
} = anchor_api

export default {
  namespaced: true,

  state: {
    awemeDetail: {},
    awemeHotwords: {},
    anchor: {},
    anchorAvg: {},
    fansStat: {},
    commentList: [],
    pagination: {},
    loading: false,
    noMore: false,
    likeOrDislike: {},
    author: {},
    drawerLoading: false
  },

  actions: {
    async initVideo({
      commit,
      dispatch
    }, {
      id
    }) {
      try {
        console.log('id', id)
        const reqQueue = [{
          dispatch_name: 'getAwemeDetail',
          state_name: 'awemeDetail'
        }, {
        //   dispatch_name: 'getAwemeHotwords',
        //   state_name: 'awemeHotwords'
        // }, {
          dispatch_name: 'getVideoEmotion',
          state_name: 'likeOrDislike'
        }]

        const resp = await Promise.all(reqQueue.map(val => dispatch(val.dispatch_name, {
          id
        })))
        // commit 状态
        resp.forEach((res, idx) => {
          if (res.status === 200 && !res.data.errcode) {
            commit('changeState', {
              data: res.data,
              name: reqQueue[idx].state_name
            })
            return
          }
          throw new Error(res.data.errmsg)
        })

        const author_id = _.get(resp[0], 'data.author_id')
        if(!author_id) {
          throw new Error("获取主播ID失败")
        }
        const anchorResp = await this._vm.$api.proxy.anchor.getAnchor({id: author_id})
        commit('changeAnchor', { data: anchorResp.data })

        const anchorAvgResp = await this._vm.$api.proxy.NewVideo.getAwemeAvg({ id: author_id })
        commit('changeAnchorAvg', { data: anchorAvgResp.data })

        // const fansStatResp = await this._vm.$api.proxy.NewVideo.getFansStat({ id, author_id })
        // commit('changeFansStat', { data: fansStatResp.data })
        commit('CHANGE_LOADING', false)
      } catch (err) {
        console.error(err)
        commit('CHANGE_LOADING', false)
      }


    },

    // 详情
    getAwemeDetail(ctx, { id }) {
      return this._vm.$api.proxy.NewVideo.getAwemeDetail({ id })
    },

    // 热词
    getAwemeHotwords(ctx, { id }) {
      return this._vm.$api.proxy.NewVideo.getAwemeHotwords({ id })
    },
    // 情感分析
    getVideoEmotion(ctx, { id }) {
      return this._vm.$api.proxy.NewVideo.getVideoEmotion({ id })
    },
    // 平均点赞

    // 视频评论
    async getComment(ctx, payload = {}) {
      let { state, commit } = ctx;
      // 改变loading
      commit('changeLoading', { status: true });
      let id = payload.id || state.awemeDetail.aweme_id
      let params = {
        page_size: 25,
      };
      if (_.isEmpty(state.pagination)) {
        params.current = 1;
      } else if (state.pagination.current >= state.pagination.pages) {
        commit('changeLoading', { status: false });
        commit('changeNomore', { status: true });
        return;
      } else {
        params.current = state.pagination.current + 1;
      }
      let commentResp = await this._vm.$api.proxy.NewVideo.getComment({ id, params })
      if (commentResp.errcode == 5001) {
        commit('changeNomore', { status: true });
      }
      commit('changeLoading', { status: false });
      console.log('commentResp', commentResp)
      commit('changeComment', { comment: commentResp.data });
    },
    firstOpen(ctx) {
      console.log('first-------')
      let { state, commit } = ctx;
      commit('changeNomore', { status: false });

    }
  },

  mutations: {
    changeState(state, payload) {
      const {
        data,
        name
      } = payload
      state[name] = data
    },

    changeAnchor(state, payload) {
      const { data } = payload
      state.anchor = data
    },

    changeAnchorAvg(state, payload) {
      const { aweme_avg, author } = payload.data;
      state.author = author || {}
      state.anchorAvg = aweme_avg
    },

    changeFansStat(state, payload) {
      const { data } = payload
      console.log('我想要的数据', data)
      if (!data || !data.gender.desc.length) {
        state.fansStat = null
        return;
      }
      state.fansStat = data;
    },

    changeComment(state, payload) {
      const { comment } = payload
      console.log(comment)
      console.log(payload)
      state.pagination = comment.pagination
      state.commentList = [...state.commentList, ...comment.list]
    },

    changeLoading(state, payload) {
      const { status } = payload
      state.loading = status
    },

    changeNomore(state, payload) {
      const { status } = payload
      state.noMore = status
    },
    CHANGE_LOADING: (state, result) => {
      state.drawerLoading = result
    }
  }

}

