<template>
  <div class="echarts" ref="echarts" style="min-height: 100px; min-width: 100px; width: 100%;"></div>
</template>

<script>
  export default {
    name: "Echarts",
    props: {
      option: {
        type: Object,
        required: true,
        default: () => {}
      },
    },
    watch: {
      option (option) {
        this.echart && this.echart.setOption(option)
      }
    },
    methods: {
      resize() {
        this.echart.resize()
      }
    },
    created() {
      window.addEventListener('resize', this.resize)
    },
    mounted() {
      setTimeout(() => {
        this.echart = this.$echarts.init(this.$refs.echarts)
        this.echart.setOption(this.option)
      })
    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.resize)
    }
  }
</script>

<style scoped>

</style>