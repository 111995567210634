import request from '@/utils/request'

export default {

  query(params) {
    return request({
      url: '/aweme-favorite-group/list',
      method: 'get',
      params
    })
  },
  save(data) {
    return request({
      url: '/favoriteGroup/save',
      method: 'POST',
      data
    })
  },
  get(id) {
    return request({
      url: '/favoriteGroup/get/' + id,
      method: 'GET'
    })
  },
  delete(id) {
    return request({
      url: '/favoriteGroup/delete/' + id,
      method: 'POST'
    })
  },
  batchMonitor(type, groupIds) {
    return request({
      url: '/favoriteGroup/batchMonitor/' + type,
      method: 'POST',
      data: groupIds
    })
  },
  unMonitor(id) {
    return request({
      url: '/favoriteGroup/unMonitor/' + id,
      method: 'POST'
    })
  },
}