
/*
 *直播信息
 */
import r from "@/utils/request";
const request = function () {
  return r(...arguments).then(res => ({ data: res, status: 200 })).catch(e => (e.disableDefaultHandler && e.disableDefaultHandler(), {errcode: 5001}))
}

const liveMessage = '/live/info'

/*
 *  直播间直播时评论分析，车型跟品牌
 */
const commentAnalysis = '/live-polling/comment/analysis-new'

/* 
 *评论中热度前5的车型
 */
const commentHotCar = '/live-polling/live/series/hot'

/* 
 *直播观众性别分析
 */
const audience = '/live-polling/live/audience/analysis/gender'

/* 
*评论中热词
*/
const hotWord = '/live-polling/live/comment/hotwords'

/* 
*直播观众地区分布
*/
const audienceAdress = '/live-polling/live/audience/analysis/area'

const liveCalender = '/live/show/calendar'

export default {
  getLiveMessage({roomId}) {
    return request({
      url: `${liveMessage}/${roomId}`,
      method: 'GET',
    })
  },
  getCommentAnalysis(param) {
    return request({
      url: commentAnalysis,
      method: 'GET',
      params: {
        room_id: param
      }
    })
  },
  getHotCarType(param) {
    return request({
      url: commentHotCar,
      method: 'GET',
      params: {
        room_id: param
      }
    })
  },
  // 直播间评论列表
  getCommentList({room_id,current,page_size,word}) {
    return request({
      url: `/live-polling/${room_id}/comment`,
      method: 'GET',
      params: {
        current: current,
        page_size: page_size,
        word: word
      }
    })
  },
  getAudience(param) {
    return request({
      url: audience,
      method: 'GET',
      params: {
        room_id: param
      }
    })
  },
  // 直播分析线条图
  getAnalysisChart(param){
    return request({
      url: `/live/statistical/chart/${param}`,
      method: 'GET'
    })
  },
  getHotWord(param){
    return request({
      url: hotWord,
      method: 'GET',
      params:{
        room_id:param
      }
    })
  },
  getAudienceAdress(param){
    return request({
      url: audienceAdress,
      method: 'GET',
      params:{
        room_id:param
      }
    })
  },
  getLiveTime({begin_time,end_time,author_id}){
    return request({
      url: `${liveCalender}/${author_id}`,
      method: 'GET',
      params: {
        begin_time,
        end_time,
      }
    })
  },
  getCarCommentList({room_id,current,page_size,word}){
    return request({
      url: `/live-polling/${room_id}/series/comment`,
      method: 'GET',
      params: {
        current: current,
        page_size: page_size,
        series_name: word
      }
    })
  },
  // 直播回放列表
  getLiveVideo(id){
    return request({
      url: `/live/queryLiveInfoUrl/${id}`,
      method: 'GET',
    })
  }
}
