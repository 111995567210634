import request from "@/utils/request";

let baseURL = "/bfz-dy";
export default {
  getPlayVolumeTrend(aweme_id) {
    return request({
      baseURL,
      url: `/aweme/${ aweme_id }/play-volume-trend`,
      method: "get",
    });
  },
  //播主分析 - 帐号诊断
  accountDiagnosis(params) {
    return request({
      url: `/creator/account/diagnosis`,
      method: "get",
      params
    });
  },
  //播主分析 - 数据总览
  dataSummarize(params) {
    return request({
      url: `/creator/summarize`,
      method: "get",
      params
    });
  },
  //播主分析 - 粉丝总览
  fansOverview(params) {
    return request({
      url: `creator/fans/overview`,
      method: "get",
      params
    });
  },
  //播主分析 - 作品对比 - 平均线
  awemeCompare(params) {
    return request({
      url: `/creator/aweme/compare`,
      method: "get",
      params
    });
  },
  //播主分析 - 作品对比 - 视频列表
  awemeCompareList(params) {
    return request({
      url: `/creator/awemes`,
      method: "get",
      params
    });
  },
  //播主分析 - 粉丝画像
  fansPortrait(params) {
    return request({
      url: `/creator/fans/portrait`,
      method: "get",
      params
    });
  },
};
