<template>
  <a-layout>
    <a-layout-sider
      ref="layout-sider"
      collapsible
      v-model="collapse"
      :trigger="null"
      id="appSider"
      :width="250"
      style="z-index: 2;"
      :collapse="collapse"
    >
      <router-link :to="{path:'/dashboard'}" v-if="!collapse">
        <div class="logo">
          <img src="../../assets/logo.png" style="height: 40px;margin: auto"/>
          <!--          <p class="logo-title">-->
          <!--            <span style="font-size: 18px;">百夫长</span>-->
          <!--            <span style="font-size: 12px;"></span>-->
          <!--          </p>-->
        </div>
      </router-link>
      <menu-panel ref="menuPanel"></menu-panel>
    </a-layout-sider>
    <a-layout id="appMain" :collapse="collapse">
      <a-layout-header ref="layout-header" style="background: #fff; padding: 0;display:flex;">
        <div class="navLocation">
          <a-icon class="trigger" :type="collapse ? 'menu-unfold' : 'menu-fold'" @click="onToggle"/>
          <a-breadcrumb style="display: inline-block;">
            <a-breadcrumb-item v-for="(item, i) in currentNodePaths" :key="item.name">
              <router-link v-if="i" :to="{name:item.name,params:item.params,query:item.query}">
                {{ (item.meta || {}).title }}
              </router-link>
              <template v-else>{{ (item.meta || {}).title }}</template>
            </a-breadcrumb-item>
          </a-breadcrumb>
          <!-- <a-select :default-value="$root.dataType" @change="onDataTypeChange" :disabled="!!$route.params.dataType" style="width: 120px;margin-left: 20px">
            <a-select-option v-for="item in $dict.store.DATATYPE" :key="item[0]">{{ item[1] }}版</a-select-option>
          </a-select> -->
          <!--<span v-if="$route.name === 'index'" style="margin-left:20px;">-->
          <!--  抖音版-->
          <!--</span>-->
        </div>
        <div style="width:auto">
          <nav-panel ref="navPanel"></nav-panel>
        </div>
      </a-layout-header>
      <a-layout-content ref="layout-content" id="mainContainer" :loading="loading" style="min-height: 280px;">
        <!--<keep-alive>-->
        <!--<router-view id="mainView" v-if="$route.meta.keepAlive" ref="defaultRootView"></router-view>-->
        <!--</keep-alive>-->
        <router-view id="mainView" v-if="!$route.meta.keepAlive" ref="defaultRootView"
                     :style="$route.meta.noPadding ? {} : {padding: '18px', background: '#fff'}"></router-view>
      </a-layout-content>
      <a-layout-footer ref="layout-footer" style="text-align: center">
        <div class="copyright">Copyright 2020-广州阿凡提  粤ICP备16108470号-1</div>
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<style lang="less" scoped>
#mainContainer {
  overflow-y: hidden;
  overflow-x: auto;
}

#mainView {
  height: 100%;
}

#appSider {
  flex: 0 0 256px;
  max-width: 260px;
  min-width: 260px;
  width: 260px;
  height: 100vh;
  -webkit-box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #001529;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1em;
      background: hsla(0, 0%, 100%, 0.6);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: hsla(0, 0%, 100%, 0.795);
    }

    // &::-webkit-scrollbar-corner {
    //   background: #00152900;
    // }
}

#appMain {
  margin-left: 250px;
  transition: all 0.2s;
}

#appMain[collapse="true"] {
  margin-left: 80px;
}

#appSider[collapse="true"] {
  .logo-title {
    display: none;
  }
}

#appSider[collapse="false"] {
  .logo-title {
    display: inline;
  }
}
</style>
<style lang="less" rel="stylesheet/less">
.layout-tabs.ant-tabs {
  > .ant-tabs-bar {
    margin: 0;
    padding: 0 5px;
  }
}

.ant-layout-content {
  //background-color: white;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;
  line-height: 70px;
  background-color: #00438a;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;

  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 18px;
    color: #ffffff;

    span {
      line-height: 1;
    }
  }
}

.navLocation {
  flex: 1;

  > .anticon {
    height: 64px;
    padding: 19px 24px;
    font-size: 20px;
    cursor: pointer;
    -webkit-transition: all 0.3s, padding 0s;
    transition: all 0.3s, padding 0s;
  }

  .trigger:hover {
    //background-color: #eee;
    color: #1890ff;
  }
}

.a-main {
  padding: 2px 5px;
  background: rgb(245, 247, 249);
  //margin-top:38px;
}

#navTab {
  //position:absolute;
  //width:100%;
  margin-top: 2px;
  padding: 0 5px;
  height: 38px !important;
}

/* .a-aside {
    color: #333;
  } */
.copyright {
  height: 30px;
  background: transparent;
  color: gray;
  text-align: center;
  line-height: 30px;
}
</style>

<script>
import { routers } from "@/router";

import NavPanel from "./NavPanel";
import MenuPanel from "./MenuPanel";
import Hamburger from "./Hamburger";

function resizeMainView() {
  let $mainContainer = document.getElementById("mainContainer");
  let $mainTab = document.getElementById("navTab");
  let height = $mainContainer.offsetHeight - $mainTab.offsetHeight;

  // document.getElementById("mainView").style.height = height - 80 + "px";
}

let _collapse = "true" == window.localStorage.getItem("collapseApp");
export default {
  components: {NavPanel, MenuPanel, Hamburger},
  data() {
    return {
      currentNodePaths: [],
      currentTab: "",
      tabs: [],
      loading: false,
      collapse: _collapse
    };
  },
  watch: {
    $route() {
      this.activeTab();
    },
    collapse(val) {
      this.$refs.menuPanel.toggoleCollapse(val);
    }
  },
  methods: {
    onDataTypeChange(v) {
      // this.$nextTick(() => this.$refs.defaultRootView.$emit("init", { dataType: v }))
      if(v == this.$root.dataType)
        return;
      const a = document.createElement("a");
      a.href = process.env["VUE_APP_PATH_" + v];
      // process.env.NODE_ENV == 'development' && (a.port = process.env['VUE_APP_PORT_' + v])
      if(window.location.host === "localhost" || window.location.host === "127.0.0.1") {
        a.port = process.env["VUE_APP_PORT_" + v];
      }
      location.href = a.href;
    },
    flushBreadcrumb() {
      let paths = [];

      function getNodePath(r, name) {
        if(!r) {
          return;
        }
        if(r.name != undefined && r.name == name) {
          paths.push(r);
          return true;
        }
        // if (r.children && r.children.length) {
        //   for (let i = 0, l = r.children.length; i < l; i++) {
        //     getNodePath(r.children[i], name);
        //   }
        // }
        if(r.children && r.children.some(r => getNodePath(r, name))) {
          r.hidden || paths.push(r);
          return true;
        }
      }

      let name = this.currentTab;
      // for (let i = 0, l = routers.length; i < l; i++) {
      //   getNodePath(routers[i], name);
      // }
      routers.some(r => getNodePath(r, name));

      this.currentNodePaths = paths.reverse();
    },
    onToggle() {
      this.collapse = !this.collapse;
      window.localStorage.setItem("collapseApp", this.collapse);
      this.$bus.$emit('collapseChange', this.collapse)
    },
    isTabClosable(tab) {
      console.log(tab);
    },
    getTab(name) {
      let rs = this.tabs.filter(tab => tab.name == name);
      return rs.length > 0 ? rs[0] : null;
    },
    activeTab() {
      this.loading = true;
      const r = this.$route;
      this.tabs.forEach(t => t.depth++);
      if(r) {
        let closable = (r.meta || {}).closable;
        if(closable === undefined) {
          closable = true;
        }
        let tab = this.getTab(r.name);
        if(!tab) {
          tab = {
            title: r.meta.title,
            name: r.name,
            query: r.query,
            params: r.params,
            depth: 0,
            closable: closable
          };

          if(!r.query._current) {
            this.tabs.push(tab);
          }
        }
        tab.depth = 0; // 最先
        if(!r.query._current) {
          this.currentTab = tab.name;
        }
        this.$nextTick(() => {
          this.$refs.defaultRootView.$emit("init", r.query);
          this.loading = false;
        });
        // 显示导航
        this.flushBreadcrumb();
      }
    },
    onTabClick(name) {
      let rs = this.tabs.filter(_tab => _tab.name === name);
      this.tabs.forEach(t => t.depth++);
      rs.depth = 0;
      this.$router.push({
        name: name,
        query: rs[0].query,
        params: rs[0].params
      });
    },
    /**
     * tab移除事件
     * @param name
     */
    onTabRemove(name) {
      for(let i = 0, len = this.tabs.length; i < len; i++) {
        let t = this.tabs[i];
        if(t.name === name) {
          this.tabs.splice(i, 1);
          break;
        }
      }
      let minDepthTab = null;
      for(let i = 0, l = this.tabs.length; i < l; i++) {
        let t = this.tabs[i];
        if(minDepthTab == null || minDepthTab.depth > t.depth) {
          minDepthTab = t;
        }
      }
      if(!minDepthTab) {
        this.$router.push("/");
      } else {
        this.onTabClick(minDepthTab.name);
      }
    },
    toRouter(routerOpt, closeCurrentFlag = true) {
      if(closeCurrentFlag) {
        this.onTabRemove(this.currentTab);
      }
      this.$router.push(routerOpt);
    },
    goback() {
      this.onTabRemove(this.currentTab);
    },
    flush() {
      let q = Object.assign({}, this.$route.query);
      q._r = Math.random();
      this.$router.replace({
        name: this.$route.name,
        query: q,
        params: this.$route.params
      });
    }
  },
  created() {
    const loadingOption = {
      lock: true,
      text: "正在提交...",
      spinner: "a-icon-loading",
      background: "rgba(0, 0, 0, 0.7)"
    };
    let loading = null;
    this.$bus.$on("request:start", config => {
      if(!config.openLoading) {
        return;
      }
      // 发起请求时调起loading
      if(config.method == "post" && !loading) {
        loading = this.$loading(loadingOption);
      } else {
        this.loading = true;
      }
    });
    this.$bus.$on("request:end", config => {
      // 结束请求时关闭loading
      if(!config.openLoading) {
        return;
      }
      this.loading = false;
      _.debounce(tryCloseFullLoading, 150)(config);
    });

    const tryCloseFullLoading = config => {
      if(config.method == "post") {
        loading && loading.close();
        loading = null;
      }
    };
  },
  mounted() {
    this.$refs.menuPanel.toggoleCollapse(this.collapse);
    this.activeTab();
    this.$bus.$on("app:toRouter", this.toRouter);
    this.$bus.$on("app:goback", this.goback);
    this.$bus.$on("app:flush", this.flush);
  }
};
</script>
