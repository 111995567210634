import Layout from "../pages/layout";

/**
 * 活动管理
 */
export default {
  path: "/actManage",
  name: "actManage",
  component: Layout,
  meta: {title: "活动管理", icon: "project"},
  children: [
    // 活动设置
    {
      path: "settings",
      name: "actManage:settings",
      component: () => import("@/pages/app/actManage/actSettings"),
      meta: {title: "活动设置"}
    },

    // 经销商媒体帐号列表
    {
      path: "dealer",
      name: "actManage:dealer",
      component: () => import("@/pages/app/actManage/actDealer"),
      meta: {title: "经销商媒体帐号列表"},
      hidden: true
    },

    // KPI列表
    {
      path: "kpiList",
      name: "actManage:kpiList",
      component: () => import("@/pages/app/actManage/actKpiList"),
      meta: {title: "KPI列表"},
      hidden: true
    },

    // KPI设置
    {
      path: "kpiEdit",
      name: "actManage:kpiEdit",
      component: () => import("@/pages/app/actManage/actKpiEdit"),
      meta: {title: "KPI设置"},
      hidden: true
    },

    // 活动列表
    {
      path: "/training/index",
      name: "training:index",
      component: () => import("@/pages/app/trainingList/index.vue"),
      meta: {title: "活动列表"}
    },
    {
      path: "/training/account",
      name: "training:account",
      hidden: true,
      component: () => import("@/pages/app/trainingList/account.vue"),
      meta: {title: "媒体帐号列表"}
    },
    {
      path: "/training/configurationTemplate",
      name: "configurationTemplate",
      hidden: true,
      component: () => import("@/pages/app/trainingList/configurationTemplate.vue"),
      meta: {title: "配置导出模板"}
    },
    {
      path: "/training/kpi_detail",
      name: "kpi_detail",
      hidden: true,
      component: () => import("@/pages/app/trainingList/kpi_detail.vue"),
      meta: {title: "KPI详情"}
    },
    {
      path: "/training/dealer-detail",
      name: "dealer-detail",
      hidden: true,
      component: () => import("@/pages/app/trainingList/dealer-detail.vue"),
      meta: {title: "经销商详情"}
    },
    {
      path: "/training/template-detail",
      name: "template-detail",
      hidden: true,
      component: () => import("@/pages/app/trainingList/template-detail.vue"),
      meta: {title: "模板使用详情"}
    },

    // 活动列表V2
    {
      path: "list",
      name: "actManage:list",
      component: () => import("@/pages/app/actManage/actList"),
      meta: {title: "活动列表V2"}
    },

    // 活动分析(运营助理)
    {
      path: "analysisOperAssi",
      name: "actManage:analysisOperAssi",
      component: () => import("@/pages/app/actManage/actAnalysisOperAssi"),
      meta: {title: "活动分析(运营助理)"}
    },

    // 作品动态(运营助理)
    {
      path: "contentOperAssi",
      name: "actManage:contentOperAssi",
      component: () => import("@/pages/app/actManage/actContentOperAssi"),
      meta: { title: "作品动态(运营助理)" },
      hidden: true
    },

    // 作品动态列表(运营助理)
    {
      path: "contentListOperAssi",
      name: "actManage:contentListOperAssi",
      component: () => import("@/pages/app/actManage/actContentListOperAssi"),
      meta: { title: "作品动态列表(运营助理)" },
      hidden: true
    },

    // 作品动态详情(运营助理)
    {
      path: "contentDetailOperAssi",
      name: "actManage:contentDetailOperAssi",
      component: () => import("@/pages/app/actManage/actContentDetailOperAssi"),
      meta: { title: "作品动态详情(运营助理)" },
      hidden: true
    },

    // 学习情况(运营助理)
    {
      path: "studyOperAssi",
      name: "actManage:studyOperAssi",
      component: () => import("@/pages/app/actManage/actStudyOperAssi"),
      meta: {title: "学习情况(运营助理)"},
      hidden: true
    },

    // 模板使用(运营助理)
    {
      path: "templateOperAssi",
      name: "actManage:templateOperAssi",
      component: () => import("@/pages/app/actManage/actTemplateOperAssi"),
      meta: {title: "模板使用(运营助理)"},
      hidden: true
    },

    // 活动分析
    {
      path: "analysis",
      name: "actManage:analysis",
      component: () => import("@/pages/app/actManage/actAnalysis"),
      meta: {title: "活动分析"}
    },

    // 作品动态
    {
      path: "content",
      name: "actManage:content",
      component: () => import("@/pages/app/actManage/actContent"),
      meta: { title: "作品动态" },
      hidden: true
    },

    // 作品动态列表
    {
      path: "contentList",
      name: "actManage:contentList",
      component: () => import("@/pages/app/actManage/actContentList"),
      meta: { title: "作品动态列表" },
      hidden: true
    },

    // 作品动态详情
    {
      path: "contentDetail",
      name: "actManage:contentDetail",
      component: () => import("@/pages/app/actManage/actContentDetail"),
      meta: { title: "作品动态详情" },
      hidden: true
    },

    // 活动列表-详情
    {
      path: "details",
      name: "actManage:details",
      component: () => import("@/pages/app/actManage/actList/details"),
      meta: {title: "活动列表"},
      hidden: true
    },
    // 聚金塔活动列表
    {
      path: "jjtActives/index",
      name: "jjtActives:index",
      component: () => import("@/pages/app/jjtActives/index.vue"),
      meta: {title: "聚金塔活动列表"}
    }, 
    // 聚金塔活动详情
    {
      path: "jjtActives/detail/:groupId",
      name: "jjtActives:detail",
      component: () => import("@/pages/app/jjtActives/detail.vue"),
      meta: {title: "聚金塔活动详情"},
      hidden: true,
    }
  ]
};
