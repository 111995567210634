import Cookies from 'js-cookie';
import AppService from '@/api/AppService';
export function getToken() {
  //return Cookies.get(TokenKey)
  let val = window.localStorage.getItem('token_val');

  if (val) {
    let tokenTime = localStorage.getItem('token_time');
    // let expiresIn = localStorage.getItem('token_expiresIn')
    // let expiredTime = parseInt(tokenTime)+expiresIn*1000;
    // if(expiredTime<=new Date().getTime()){

    //   removeToken()
    //   return null
    // }
    return val;
  }
  return val;
}

//是否过期(辅助使用，不确保)
export function isTokenExpired() {
  let val = window.localStorage.getItem('token_val');
  if (!val) {
    return true;
  }
  let tokenTime = localStorage.getItem('token_time');
  let expiresIn = localStorage.getItem('token_expiresIn');
  let numReg = /[0-9]+/;
  if (!numReg.test(tokenTime) || !numReg.test(expiresIn)) {
    return true;
  }
  let expiredTime = parseInt(tokenTime) + expiresIn * 1000;
  if (expiredTime <= new Date().getTime()) {
    return true;
  }
  return false;
}

function isIP(url) {
  var pattern =
    /(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/;
  return pattern.test(url);
}

export function getTopDomain() {
  var fullDomain = window.location.hostname;
  if (isIP(fullDomain)) {
    return fullDomain;
  }
  var domainParts = fullDomain.split('.');
  var tld = '';
  if (domainParts.length > 1) {
    if (domainParts[0] !== 'localhost') {
      tld = domainParts[domainParts.length - 2] + '.' + domainParts[domainParts.length - 1];
    } else {
      tld = domainParts[domainParts.length - 1];
    }
  } else {
    tld = fullDomain;
  }
  return tld;
}

export function setCookieToken(token) {
  // 写入顶级域名， 做单点登陆
  const topDomain = getTopDomain();
  Cookies.set('token_val', token, { domain: topDomain });
}

export function setCookieUserId() {
  return new Promise((resolve) => {
    AppService.authorization(getToken()).then((r) => {
      const topDomain = getTopDomain();
      Cookies.set('user_id', r.user.id, { domain: topDomain });
      Cookies.set('user_name', r.user.username, { domain: topDomain });
      AppService.getUserInfo().then((res) => {
        if (res.code == 200) {
          Cookies.set('user_avatar_url', res.data.avatar_url, { domain: topDomain });
        }
        resolve();
      });
    });
  });
}

export function setToken(token) {
  // 写入顶级域名， 做单点登陆
  const topDomain = getTopDomain();
  Cookies.set('token_val', token.access_token, { domain: topDomain });
  window.localStorage.setItem('token_val', token.access_token);
  window.localStorage.setItem('token_expiresIn', token.expires_in);
  window.localStorage.setItem('token_time', new Date().getTime());

  return token.access_token;
}

export function getTenantId() {
  let tenantId = window.localStorage.getItem('tenantId');
  return tenantId == null ? null : parseInt(tenantId);
}

export function setTenantId(tenantId) {
  window.localStorage.setItem('tenantId', tenantId);
}

export function getCurrentUserId() {
  return window.localStorage.getItem('userId') || 0;
}

export function setCurrentUserId(userId) {
  window.localStorage.setItem('userId', userId);
}

export function removeToken() {
  const topDomain = getTopDomain();
  Cookies.remove('token_val', { domain: topDomain });
  Cookies.remove('user_val', { domain: topDomain });
  localStorage.removeItem('token_val');
  localStorage.removeItem('token_expiresIn');
  localStorage.removeItem('token_time');
  localStorage.removeItem('userId');
}

export function isLogin() {
  return !!getToken();
}

export function init() {
  let rq = getRequestQuery();
  let tokenVal = rq['token'];
  let expiresIn = rq['expiresIn'];
  if (tokenVal) {
    setToken({ access_token: tokenVal, expires_in: expiresIn });
  }
}

function getRequestQuery() {
  function getTheReq(str) {
    let theRequest = new Object();
    let strs = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
    }
    return theRequest;
  }

  let url = location.search;
  if (location.search.indexOf('?') != -1) {
    var str = url.substr(1);
    return getTheReq(str);
  } else if (location.hash.indexOf('#/') > -1) {
    var str = location.hash.replace('#/', '');
    return getTheReq(str);
  }
  return new Object();
}

export function rememberMe(p) {
  window.localStorage.setItem('loginUser', JSON.stringify(p));
}

export function clearMe(p) {
  window.localStorage.removeItem('loginUser');
}

export function getMe() {
  let u = window.localStorage.getItem('loginUser');
  if (u) {
    return JSON.parse(u);
  }
  return null;
}

export default {
  init,
  setToken,
  setCookieToken,
  setCookieUserId,
  removeToken,
  getToken,
  isTokenExpired,
  isLogin,
  getTenantId,
  setTenantId,
  getCurrentUserId,
  setCurrentUserId,
  rememberMe,
  clearMe,
  getMe,
};

export function getBase64(img) {
  let canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  return canvas.toDataURL('image/jpeg');
}

export function getImageToBase64Data(imgSrc) {
  return new Promise(function (resolve, reject) {
    const image = new Image();
    image.crossOrigin = '*'; // 图片处理跨域
    if (imgSrc.indexOf('data:image/jpeg;base64') > -1) {
      image.src = imgSrc + '?v=' + Math.random(); // 处理图片从缓存获取
    } else {
      image.src = imgSrc;
    }
    image.onload = function () {
      const imageBase64Data = getBase64(image);
      resolve(imageBase64Data);
    };
    image.onerror = function (err) {
      reject(err);
    };
  });
}
