import r from "@/utils/request";
const request = function () {
  return r(...arguments).then(res => ({ data: res, status: 200 })).catch(e => (e.disableDefaultHandler && e.disableDefaultHandler(), {errcode: 5001}))
}

export default {

  getAnchor({id}) {
    return request({
      url: '/author/'+id,
      method: 'GET'
    })
  }
}
