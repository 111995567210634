import Layout from '../pages/layout'
import Error401 from '../pages/errorPage/401';
import Error404 from '../pages/errorPage/404';
import shouquan from '../pages/app/other/douyin.vue';
import workOrder from '../pages/app/workOrder/detail.vue';
import result from '../pages/app/other/success.vue';
export const constantRouter = [
  {
    path: '/login',
    hidden: true,
    component: () => import('@/pages/login.vue')
  },
  {
    path: '/authredirect',
    component: () => import('@/pages/login.vue'),
    hidden: true
  },
  {
    path: '/404',
    component: Error404,
    hidden: true
  },
  {
    path: '/401',
    component: Error401,
    hidden: true
  },
  // {
  //   path: '/douyin/shouquan',
  //   hidden: true,
  //   component:shouquan,
  // },
  {
    path: "/workOrder/detailPhone",
    component: workOrder,
    hidden: true
  },
  {
    path: '/result/success',
    hidden: true,
    component:result,
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/index',
        redirect: '/',
        hidden: true,
        meta: { closable: false }
      },
      {
        path: '/',
        component: () => import('@/pages/index.vue'),
        name: 'index',
        meta: { icon: 'dashboard', noCache: true, title: '工作面板', noPadding: true, closable: false }
      }
    ],
    hidden: true
  },
  {
    path: '/dashboard',
    redirect: '/index',
    name: 'dashboard',
    meta: { icon: 'dashboard', noCache: true, title: '工作面板', closable: false }
  }, 
  {
    path: '*',
    hidden: true,
    redirect: '/404',
    component: Error404,
  }
]
