import request from '@/utils/request'

export default {
    //查询所有分组
    allGroupList(data) {
        return request({
            url: '/word-group/group/selectAll',
            method: 'get',
            params: {
                user_id: 'aftc'
            }
        })
    },
    //分组对应的列表
    discourseList(params) {
        console.log(params)
        return request({
            url: '/word-group/template/selectByPage',
            method: 'get',
            params
        })
    },
    //获取单个分组信息
    group(data) {
        return request({
            url: `/word-group/group/${data}`,
            method: 'get',
            params: {
                user_id: 'aftc'
            }
        })
    },
    //保存分组
    saveGroup(data) {
        return request({
            url: '/word-group/group',
            method: 'post',
            data
        })
    },
    //删除分组
    delGroup(data) {
        return request({
            url: `/word-group/group/${data}`,
            method: 'delete',
        })
    },
    //话术详情
    discourseDetail(data) {
        return request({
            url: `/word-group/template/${data}`,
            method: 'get',
        })
    },
    //保存话术
    saveDiscourse(data) {
        return request({
            url: '/word-group/template/save',
            method: 'post',
            data
        })
    },
    //删除话术
    delDiscourse(data) {
        return request({
            url: `/word-group/template/${data}`,
            method: 'delete',
        })
    },
}
