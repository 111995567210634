import request from '@/utils/request'
import exportExcel from '@/utils/exportExcel'
export default {
    // 我的经销商分组列表
    getMyDealerGroup(params) {
        return request({
            url: '/my-dealer/group',
            method: 'get',
            params
        })
    },
    // 分组总览
    getGroupMessage(id) {
        return request({
            url: `/my-dealer/overview/${id}`,
            method: 'get'
        })
    },
    // 我的经销商列表
    getMyDealerList(params) {
        return request({
            url: '/my-dealer/dealers',
            method: 'get',
            params
        })
    },
    // 我的经销商列表-导出
    getMyDealerExport(params) {
        return exportExcel({
            url: '/my-dealer/dealers-export',
            method: 'get',
            params
        })
    },
    // 我的播主列表-导出
    getMyAuthorExport(params) {
        return exportExcel({
            url: '/my-author/auhtor-export',
            method: 'get',
            params
        })
    },
    // 分组-重命名
    changeGroupRename(data) {
        return request({
            url: '/dealer/public/group/rename',
            method: 'post',
            data
        })
    },
    // 经销商私有分组重命名
    changePrivatelyRename(data) {
        return request({
            url: '/my-dealer/rename',
            method: 'PUT',
            data
        })
    },
    // 播主私有分组重命名
    changeAuthorPrivateRename(data){
        return request({
            url: '/my-author/rename',
            method: 'PUT',
            data
        })
    },
    
    // 我的经销商分组总览
    getGroupDetail(groupId) {
        return request({
            url: `/my-dealer/overview/${groupId}`,
            method: 'get'
        })
    },
    // 播主分组总览
    getAuthorGroupDetail(groupId) {
        return request({
            url: `/my-author/overview/${groupId}`,
            method: 'get'
        })
    },
    // 我的播主排序保存
    saveAuthorSort(data) {
        return request({
            url: '/my-author/sort',
            method: 'post',
            data
        })
    },
    // 我的经销商排序保存
    saveDealerSort(data) {
        return request({
            url: '/my-dealer/sort',
            method: 'post',
            data
        })
    },
    // 经销商分组-取消关注
    cancelAttention(data) {
        return request({
            url: '/dealer/public/group/unfollow',
            method: 'post',
            data
        })
    },
    // 播主公共分组-播主公共分组删除接口
    deleteDealergroup(data) {
        return request({
            url: '/dealer/group/removeDealer',
            method: 'post',
            data
        })
    },

    // 我的播主分组列表
    getMyAuthorGroup(data) {
        return request({
            url: '/my-author/group',
            method: 'get',
            data
        })
    },
    // 经销商分组-删除私有分组
    deleteGroup(data) {
        return request({
            url: '/dealer/public/group/delete/private',
            method: 'delete',
            data
        })
    },
    // 播主公共分组-播主私有分组删除接口
    delAuthorGroup(data) {
        return request({
            url: '/author/group/delete/private',
            method: 'delete',
            data
        })
    },
    // 经销商分组-删除公共分组
    deleteCommonGroup(data) {
        return request({
            url: `/dealer/public/group/${data.groupId}`,
            method: 'delete',
        })
    },
    // 播主分组-删除公共分组
    delAuthorCommonGroup(data) {
        return request({
            url: `/author/group/${data.groupId}`,
            method: 'delete',
        })
    },
    // 播主公共分组详情-将播主从播主公共分组移除接口
    delAuthorCommon(data){
        return request({
            url: `/author/group`,
            method: 'delete',
            params:data
        })
    },
    // 私有播主分组移除播主
    delAuthorPrivately(params){
        return request({
            url: `/my-author/delete`,
            method: 'delete',
            params
        })
    },

    // 经销商分组-派生播主公共分组
    changeGroup(data) {
        return request({
            url: '/dealer/public/group/createAuthorGroup',
            method: 'post',
            data
        })
    },
    // 派生播主分组-私有分组
    changePrivately(data) {
        return request({
            url: '/my-dealer/derive',
            method: 'post',
            data
        })
    },

    /*******个人中心播主相关*******/
    getAuthorList(params) {
        return request({
            url: '/my-author/authors',
            method: 'get',
            params
        })
    },

    /********我收藏的短视频相关*********/
    getAwemeList(params) {
        return request({
            url: '/aweme/favorite-list',
            method: 'get',
            params
        })
    },
    // 收藏视频分组列表
    getAwemeGroup(params) {
        return request({
            url: '/aweme-favorite-group/list',
            method: 'get',
            params
        })
    },
    createNewGroup(data){
        return request({
            url:`/aweme-favorite-group/save`,
            method:'post',
            data
        })
    },
    // 我收藏的短视频分组-删除
    getAwemeGroupDelete(id) {
        return request({
            url: `/aweme-favorite-group/delete/${id}`,
            method: 'delete',
        })
    },
    // 我收藏的短视频分组-重命名
    getAwemeGroupRename(data) {
        return request({
            url: `/aweme-favorite-group/rename`,
            method: 'put',
            data
        })
    },
    // 我收藏的短视频-取消收藏
    cancelAweme(id){
        return request({
            url: `/aweme-favorite-group/cancel/${id}`,
            method: 'delete',
    
        })
    }

}