import request from "@/utils/request";

export default {
  getList(params) {
    return request({
      baseURL: "/bfz-app",
      url: "/msgaudit/hint/list",
      method: "get",
      params,
    });
  },
  saveHint(data) {
    return request({
      baseURL: "/bfz-app",
      url: "/msgaudit/hint/save",
      method: "post",
      data,
    });
  },
  updateHint(data) {
    return request({
      baseURL: "/bfz-app",
      url: "/msgaudit/hint/update",
      method: "put",
      data,
    });
  },
  delHint(id) {
    return request({
      baseURL: "/bfz-app",
      url: `/msgaudit/hint/${ id }`,
      method: "delete",
    });
  },

};
