import request from '@/utils/request'
export default {
  query(data) {
    return request({
      url: '/openAuthAuthor/query',
      method: 'POST',
      data
    })
  },
}
