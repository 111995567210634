import Layout from "../pages/layout";

/**
 * 风火轮用户管理
 */
export default {
  path: "/fhlUser",
  name: "fhlUser",
  component: Layout,
  meta: {title: "风火轮用户管理", icon: "user"},
  children: [
    // 风火轮用户列表
    {
      path: "list",
      name: "fhlUser:list",
      component: () => import("@/pages/app/fhlUser/userList"),
      meta: { title: "风火轮用户列表" }
    },

    // 风火轮用户详情
    {
      path: "detail",
      name: "fhlUser:detail",
      component: () => import("@/pages/app/fhlUser/userDetail"),
      meta: { title: "风火轮用户详情" },
      hidden: true
    }
  ]
}
