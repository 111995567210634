import request from '@/utils/request'
export default {
    query(data) {
        return request({
            url: '/dealer/query',
            method: 'POST',
            data
        })
    },
}
