import request from '@/utils/request'
export default {

  query(data) {
    return request({
      url: '/operator/query',
      method: 'POST',
      data
    })
  },
  save(data) {
    return request({
      url: '/operator/save',
      method: 'POST',
      data
    })
  },
  weChatWorkInfo(employee_name) {
    return request({
      url: '/operator/weChatWorkInfo',
      method: 'GET',
      params: {
        employee_name
      }
    })
  },
  get(id) {
    return request({
      url: '/operator/get/'+id,
      method: 'GET'
    })
  },
  delete(id) {
    return request({
      url: '/operator/delete/'+id,
      method: 'POST'
    })
  },
}
