<template>
  <a-upload
    :custom-request="customRequest"
    :list-type="listType"
    :accept="acceptList"
    :file-list="file_list"
    :show-upload-list="showUploadList"
    :before-upload="checkUpload"
    :remove="handleRemove"
    @preview="handlePreview"
    @change="handleChange"
  >
    <div v-if="file_list.length < limitFileLength">
      <div v-if="isForm == 'script'">
        <a-button type="primary">
          {{ uploadTitle }}
        </a-button>
      </div>
      <div v-else>
        <a-icon type="plus"></a-icon>
        <div class="ant-upload-text">{{ uploadTitle }}</div>
      </div>
    </div>
  </a-upload>
</template>

<script>
import ossProtype from '@/common/oss.js';
import utils from '@/common/util.js';
import { generateRandomString } from '@/utils/util';

export default {
  props: {
    //允许上传的类型
    acceptList: {
      type: String,
      default: '.png,.jpg,.jpeg,.mp4,.mov,.m4v',
    },
    //上传显示的按钮文本
    uploadTitle: {
      type: String,
      default: '上传',
    },
    //回显上传的文件
    hasFileList: {
      type: Array,
      default: () => [],
    },
    //允许上传的文件的大小
    limitSize: {
      type: Number,
      default: 10000,
    },
    //允许上传的文件的个数
    limitFileLength: {
      type: Number,
      default: 1,
    },
    //是否打开新窗口预览
    isOpenPreview: {
      type: Boolean,
      default: false,
    },
    //判断后缀显示缩略图
    suffixList: {
      type: Array,
      default: () => ['mp4', 'mov', 'm4v'],
    },
    // 上传样式
    listType: {
      type: String,
      default: 'picture-card',
    },
    // 是否显示已上传文件
    showUploadList: {
      type: Boolean,
      default: true,
    },
    // 来自哪里的上传
    isForm: {
      type: String,
      default: '',
    },
    randomFileName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file_list: [],
      custom_request_type: 'image',
      suffix_list: [],
      // showUploadList: true,
      thumb_url: 'http://fhl-ai-video-test.oss-cn-hangzhou.aliyuncs.com/image/2021-6-4/video.png',
    };
  },
  watch: {
    hasFileList: {
      handler: function (data) {
        this.file_list = data;
      },
      immediate: true,
    },
    suffixList: {
      handler: function (data) {
        data.map((item) => {
          if (item == 'pdf') {
            this.thumb_url = 'http://fhl-ai-video-test.oss-cn-hangzhou.aliyuncs.com/image/2021-6-4/PDF.png';
          } else if (item == 'doc') {
            this.thumb_url = 'http://fhl-ai-video-test.oss-cn-hangzhou.aliyuncs.com/image/2021-8-9/doc.png';
          }
        });
        this.suffix_list = data;
      },
      immediate: true,
    },
  },
  mounted() {
    this.initOSSAuth();
  },
  methods: {
    initOSSAuth() {
      let judge_url = window.location.href.split('/').indexOf('baifuzhang.afanticar.com');
      // let bucket = judge_url != -1 ? 'fhl-ai-video' : 'fhl-ai-video-test';
      let bucket = judge_url != -1 ? 'afanti-bfz' : 'afanti-bfz-dev';
      ossProtype(bucket).then((res) => {
        this.client = res;
      });
    },
    async customRequest({ file, onSuccess }) {
      const date = new Date().getTime();
      const fileName = this.randomFileName ? `${generateRandomString(12)}.${file.type.split('/')[1]}` : file.name;
      const url = this.custom_request_type + '/' + date + '/' + fileName;
      return new Promise((resolve, reject) => {
        this.client
          .put(url, file)
          .then((data) => {
            onSuccess(data, data.url);
            resolve(data);
          })
          .catch((error) => {
            this.initOSSAuth();
            this.$message.error(`请重新上传或者刷新页面`);
            reject(error);
          });
      });
    },
    beforeUploadVideo(file) {
      const isVideo = file.type === 'video/mp4' || file.type === 'video/mov' || file.type === 'video/quicktime';
      if (!isVideo) {
        this.$message.error('请选择视频上传!');
      }

      const isLimit = file.size / 1024 / 1024 < this.limitSize;
      if (!isLimit) {
        this.$message.error(`视频不超过${this.limitSize}MB!`);
      }

      isVideo && isLimit ? (this.custom_request_type = 'video') : '';

      return isVideo && isLimit;
    },
    beforeUploadImg(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        this.$message.error('上传的图片格式只能为jpeg/jpg/png!');
      }

      const isLimit = file.size / 1024 / 1024 < this.limitSize;
      if (!isLimit) {
        this.$message.error(`图片格式不超过${this.limitSize}MB!`);
      }

      isJpgOrPng && isLimit ? (this.custom_request_type = 'image') : '';

      return isJpgOrPng && isLimit;
    },
    beforeUploadPdf(file) {
      console.log(file);
      const isPdf = file.type === 'application/pdf';
      if (!isPdf) {
        this.$message.error('上传的文件格式只能为PDF!');
      }

      const isLimit = file.size / 1024 / 1024 < this.limitSize;
      if (!isLimit) {
        this.$message.error(`文件格式不超过${this.limitSize}MB!`);
      }

      isPdf && isLimit ? (this.custom_request_type = 'pdf') : '';

      return isPdf && isLimit;
    },
    beforeUploadDoc(file) {
      let fileType = file.name.split('.');
      const fileDate = fileType.slice(-1);
      console.log('fileDate', fileDate);
      const isPdf = ['wps', 'wpt', 'doc', 'dot', 'docx', 'doct', 'docm', 'dotm'].indexOf(fileDate[0]) !== -1;
      if (!isPdf) {
        this.$message.error('上传的文件格式只能为word文档相关格式!');
      }
      const isLimit = file.size / 1024 / 1024 < this.limitSize;
      if (!isLimit) {
        this.$message.error(`文件格式不超过${this.limitSize}MB!`);
      }

      isPdf && isLimit ? (this.custom_request_type = 'doc') : '';

      return isPdf && isLimit;
    },
    beforeUploadXlsx(file) {
      let fileType = file.name.split('.');
      const fileDate = fileType.slice(-1);
      const isXls = ['xls', 'xlsx'].indexOf(fileDate[0]) !== -1;
      if (!isXls) {
        this.$message.error('上传的文件格式只能为表格文档相关格式!');
      }
      const isLimit = file.size / 1024 / 1024 < this.limitSize;
      if (!isLimit) {
        this.$message.error(`文件格式不超过${this.limitSize}MB!`);
      }

      isXls && isLimit ? (this.custom_request_type = 'xls') : '';

      return isXls && isLimit;
    },
    checkUpload(file) {
      let fileType = file.name.split('.');
      const fileDate = fileType.slice(-1);
      const isPdf = ['wps', 'wpt', 'doc', 'dot', 'docx', 'doct', 'docm', 'dotm'].indexOf(fileDate[0]) !== -1;
      const isXls = ['xls', 'xlsx'].indexOf(fileDate[0]) !== -1;
      if (this.isForm == 'script') {
        if (file.type.split('/')[0] == 'video') {
          return this.beforeUploadVideo(file);
        } else if (isXls) {
          return this.beforeUploadXlsx(file);
        } else {
          this.$message.error('请上传指定文件格式！');
          return false;
        }
      }

      if (file.type.split('/')[0] == 'image') {
        return this.beforeUploadImg(file);
      } else if (file.type.split('/')[0] == 'video') {
        return this.beforeUploadVideo(file);
      } else if (file.type.split('/')[1] == 'pdf') {
        return this.beforeUploadPdf(file);
      } else if (isPdf) {
        return this.beforeUploadDoc(file);
      } else {
        this.$message.error('请上传指定文件格式！');
        return false;
      }
    },
    handleChange(info) {
      if (info.file.status) {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-this.limitFileLength);

        if (info.file.status === 'done') {
          fileList.map((file) => {
            console.log(utils.fileSuffixTypeUtil(file.xhr, this.suffix_list));
            file.thumbUrl = utils.fileSuffixTypeUtil(file.xhr, this.suffix_list) ? this.thumb_url : file.xhr;
          });
          // this.showUploadList = false;
          this.$emit('handleUrl', info.file);
        }

        this.file_list = fileList;
      } else {
        return;
      }
    },
    async handlePreview(file) {
      let url = file.xhr || file.url;
      if (this.isOpenPreview) {
        window.open(url);
      } else {
        this.$emit('handlePreview', url);
      }
    },
    handleRemove(file) {
      let index = this.file_list.indexOf(file);
      this.$emit('handleUrl', file, 3);
    },
  },
};
</script>
