import { render, staticRenderFns } from "./BaseButton.vue?vue&type=template&id=d53c0732&"
import script from "./BaseButton.vue?vue&type=script&lang=js&"
export * from "./BaseButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BaseButton.vue?vue&type=custom&index=0&blockType=base-button&%3Aicon=icon&%3Asize=size&%3Atype=type&%3Atitle=title&%3Aloading=loading&%3Adisabled=disabled&%40onClickBtn=onClickBtn"
if (typeof block0 === 'function') block0(component)

export default component.exports