import request from "@/utils/request";


export default {
  // 数据对比
  getStatistics(params) {
    return request({
      baseUrl: "/bfz-dy",
      url: `/author/aweme/summmary`,
      method: "get",
      params
    });
  },
  // 数据对比详情
  getAwemeDetail(params) {
    return request({
      baseUrl: "/bfz-dy",
      url: `/author/aweme/detail`,
      method: "get",
      params
    });
  },
};
