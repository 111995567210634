//  type :'add' or 'reduce
const Calculation = (type, num1, num2) => {
  var temp1, temp2, a
  try {
    // 获取temp1小数点后的长度
    temp1 = num1.toString().split('.')[1].length
  } catch (e) {
    temp1 = 0
  }
  try {
    // 获取temp2小数点后的长度
    temp2 = num2.toString().split('.')[1].length
  } catch (e) {
    temp2 = 0
  }
  // Math.max(temp1, temp2) 为了获取temp1和temp2两个值中较大的一个
  // Math.pow(a,b) 表示 a 的 b 次方i
  a = Math.pow(10, Math.max(temp1, temp2))

  // 计算的方式是先将所有的小数乘为整数，待加减运算执行完之后再除去对应的 a 的值，将其变为小数输出
  // 先判断执行的方式是否是加法，不是的话则执行减法运算
  return type === 'add' ? (num1 * a + num2 * a) / a : (num1 * a - num2 * a) / a
}

// 万、亿位处理
const numEdit = (value) => {
  if(value&&typeof value==='number'){
    if (Math.abs(value) > 100000000) {
      value = `${parseFloat((value / 100000000).toFixed(2))}亿`
    } else if (Math.abs(value) > 10000) {
      value = `${parseFloat((value / 10000).toFixed(2))}w`
    }else{
      value = parseFloat(value.toFixed(2))
    }
  }
  return value;
}

const numEditComma= (val) =>{
  if (!val) return 0.0
  let USPrice = Number.parseFloat(val).toLocaleString('en-US')

  let lastDot = USPrice.toString().indexOf('.')
  // // 完全是整数, 需要添加小数点
  // if (lastDot === -1) USPrice += '.00'

  // // 返回数据是一位小数，用0补齐为两位小数
  // if (USPrice.toString().substring(lastDot + 1).length === 1) USPrice += '0'

  return USPrice
}

/*
*
日期转换，毫秒数改为时间日期
*
*/
const timeChange = (time, state) => {
  let unixTimestamp = new Date(time);
  let commonTime = unixTimestamp.toLocaleString();
  if (state === 'LL') {
    Date.prototype.toLocaleString = function () {
      let h = this.getHours() < 10 ? '0' + this.getHours() : this.getHours();
      let m = this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes();
      let s = this.getSeconds() < 10 ? '0' + this.getSeconds() : this.getSeconds();
      return this.getFullYear() + '-' + (this.getMonth() + 1) + '-' + this.getDate() + ' ' + h + ":" + m + ":" + s;
    };
    return commonTime;
  } else if (state === 'Ll') {
    Date.prototype.toLocaleString = function () {
      return this.getFullYear() + '-' + (this.getMonth() + 1) + '-' + this.getDate() + ' ' + this.getHours() + ":" + this.getMinutes() + ":" + this.getSeconds();
    };
    return commonTime;
  } else {
    Date.prototype.toLocaleString = function () {
      return this.getFullYear() + '-' + (this.getMonth() + 1) + '-' + this.getDate();
    };
    return commonTime;
  }
}

/*
 *   两个时间差
 */
const timeInterval = (startDate, endDate) => {
  if(!startDate||!endDate) return;
  // 一般后端返回时间格式为2016-03-28 10:27:00，需要转为'2016/03/28 10:17:22'
  startDate = startDate.replace(/\-/g, "/");
  endDate = endDate.replace(/\-/g, "/");
  const sDate = new Date(startDate);
  const eDate = new Date(endDate)
  const timeI = eDate.getTime() - sDate.getTime();
  let day = Math.floor(timeI / (24 * 3600 * 1000));
  let leave1 = timeI % (24 * 3600 * 1000);
  let hour = Math.floor(leave1 / (3600 * 1000))
  let leave2 = leave1 % (3600 * 1000);
  let minutes = Math.floor(leave2 / (60 * 1000));
  let leave3 = leave2 % (60 * 1000)
  let seconds = Math.round(leave3 / 1000);
  let value = ''
  if(day){
    value = `${day}天${hour}小时${minutes}分${seconds}秒`
    return value
  }
  if(hour){
    value = `${hour}小时${minutes}分${seconds}秒`
    return value
  }
  if(minutes){
    value = `${minutes}分${seconds}秒`
    return value
  }
  if(seconds){
    value = `${minutes}分${seconds}秒`
    return value
  }
}


/*
 *   判断是否为demo
 */
const isDemo = () => {
  if (window.location.host.indexOf('demo') >= 0) {
    return true
  } else {
    return false
  }
}

const parseTime = (time, cFormat) => {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

export default {
  Calculation,
  numEdit,
  timeChange,
  timeInterval,
  numEditComma,
  isDemo,
  parseTime
}
