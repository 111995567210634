import request from '@/utils/request'


export default {
  //
  fetchAuthorDetailKS(params) {
    const { authorId } = params

    return request({
      baseURL: '/bfz-ks',
      url: `/author/get/${authorId}`,
      method: 'GET'
    })
  },
  fetchOpenAuthUrlKS(params) {
    return request({
      baseURL: '/bfz-ks',
      url: `/openAuth/url`,
      method: 'GET',
      params
    })
  },
  fetchOpenAuthStatusKS(params) {
    const { authorId } = params

    return request({
      baseURL: '/bfz-ks',
      url: `/openAuth/status/${authorId}`,
      method: 'GET'
    })
  },
  getSeriesStatusKS(params) {
    const { authorId } = params

    return request({
      baseURL: '/bfz-ks',
      url: `/serviceAuthor/status/${authorId}`,
      method: 'GET'
    })
  },
  getQRCodeKS(data) {
    return request({
      baseURL: '/bfz-ks',
      url: '/serviceAuthor/getQRCode',
      method: 'POST',
      data
    })
  },
  getScanCodeStatusKS(data) {
    const { authorId } = data

    return request({
      baseURL: '/bfz-ks',
      url: `/serviceAuthor/getScanCodeStatus/${authorId}`,
      method: 'POST',
      data
    })
  }
}
