import request from '@/utils/request'
export default {

  /**
   * 获取认证信息
   */
  authorization() {
    return request({
      url: '/authorization',
      method: 'POST'
    })
  },
  modifyPassword(params) {
    return request({
      url: '/authorization/modifyPassword',
      method: 'POST',
      data: params
    })
  },
  loginInit() {
    return request({
      url: '/auth/init',
      method: 'GET'
    })
  },
  login(data) {
    return request({
      url: '/auth/login',
      method: 'POST',
      data
    })
  },
  logout(){
    return request({
      baseURL: '/cas',
      url:'/ums/user/logout'
    })
  },
  /**
   * 动态路由
   */
  getMenus(){
    return request({
      baseURL: '/cas',
      url:'/ums/user/authority?client_module_id=3',
      method:'get',
    })
  },
  wchatCode(params) {
    return request({
      baseURL:'/wx-enterprise',
      url: '/wx/qrcode/oauth',
      method: 'get',
      params
    })
  },
  // 个人中心信息
  getUserInfo () {
    return request({
      baseURL:'/cas',
      url:'/ums/user/info',
      method: 'get',
    })
  },
}
