import request from '@/utils/request'
import excelRequest from '@/utils/excelRequest'

const module = '/ad-onedata'
const hjkModule = '/hjk'


export default {
    //巨量广告素材报表
    //获取列表
    getMaterialList(data) {
        return request({
            baseURL: module,
            url: '/sysAdmin/aodMaterialReport/page',
            method: 'post',
            data
        })
    },
    getAdvertiserPrincipalList() {
        return request({
            baseURL: 'hjk',
            url: '/advertiser/principalList',
            method: 'get',
        })
    },
    // 导出
    exportMaterialList(data) {
        return excelRequest({
            baseURL: module,
            url: '/sysAdmin/aodMaterialReport/list/export',
            method: 'post',
            responseType: 'blob',
            data
        })
    },
    // 获取媒体播放地址
    getMaterialMediaUrl(adMaterialId) {
        return request({
            baseURL: hjkModule,
            url: `/sysAdmin/hjkAdMaterial/getPlayUrlByMaterialId/${adMaterialId}`,
            method: 'get',
        })
    },


    // 橙子建站落地页报表
    getLandingList(data) {
        return request({
            baseURL: module,
            url: '/sysAdmin/aodLandingPageReport/page',
            method: 'post',
            data
        })
    },
    // 导出
    exportLadingPageList(data) {
        return excelRequest({
            baseURL: module,
            url: '/sysAdmin/aodLandingPageReport/list/export',
            method: 'post',
            responseType: 'blob',
            data
        })
    },
}