import Vue from 'vue'
import Vuex from 'vuex'
import infiniteScroll from 'vue-infinite-scroll'
import './icons'
import common from "@/mirror-modules/common";
import live_analyze from './live_analyze'
import new_video from './new_video'
import live_now from './live_now'
import console_live from './console_live'
import temporary_parameters from './temporary_parameters'

Vue.prototype.common = common
Vue.use(Vuex)
Vue.use(infiniteScroll)
const store = new Vuex.Store({
  state: {
    userInfo: null
  },
  mutations: {
    SET_USER_INFO (state, payload) {
      state.userInfo = payload
    }
  },
  modules: {
    live_analyze,
    new_video,
    live_now,
    console_live,
    temporary_parameters
  }
})

export {
  store
}
