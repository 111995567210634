<template>
  <div>
    <a-spin tip="加载中..." :spinning="showLoading">
      <div class="all-box">
        <!-- 工单详情 -->
        <div class="card">
          <div class="flex-b">
            <a-tag :color="colorStatus" class="tag__s"> {{ strStatus }} </a-tag>
            <h3>{{ dtlMsg.title }}</h3>
          </div>
          <div class="word__des">
            <div class="color1 lebel_tips">需求描述：</div>
            <div
              class="right_box"
              v-html="dtlMsg.description.replace(/(\r\n|\n|\r)/gm, '<br />')"
            ></div>
          </div>
          <div v-if="dtlMsg.attaches.length">
            <a-list bordered :data-source="dtlMsg.attaches">
              <a-list-item slot="renderItem" slot-scope="item, index">
                <div class="flex-be">
                  <div class="select-exe">{{ item.attachName }}</div>
                  <a class="down" :href="item.attachUrl" download="blog"
                    >下载</a
                  >
                </div>
              </a-list-item>
            </a-list>
          </div>
          <div class="pdt12">
            <span class="color1">更多信息：</span
            ><a-button type="link" @click="openModal(1)">点击查看</a-button>
          </div>
        </div>
        <!-- 工单节点 -->
        <div class="card">
          <h3>流程进度（打勾表示节点完成）</h3>
          <a-steps direction="vertical">
            <a-step
              v-for="item in dtlMsg.processes"
              :key="item.nodeCode"
              :title="item.nodeName"
              :status="disposeStepsStatus(item.nodeStatus, item.nodeName)"
            >
              <div slot="description">
                <div
                  v-for="(k, dindex) in item.processVOList"
                  :key="k.id"
                  class="desbox"
                >
                  <div class="desbox-time" v-show="k.atime">
                    <a-icon type="sound" class="sound_tips" /> {{ k.atime }}
                  </div>
                  <div class="desbox-msg">
                    <div class="desbox-msg-name" v-show="k.actorOauthName">
                      {{ k.actorOauthName
                      }}<span v-show="k.isMe"
                        >(我)</span
                      ><span v-show="k.nodeCode != 'fetch_create'&&k.remark">：</span>
                    </div>
                    <div class="desbox-msg-remark">{{ k.remark }}</div>
                  </div>
                  <div
                    v-for="d in k.attaches"
                    :key="d.id"
                    class="description-file"
                  >
                    <div
                      v-if="
                        dtlMsg.status == 2 && k.nodeCode == 'audit_delivery'
                      "
                    >
                      {{ d.mtime }}
                    </div>
                    <div class="flex-be">
                      <div class="select-exe">{{ d.attachName }}</div>
                      <div class="down">
                        <a-button
                          type="link"
                          v-if="
                            dtlMsg.status == 2 &&
                            k.nodeCode == 'audit_delivery' &&
                            k.isMe
                          "
                          style="color: red"
                          @click="deleteFile(d.id)"
                          :loading="deleteLoading && activeId == d.id"
                          >删除</a-button
                        >
                        <a :href="d.attachUrl" download="blog" class="btndown"
                          >下载</a
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="upload-file"
                    v-if="
                      k.nodeCode == 'audit_delivery' &&
                      k.isMe &&
                      dtlMsg.status == 2 &&
                      dindex == item.processVOList.length - 1
                    "
                  >
                    <span>添加附件：</span>
                    <exc-upload
                      @handleUrl="handleUrl"
                      :limitFileLength="10"
                      btnSize="small"
                      :hasFileList="excList"
                      acceptList=''
                      :limitSize='20'
                    >
                    </exc-upload>
                  </div>
                </div>
              </div>
            </a-step>
          </a-steps>
        </div>
        <!-- 工单备注 -->
        <div class="card" v-if="dtlMsg.remarks">
          <h4>工单备注(从新到旧排序)</h4>
          <div v-for="r in dtlMsg.remarks" :key="r.id">
            <div class="remark-box">
              <div class="remark-box-time">
                <a-icon type="sound" class="sound_tips color2" />{{ r.atime }}
              </div>
              <div class="remark-box-msg">
                <div class="remark-box-msg-name">{{ r.creatorName }}：</div>
                <div class="remark-box-msg-remark">{{ r.remark }}</div>
              </div>

              <div
                v-for="d in r.attaches"
                :key="d.id"
                class="description-file flex-be"
              >
                <div class="select-exe">{{ d.attachName }}</div>
                <a :href="d.attachUrl" download="blog" class="btndown">下载</a>
              </div>
            </div>
          </div>
        </div>
        <!-- 工单评价 -->
        <div class="card" v-if="dtlMsg.appraisal">
          <h4>工单评价</h4>
          <div class="remark-box appraisal">
            <div class="remark-box-time">
              <a-icon type="sound" class="sound_tips color2" />{{
                dtlMsg.appraisalTime
              }}
            </div>
            <div class="remark-box-msg">
              <div class="remark-box-msg-name">{{ dtlMsg.creatorName }}：</div>
              <div class="remark-box-msg-remark">{{ dtlMsg.appraisal }}</div>
            </div>
          </div>
        </div>
        <div class="footer">
          <!-- v-show="showBtn" -->
          <a-popover
            v-model="visibleAction"
            title="请点击操作类型"
            trigger="click"
          >
            <div slot="content">
              <div
                class="mgn-bt"
                v-if="
                  (dtlMsg.nodeCode == 'fetch_receive' ||
                    dtlMsg.nodeCode == 'comprehensive_evaluation') &&
                  showBtn
                "
              >
                <a-button type="danger" @click="openModal(2, 1)"
                  >拒绝工单</a-button
                >
              </div>
              <div
                class="mgn-bt"
                v-if="dtlMsg.nodeCode == 'audit_delivery' && showBtn"
              >
                <a-button type="danger" @click="openModal(2, 2)"
                  >审核驳回</a-button
                >
              </div>
              <div class="mgn-bt" v-if="dtlMsg.status == 1 && dtlMsg.isMe">
                <a-button type="dashed" @click="openModal(2, 3)"
                  >取消工单</a-button
                >
              </div>
              <div class="mgn-bt" v-if="dtlMsg.status == 1 && showBtn">
                <a-button type="primary" @click="openModal(3)"
                  >继续流转</a-button
                >
              </div>
              <div class="mgn-bt" v-if="dtlMsg.isMe && dtlMsg.status == 1">
                <a-button
                  class="ml10"
                  type="primary"
                  @click="openRemark"
                  :loading="btnLoading"
                  >备注</a-button
                >
              </div>
            </div>
            <a-button
              type="primary"
              v-show="(showBtn || dtlMsg.isMe) && dtlMsg.status == 1"
              >操作</a-button
            >
          </a-popover>
          <a-button
            type="primary"
            @click="openModal(2, 4)"
            v-if="dtlMsg.status !== 1 && dtlMsg.isMe"
            >评价</a-button
          >
          <!-- <a-button
            class="ml10"
            type="primary"
            v-show="dtlMsg.isMe && dtlMsg.status !== 1"
            @click="resetOpenWork"
            :loading="btnLoading"
            >重新打开</a-button
          > -->
          <!-- <a-button
            v-if="dtlMsg.isMe && dtlMsg.status == 1"
            class="ml10"
            type="primary"
            @click="openRemark"
            :loading="btnLoading"
            >备注</a-button
          > -->
        </div>
      </div>
    </a-spin>
    <!-- 工单详情弹窗 -->
    <detail-modal
      :showDtlModal="showDtlModal"
      :detailData="dtlMsg"
      @closeModal="closeModal(1)"
    />
    <!-- 拒绝，驳回，评论弹窗 -->
    <cancel-modal
      :modalType="cancelType"
      :showModal="showOneInput"
      :confirmLoading="cancelLoading"
      @closeModal="closeModal(2)"
      @saveOneInput="saveOneInput"
      ref="cancelSetting"
    />
    <!-- 流转工单 -->
    <execute-settting
      :modalType="modalType"
      :showModal="showSetting"
      :executeLoading="executeLoading"
      @closeModal="closeModal(3)"
      @saveExecute="saveExecute"
      ref="Execute"
    />
    <!-- 工单备注 -->
    <remark-modal
      :modalType="modalType"
      :showModal="showRemark"
      :remarkLoading="remarkLoading"
      @closeModal="closeModal(4)"
      @saveRemark="saveRemark"
      ref="remarkModal"
    />
  </div>
</template>
<script>
import _https from "@/api/workOrder";
import DetailModal from "./components/DetailModal.vue";
import CancelModal from "./components/CancelModal.vue";
import ExecuteSettting from "./components/ExecuteSetting.vue";
import RemarkModal from "./components/RemarkModal.vue";
import ExcUpload from "@/components/exc-upload.vue";
import { auth, bus } from "../../../utils";

export default {
  data() {
    return {
      showLoading: false,
      dtlMsg: {
        attaches: [],
        description: "",
      },
      colorStatus: "green",
      strStatus: "处理中",
      showDtlModal: false,
      showOneInput: false,
      cancelLoading: false,
      modalType: "",
      showSetting: false,
      showBtn: false,
      executeLoading: false,
      app_id: "ww0442e19148683e19",
      visibleAction: false,
      cancelType: 0,
      btnLoading: false,
      deleteLoading: false,
      excList: [],
      remarkLoading: false,
      showRemark: false,
      activeId: "",
    };
  },
  components: {
    DetailModal,
    CancelModal,
    ExecuteSettting,
    ExcUpload,
    RemarkModal,
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/wxwork/i) == "wxwork") {
      if (this.$route.query.code) {
        this.getExchangeToken(this.$route.query.code);
      } else {
        this.wechatDispose();
      }
    } else {
      this.getInit();
    }
    // console.log("ua", ua);
  },
  methods: {
    // code 置换token
    getExchangeToken(value) {
      // console.log(
      //   "process.env.VUE_APP_BASE_AGENTID,",
      //   process.env.VUE_APP_BASE_AGENTID
      // );
      auth.setToken({access_token:''});
      let payload = {
        grant_type: "qy_wechat",
        qy_wechat: value, //主要填这个参数，填企业微信的传回来的code
        qy_wechat_type: "code",
        qy_wechat_agent_id: process.env.VUE_APP_BASE_AGENTID,
      };
      this.$api.AppService.login(payload).then((res) => {
        if (res.code == 200) {
          auth.setToken({
            access_token: res.data.token,
            expires_in: res.data.expiresIn,
          });
          this.getInit();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 构造网页授权链接,获取code
    wechatDispose() {
      let url = window.location.href;
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
        this.app_id
      }&redirect_uri=${encodeURIComponent(
        url
      )}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
    },
    disposeStatus(key) {
      switch (key) {
        case 1:
          this.strStatus = "处理中";
          this.colorStatus = "cyan";
          break;
        case 2:
          this.strStatus = "已完成";
          this.colorStatus = "green";
          break;
        case 3:
          this.strStatus = "已取消";
          this.colorStatus = "purple";
          break;
        case 4:
          this.strStatus = "已拒绝";
          this.colorStatus = "red";
          break;
        default:
          break;
      }
    },
    // 当前节点位置与状态
    disposeStepsStatus(key, name) {
      let str = "";
      switch (key) {
        case 0:
          if (name == "已取消") {
            str = "error";
          } else {
            str = "process";
          }
          break;
        case 1:
          str = "finish";
          break;
        case 2:
          str = "error";
          break;
        case 3:
          str = "wait";
          break;
        default:
          str = "wait";
          break;
      }
      return str;
    },
    async getInit() {
      await this.getWordDetail();
      this.judgeUser();
    },
    async getWordDetail() {
      this.showLoading = true;
      let id = this.$route.query.id;
      await _https
        .getWordOrderDetail(id)
        .then((res) => {
          if (res.code == 200) {
            this.dtlMsg = res.data;
            this.disposeStatus(res.data.status);
          }
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    judgeUser() {
      const { orderCode, nodeCode } = this.dtlMsg;
      let payload = {
        orderCode,
        nodeCode,
      };
      _https.judgeUser(payload).then((res) => {
        // console.log(res);
        this.showBtn = res.data;
      });
    },
    /***
     * @ fetch_create,创建取数工单
     * @ fetch_receive,接收取数工单
     * @ technological assessment,技术评估
     * @ comprehensive_evaluation,综合评估
     * @ fetch_implement,报表实施
     * @ audit_delivery,审核交付
     * **/
    closeModal(key) {
      switch (key) {
        case 1:
          this.showDtlModal = false;
          break;
        case 2:
          this.showOneInput = false;
          break;
        case 3:
          this.showSetting = false;
          break;
        case 4:
          this.showRemark = false;
          break;
        default:
          break;
      }
    },
    openModal(key, type) {
      this.modalType = this.dtlMsg.nodeCode;
      this.visibleAction = false;
      if (type) {
        this.cancelType = type;
      }
      switch (key) {
        case 1:
          this.showDtlModal = true;
          break;
        case 2:
          this.showOneInput = true;
          this.$nextTick(() => {
            this.$refs.cancelSetting.initFrom();
          });
          break;
        case 3:
          this.showSetting = true;
          this.$nextTick(() => {
            this.$refs.Execute.getExcutePeopleList({
              nodeCode: this.dtlMsg.nodeCode,
              orderCode: this.dtlMsg.orderCode,
            });
            this.$refs.Execute.initFrom();
          });

          break;
        default:
          break;
      }
    },
    /***
     * @fetch_receive ：拒绝工单
     *  */
    saveOneInput(remark) {
      this.cancelLoading = true;
      const { orderCode, nodeCode } = this.dtlMsg;
      let payload = {
        remark,
        orderCode,
        node: nodeCode,
      };
      let httpApi = "";
      switch (this.cancelType) {
        case 1:
          httpApi = "refuseWork";
          break;
        case 2:
          httpApi = "turnDownAudit";
          break;
        case 3:
          httpApi = "cancelWorkOrder";
          break;
        case 4:
          httpApi = "commentWorkOrder";
          delete payload.remark;
          payload.appraise = remark;
          break;
        default:
          break;
      }
      _https[httpApi](payload)
        .then((res) => {
          if (res.code == 200) {
            this.showOneInput = false;
            this.getInit();
            this.$message.success("保存操作成功！");
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.cancelLoading = false;
        });
    },
    /***
     * @fetch_receive ：接受工单
     */
    saveExecute(value) {
      this.executeLoading = true;
      const { orderCode, nodeCode } = this.dtlMsg;
      const { isSkipNextNode, nextNodeDealerMan, remark, attaches } = value;
      let payload = {
        isSkipNextNode,
        nextNodeDealerMan,
        remark,
        attaches,
        orderCode,
        node: nodeCode,
      };
      let httpApi = "";
      // console.log("nodeCode", nodeCode);
      switch (nodeCode) {
        case "fetch_receive":
          httpApi = "acceptWork";
          break;
        case "technological assessment":
          httpApi = "skillWork";
          break;
        case "comprehensive_evaluation":
          httpApi = "assessWork";
          break;
        case "fetch_implement":
          httpApi = "statementWork";
          break;
        case "audit_delivery":
          httpApi = "auditDelivery";
          break;
        default:
          break;
      }
      _https[httpApi](payload)
        .then((res) => {
          if (res.code == 200) {
            this.showSetting = false;
            this.getInit();
            this.$message.success("保存操作成功！");
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.executeLoading = false;
        });
    },
    // 重新打开
    resetOpenWork() {
      this.btnLoading = true;
      _https
        .resetWork(this.dtlMsg.orderCode)
        .then((res) => {
          if (res.code == 200) {
            this.getInit();
            this.$message.success("操作成功！");
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    // 删除附件
    deleteFile(id) {
      this.activeId = id;
      this.deleteLoading = true;
      _https
        .deletWorkFile(id)
        .then((res) => {
          if (res.code == 200) {
            this.getInit();
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    handleUrl(val, type) {
      const { processes } = this.dtlMsg;
      let arr = processes[processes.length - 1].processVOList;
      let id = arr[arr.length - 1].id;
      const { size, name, uid, xhr } = val;
      // console.log("val", val);
      if (type == 3) {
        // console.log(111);
      } else {
        let obj = {
          attachSize: size,
          attachName: name,
          attachUrl: xhr,
          type: 1,
          attachSource: 3,
          bizId: id,
        };
        _https
          .saveWorkFile(obj)
          .then((res) => {
            if (res.code == 200) {
              this.getInit();
              this.$message.success("成功添加附件");
            } else {
              this.$message.error("保存附件失败！");
            }
          })
          .finally(() => {
            this.excList = [];
          });
      }
    },
    openRemark() {
      this.showRemark = true;
      this.$nextTick(() => {
        this.$refs.remarkModal.initFrom();
      });
    },
    saveRemark(value) {
      const { remark, attachList } = value;
      let payload = {
        remark,
        orderCode: this.dtlMsg.orderCode,
        attachList,
      };
      this.remarkLoading = true;
      _https
        .addWorkRemark(payload)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("成功添加备注");
            this.showRemark = false;
            this.getInit();
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.remarkLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.ml10 {
  margin-left: 10px;
}
.pdt12 {
  padding-top: 12px;
}
.tag__s {
  font-size: 16px;
  padding: 2px 8px;
}
.word__des {
  padding: 8px 0;
  display: flex;
  .lebel_tips {
    width: 86px;
  }
  .right_box {
    flex: 1;
  }
}
.color1 {
  color: black;
}
.flex-be {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .select-exe {
    flex: 7;
  }
  .down {
    flex: 3;
    text-align: right;
  }
}
.description-file {
  background-color: rgba($color: #bbb, $alpha: 0.3);
  padding: 4px 8px;
  margin: 4px 0;
}
.sound_tips {
  font-size: 16px;
  margin-right: 3px;
  color: #333;
}
.color2 {
  color: rgb(243, 159, 5);
}
.upload-file {
  padding: 6px;
}
.flex-b {
  display: flex;
  align-items: center;
  h3 {
    margin-left: 12px;
    margin-bottom: 0;
  }
}
.all-box {
  width: 500px;
  margin: 0 auto;
}
.card {
  width: 540px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  padding: 24px;
  margin-bottom: 24px;
}
.footer {
  display: flex;
  justify-content: center;
}
.mgn-bt {
  margin-bottom: 10px;
  text-align: center;
}
.desbox {
  margin-bottom: 12px;
  &-msg {
    display: flex;
    &-name {
      color: #888;
      font-weight: bold;
    }
    &-remark {
      color: #aaa;
      // text-indent: 2em;
      text-align: justify;
      flex: 1;
    }
  }
  &-time {
    color: #888;
  }
}
.remark-box {
  border-bottom: 1px solid #e3e3e3;
  padding: 10px 0;

  &-time {
    color: #888;
  }
  &-msg {
    display: flex;
    &-name {
      color: #888;
      font-weight: bold;
    }
    &-remark {
      flex: 1;
      color: #aaa;
      text-align: justify;
    }
  }
}
.appraisal {
  border-bottom: none;
}

@media screen and (max-width: 500px) {
  .all-box {
    width: 90%;
    margin: 24px 5%;
    padding-bottom: 24px;
  }
  .card {
    width: 100%;
  }
  .btndown {
    padding: 0 15px;
  }
}
</style>
