import request from '@/utils/request';
import { HJK } from '@/constant/api';

export default {
  getAccountAuthRecordListApi(data) {
    return request({
      baseURL: HJK,
      url: `/sysAdmin/hjkAdPlatformAccountAuthRecord/managePage`,
      method: 'POST',
      data,
    });
  },

  exportAccountAuthRecordApi(data) {
    return request({
      baseURL: HJK,
      responseType: 'blob',
      url: `/sysAdmin/hjkAdPlatformAccountAuthRecord/list/export`,
      method: 'post',
      data
    });
  },
};
