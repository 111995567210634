import RouteView from '../pages/layout/RouteView'

/**
 * 审核管理
 */
export default {
  path: "/pphtManage",
  name: "pphtManage:accountListIndex",
  component: RouteView,
  meta: {title: "广告主管理", icon: "notification"},
  redirect: '/pphtManage/accountList',
  children: [
    // 广告主管理列表
    {
      path: "/pphtManage/accountList",
      name: "pphtManage:accountList",
      component: () => import("@/pages/app/pphtManage/accountManage/accountList"),
      meta: {title: "广告主账号管理"}
    },
    // 广告主管理详情
    {
      path: "/pphtManage/accountDetail",
      name: "pphtManage:accountDetail",
      component: () => import("@/pages/app/pphtManage/accountManage/accountDetail"),
      hidden: true,
      meta: {title: "新建广告主"}
    },
    // 广告主账号管理状态设置
    {
      path: "/pphtManage/accountState",
      name: "pphtManage:accountState",
      component: () => import("@/pages/app/pphtManage/accountManage/accountState"),
      hidden: true,
      meta: {title: "广告主账号状态"}
    },
    // 广告主平台账套管理
    {
      path: "/pphtManage/platformManage",
      name: "pphtManage:platformManage",
      component: () => import("@/pages/app/pphtManage/platformManage/index"),
      meta: {title: "广告主平台账套管理"}
    },
    // 广告主平台账套管理详情
    {
      path: "/pphtManage/platformSetting",
      name: "pphtManage:platformSetting",
      component: () => import("@/pages/app/pphtManage/platformManage/setting"),
      hidden: true,
      meta: {title: "设置托管账套"}
    },
    // 广告主账号余额
    {
      path: "/pphtManage/accountBalance",
      name: "pphtManage:accountBalance",
      component: () => import("@/pages/app/pphtManage/accountBalance/index"),
      meta: {title: "广告主账号余额"}
    },
    // 广告主监控管理
    {
      path: "/pphtManage/monitorManage",
      name: "pphtManage:monitorManage",
      component: () => import("@/pages/app/pphtManage/monitorManage/index"),
      meta: {title: "广告主监控管理"}
    },
    // 广告主监控管理详情
    {
      path: "/pphtManage/monitorDetail",
      name: "pphtManage:monitorDetail",
      component: () => import("@/pages/app/pphtManage/monitorManage/detail"),
      hidden: true,
      meta: {title: "设置监测链接"}
    },
    // 广告主授权
    {
      path: "/pphtManage/authorizeManage",
      name: "pphtManage:authorizeManage",
      component: () => import("@/pages/app/pphtManage/authorizeManage/index"),
      meta: {title: "广告主授权"}
    },
    // 广告主授权详情
    {
      path: "/pphtManage/authorizeDetail",
      name: "pphtManage:authorizeDetail",
      component: () => import("@/pages/app/pphtManage/authorizeManage/detail"),
      hidden: true,
      meta: {title: "组织绑定状态"}
    },
    // 广告主管理看板
    {
      path: "/pphtManage/boardManage",
      name: "pphtManage:boardManage",
      component: () => import("@/pages/app/pphtManage/boardManage/index"),
      meta: {title: "广告主管理看板"}
    },
    
    // 广告主分组管理
    {
      path: "/pphtManage/advGroupManage",
      name: "pphtManage:advGroupManage",
      component: () => import("@/pages/app/pphtManage/advGroupManage/index"),
      meta: {title: "广告主分组管理"}
    },
    // 广告主分组管理-分组管理
    {
      path: "/pphtManage/advGroupList",
      name: "pphtManage:advGroupList",
      component: () => import("@/pages/app/pphtManage/advGroupManage/advGroupList"),
      hidden: true,
      meta: {title: "分组管理"}
    },

    // 广告主销售目标城市管理
    {
      path: "/pphtManage/advTargetCityManage",
      name: "pphtManage:advTargetCityManage",
      component: () => import("@/pages/app/pphtManage/advTargetCityManage/index"),
      meta: {title: "广告主销售目标城市管理"}
    },
    // 广告主销售目标城市管理-销售目标城市详情
    {
      path: "/pphtManage/cityList",
      name: "pphtManage:cityList",
      hidden: true,
      component: () => import("@/pages/app/pphtManage/advTargetCityManage/cityList"),
      meta: {title: "销售目标城市详情"}
    },
  ]
}
