<template>
  <div>
    <a-modal
      title="继续流转"
      :visible="showModal"
      :confirm-loading="executeLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskClosable="false"
      :destroyOnClose="true"
    >
      <h4>请确保本节点已完成才进行流转动作</h4>
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item
          label="技术评估"
          prop="isSkipNextNode"
          v-if="modalType == 'fetch_receive'"
        >
          <a-select
            v-model="form.isSkipNextNode"
            placeholder="请选择是否需要技术评审"
            @change="changeCode"
          >
            <a-select-option :value="0"> 不需要 </a-select-option>
            <a-select-option :value="1"> 需要 </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="下个节点受理人"
          prop="nextNodeDealerMan"
          v-if="modalType !== 'audit_delivery'"
        >
          <a-select
            v-model="form.nextNodeDealerMan"
            show-search
            option-filter-prop="children"
            allow-clear
            placeholder="请选择受理人"
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
            style="width: 100%"
          >
            <a-select-option
              :value="item.dealerManId"
              v-for="item in peopleList"
              :key="item.dealerManId"
            >
              {{ item.dealerManName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <div class="textarea-wrapper">
            <a-input
              v-model="form.remark"
              type="textarea"
              placeholder="可以总结本节点工作完成情况及一些说明"
              :auto-size="{ minRows: 4, maxRows: 5 }"
              :maxLength="200"
              @change="changeDes()"
            />
            <span class="m-count">{{ desCount }}</span>
          </div>
        </a-form-model-item>

        <a-form-model-item label="添加附件">
          <exc-upload
            @handleUrl="handleUrl"
            :limitFileLength="limitFileLength"
            acceptList=""
            :limitSize="20"
          >
          </exc-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import ExcUpload from "@/components/exc-upload.vue";
import _https from "@/api/workOrder";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    executeLoading: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      limitFileLength: 10,
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
      form: {
        isSkipNextNode: undefined,
        nextNodeDealerMan: undefined,
        remark: "",
      },
      rules: {
        isSkipNextNode: [
          {
            required: true,
            message: "请选择是否需要技术评估",
            trigger: "change",
          },
        ],
        nextNodeDealerMan: [
          { required: true, message: "请选择节点受理人", trigger: "change" },
        ],
      },
      excList: [],
      peopleList: [],
      desCount: "0/200",
    };
  },
  components: {
    ExcUpload,
  },
  methods: {
    initFrom() {
      this.form = {
        isSkipNextNode: undefined,
        nextNodeDealerMan: undefined,
        remark: "",
      };
      this.excList = [];
      this.desCount = "0/200";
    },
    getExcutePeopleList(value, type) {
      let arr = [
        "fetch_create",
        "fetch_receive",
        "technological assessment",
        "comprehensive_evaluation",
        "fetch_implement",
        "audit_delivery",
      ];
      let index = arr.indexOf(value.nodeCode);
      let skip = arr[index + 1] === "comprehensive_evaluation" || type ? 2 : 1;
      let payLoad = {
        nodeCode: type ? arr[index + 2] : arr[index + 1],
        orderCode: value.orderCode,
        skip: skip,
      };
      this.codePeople = value;
      _https.getExcutePeopleList(payLoad).then((res) => {
        if (res.code == 200) {
          this.peopleList = res.data;
        }
      });
    },
    getCheckedUser({ checkedUser }) {
      this.checkedUser = checkedUser || [];
    },

    handleOk(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const { isSkipNextNode, nextNodeDealerMan, remark } = this.form;
          let arr = [];
          this.excList.map((item) => {
            arr.push({
              attachUrl: item.xhr,
              attachName: item.name,
              attachSize: item.size,
              id: item.uid,
              attachType: 1,
            });
          });
          let value = {
            isSkipNextNode:
              this.modalType == "fetch_receive" ? !isSkipNextNode : undefined,
            nextNodeDealerMan,
            remark,
            attaches: arr,
          };
          this.$emit("saveExecute", value);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleUrl(val, type) {
      console.log(val, type);
      const { size, name, uid, xhr } = val;
      if (type == 3) {
        this.excList = this.excList.filter((item) => {
          return item.uid !== uid;
        });
      } else {
        let obj = {
          size,
          name,
          uid,
          xhr,
        };

        this.excList.push(obj);
      }
    },
    handleCancel(e) {
      this.$emit("closeModal", 3);
    },
    changeDes() {
      this.desCount = `${this.form.remark.length}/200`;
    },
    // 节点选择
    changeCode() {
      this.form.nextNodeDealerMan = [];
      console.log("this.form.isSkipNextNode", this.form.isSkipNextNode);
      if (!this.form.isSkipNextNode) {
        this.getExcutePeopleList(this.codePeople, 1);
      } else {
        this.getExcutePeopleList(this.codePeople);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.textarea-wrapper {
  position: relative;
  display: block;

  .m-textarea {
    padding: 8px 12px;
    height: 100%;
  }

  .m-count {
    color: #808080;
    background: #fff;
    position: absolute;
    font-size: 12px;
    bottom: 8px;
    right: 12px;
  }
}
</style>
