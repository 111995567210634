import request from '@/utils/request'
let baseModule = '/cmd-admin'
import axios from 'axios'
const baseURL = "/mock";
const nr = axios.create({
    baseURL: baseURL, // api的base_url
    timeout: 60000, // request timeout
})
export default {
    // list-mine:我的取数规则 ;list-all:所有取数规则
    getRuleTable(payload) {
        return request({
            baseURL: baseModule,
            url: `/self-get-data/rule/${payload.type}`,
            method: 'get',
            params: {
                rule_name: payload.rule_name,
                page_size: payload.page_size,
                current: payload.current
            }
        })
    },
    // 导出结果列表
    exportExport(params) {
        return request({
            baseURL: baseModule,
            url: '/self-get-data/export-record/list-mine',
            method: 'get',
            params
        })
    },
    // 删除规则
    deleteMine(id) {
        return request({
            baseURL: baseModule,
            url: `/self-get-data/rule/${id}`,
            method: 'delete',
            data: {
                id: id
            }
        })
    },
    // 拷贝规则
    copyRule(payload) {
        return request({
            baseURL: baseModule,
            url: '/self-get-data/rule/copy',
            method: 'post',
            data: payload
        })
    },
    // 模型列表
    getModelList() {
        return request({
            baseURL: baseModule,
            url: '/self-get-data/model/list',
            method: 'get',
        })
    },
    // mock数据
    getDealerModel() {
        return nr.get('/dealer-model')
    },
    // 导出
    exportRule(id) {
        return request({
            baseURL: baseModule,
            url: `/self-get-data/rule-export/${id}`,
            method: 'get',
        })
    },
    // 规则详情
    exportRuleDetail(id) {
        return request({
            baseURL: baseModule,
            url: `/self-get-data/rule/${id}`,
            method: 'get',
        })
    },
    // 新建规则
    createRule(data) {
        return request({
            baseURL: baseModule,
            url: data.id ? '/self-get-data/rule/update' : '/self-get-data/rule/create',
            method: data.id ? 'put' : 'post',
            data
        })
    },
    // 导入经销商模板下公共分组分页
    dealerTemplete(params) {
        return request({
            baseURL: '/business-mix',
            url: '/account/group/dealer-group/page',
            method: 'get',
            params
        })
    },
    // // 更新规则
    // updataRule(params) {
    //     return request({
    //         baseURL: baseModule,
    //         url: '/self-get-data/rule/update',
    //         method: 'put',
    //         params
    //     })
    // },
}