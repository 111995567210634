import RouteView from '../pages/layout/RouteView'

/**
 * 审核管理
 */
export default {
  path: "/pphtManage",
  name: "pphtManage:accountAuthRecord:accountList",
  component: RouteView,
  meta: {title: "账套管理", icon: "notification"},
  redirect: '/pphtManage/accountAuthRecord/accountList',
  children: [
    {
      path: "/pphtManage/accountAuthRecord/accountList",
      name: "pphtManage:accountAuthRecord:accountList",
      component: () => import("@/pages/app/pphtManage/accountAuthRecord/accountList"),
      meta: {title: "账套列表"}
    },
    {
      path: "/pphtManage/accountAuthRecord/tagList",
      name: "pphtManage:accountAuthRecord:tagList",
      component: () => import("@/pages/app/pphtManage/accountAuthRecord/tagList"),
      hidden: true,
      meta: {title: "账套标签"}
    },
  ]
}
