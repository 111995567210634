import request from '@/utils/request'

export default {
    //视频
    video(data) {
        return request({
        url: '/billboard/hot-video/list',
        method: 'get',
        })
    },
    //汽车
    car(data) {
        return request({
        url: `/billboard/car/list/${data.rank_type}`,
        method: 'get',
        data
        })
    },
    //音乐
    music(data) {
        return request({
        url: `/billboard/music/list/${data.rank_type}`,
        method: 'get',
        })
    },
    //经销商
    dealer(data) {
        return request({
        url: '/billboard/dealer/list',
        method: 'get',
        params:data
        })
    },
    // 热词列表
    topic(params){
        return request({
            url: '/hotsearch-sentences/list',
            method: 'get',
            params
        })
    },
    // 热词详情
    topicDetail(params){
        return request({
            url:"/hotsearch-sentences/info",
            method:'get',
            params
        })
    },
    // 热词详情视频列表
    topicVideo(params){
        return request({
            url:'/hotsearch-sentences/videoList',
            method:'get',
            params
        })
    },
    // 热词详情趋势
    topicChart(params){
        return request({
            url:'/hotsearch-sentences/trend',
            method:'get',
            params
        })
    },
    // 主体
    subject(params){
        return request({
            url:'/principal/list',
            method:'get',
            params
        })
    },
    challenge(params){
        return request({
            url:'/billboard/challenge/list',
            method:'get',
            params
        })
    },
    // 视频经销商详情
    dealerDetail(params){
        return request({
            url:`/billboard/details/dealer/${params}`,
            method:'get',
            // params
        })
    },
    // 挑战榜详情视频列表
    challengeDetail(params){
        return request({
            url:`/billboard/details/challenge/aweme/${params}`,
            method:'get',
            // params
        })
    },
    // 挑战榜趋势列表
    challengeTendency(params){
        return request({
            url:`/billboard/details/challenge/trend/${params}`,
            method:'get',
            // params
        })
    },
    // 关键词列表
    getKeyWords(){
        return request({
            url:'/keyword/query',
            method:'get',
            // params
        }) 
    },
    // 关键词视频列表
    getKeyWordVideo(params){
        return request({
            url:'/keyword/search/video',
            method:'get',
            params
        })
    },
    // 关键词视频详情
    getKeyWordDetail(params){
        return request({
            url:'/keyword/search/video/details',
            method:'get',
            params,
        })
    }

}