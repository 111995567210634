import request from '@/utils/request'

export default {
  // 获取模板分类列表
  fetchTempCateList () {
    return request({
      baseURL: '/ai-video-admin',
      url: `/gwp/module-group/get/list-tag`,
      method: 'get'
    })
  },
  // 获取模板难度列表
  fetchTempLevelList () {
    return request({
      baseURL: '/ai-video-admin',
      url: `/module/form`,
      method: 'get'
    })
  },
  // 获取主体列表
  fetchPrincipalList () {
    return request({
      baseURL: '/ai-video-admin',
      url: `/module/brands`,
      method: 'get'
    })
  },
  // 获取使用数据列表
  fetchUsageDataList (params) {
    return request({
      url: `/module/use/list`,
      method: 'get',
      params
    })
  },
  // 导出使用数据
  exportUsageData (params) {
    return request({
      url: `/module/use/export`,
      method: 'get',
      params,
      responseType: 'blob'
    })
  },
  // 获取发布视频失败详情列表(使用数据的)
  fetchUsageDataPublishFailList (params) {
    return request({
      url: `/module/use/publish/fail`,
      method: 'get',
      params
    })
  },
  // 获取使用详情列表
  fetchUsageDetailList (params) {
    return request({
      url: `/module/use/detail`,
      method: 'get',
      params
    })
  },
  // 导出使用详情
  exportUsageDetail (params) {
    return request({
      url: `/module/use/detail/export`,
      method: 'get',
      params,
      responseType: 'blob'
    })
  },
  // 获取发布视频失败详情列表(使用详情的)
  fetchUsageDetailPublishFailList (params) {
    return request({
      url: `/module/use/publish/fail`,
      method: 'get',
      params
    })
  }
}
