import request from '@/utils/request'
import exportExcel from '@/utils/exportExcel'

export default {
  // 播主分析-直播数据-直播概况
  fetchLiveStatistics(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/live/statistics`,
      method: 'GET',
      params
    })
  },
  // 播主分析-直播数据-直播概况下直播分页数据
  fetchLiveStatisticsList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/live/statistics/list`,
      method: 'GET',
      params
    })
  },
  // 播主分析-直播数据-直播列表数据导出
  exportLiveStatisticsList(params) {
    return exportExcel({
      baseURL: '/bfz-dy',
      url: `/live/excel`,
      method: 'GET',
      params
    })
  },


  //直播复盘助手
  //查询直播详情
  getLiveChartData(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/live/statistics/chart/data`,
      method: 'GET',
      params
    })
  },
  //查询直播详情 - 图表
  getLiveDetail(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/live/detail`,
      method: 'GET',
      params
    })
  },
  //视频转译为主播话术文本
  translationLiveForText(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/live/audio/text/translation`,
      method: 'GET',
      params
    })
  },
  //主播话术文本导出报表
  exportLiveText(params) {
    return request({
      responseType: 'blob',
      baseURL: '/operation-efficiency-up',
      url: `/live/audio/text/list/export`,
      method: 'GET',
      params
    })
  },
  //查询主播话术文本
  getLiveText(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/live/audio/text/list`,
      method: 'GET',
      params
    })
  },
  //查询视频主播文本和用户评论联动数据
  getLiveTextAndComment(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/live/text-and-comment`,
      method: 'GET',
      params
    })
  },
  //查询直播用户评论数据
  getLiveComment(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/live/comment`,
      method: 'GET',
      params
    })
  },
  //获取直播录制视频播放链接
  getLiveVideo(params) {
    return request({
      baseURL: '/content-center',
      url: `/v1/video-live-info/get-record-play`,
      // baseURL: '/bfz-bff',
      // url: '/nmcms/get/record/play/list',
      method: 'get',
      params
    })
  },
  //oss资源解冻和更新解冻列表
  restoreLiveVideo(data) {
    return request({
      baseURL: '/content-center',
      url: `/v1/Live-video-restore/restore-asyn-and-update-retore-list`,
      // baseURL: '/bfz-bff',
      // url: '/nmcms/restore/Asyn/And/Update/Retore/List',
      method: 'POST',
      data
    })
  },
  
  //P9直播任务爬取
  submitP9Task(data) {
    return request({
      baseURL: '/bfz-bff',
      url: `/nmcms/p9/task/submit`,
      method: 'POST',
      data
    })
  },
  //查询抖音直播总结数据（看板数据）
  getOverviewDY(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/nmcms/p9/edouyin/live/overview/v1',
      method: 'post',
      data
    })
  },
  //查询抖音直播大屏数据
  getScreenDataDY(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/nmcms/p9/edouyin/live/screendata/v1',
      method: 'post',
      data
    })
  },
  //P9-主播话术文本导出报表
  exportLiveTextDY(params) {
    return request({
      responseType: 'blob',
      baseURL: '/bfz-bff',
      url: `/nmcms/p9/live/audio/text/list/export`,
      method: 'GET',
      params
    })
  },
  //P9-查询主播话术文本
  getLiveTextDY(params) {
    return request({
      baseURL: '/bfz-bff',
      url: `/nmcms/p9/live/audio/text/list`,
      method: 'GET',
      params
    })
  },
  //P9-查询视频主播文本和用户评论联动数据
  getLiveTextAndCommentDY(params) {
    return request({
      baseURL: '/bfz-bff',
      url: `/nmcms/P9/live/text-and-comment`,
      method: 'GET',
      params
    })
  },
  //批量查询直播间的P9直播数据采集状态
  getTextAndCommentStatusDY(data) {
    return request({
      baseURL: '/bfz-bff',
      url: `/nmcms/sys/p9/live/crawl-task/v1`,
      method: 'post',
      data
    })
  },
  
  
}
