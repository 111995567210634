import router from "./router";
import Vue from "vue";
import { Message } from "ant-design-vue";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; // progress bar style
import { getToken, init as authInit, removeToken, getTopDomain } from "@/utils/auth"; // getToken from cookie
import { bus } from "@/utils";
import { trackRequest, trackEvent } from "@/utils/track";
import AppService from "@/api/AppService";
import Cookies from 'js-cookie'


NProgress.configure({showSpinner: false});

const whiteList = ["/login", "/regist", "/401", "/404","/result/success", "/workOrder/detailPhone", "/chatgpt"];// no redirect whitelist
function isInWhiteList(path) {
  for(let i = 0; i < whiteList.length; i++) {
    let tmp = whiteList[i];
    if(tmp == path) {
      return true;
    }
  }
  return false;
}

// permissiom judge function
//资源控制（最小粒度）
function hasPermission(resCode) {
  return true;
  if(router.app.isRoot) {
    return true;
  }
  if(!resCode) { //如果未编码，放行
    return true;
  }
  let reslist = router.app.resources || [];

  let tmp = reslist.filter(r => r.code == resCode);
  if(tmp.length > 0) {
    return true;
  }
  return false;
}

function hasRole(roleCode) {
  if(router.app.isRoot) {
    return true;
  }
  let roles = router.app.roles || [];

  let tmp = roles.filter(r => r.roleCode == roleCode);
  return tmp.length > 0;
}

function hasDomain(domainId) {
  let resGroups = router.app.resGroups || [];
  let dResGroups = resGroups.filter(r => r.domainId == domainId && r.groupType == 1);//菜单

  return dResGroups.length > 0;
}

function getMergeRoleAttrs() { //获取合并数据权限属性
  let roles = router.app.roles || [];
  let r = {"minDiscount": 80};
  let dataRoles = roles.filter(r => r.type == 2);
  for(let i = 0, l = dataRoles.length; i < l; i++) {
    let extAttrs = dataRoles[i].extAttrs || "{}";
    Object.assign(r, JSON.parse(extAttrs));
  }
  return r;
}

Vue.prototype.$hasPermission = hasPermission;
Vue.prototype.$hasRole = hasRole;
Vue.prototype.$hasDomain = hasDomain;
Vue.prototype.$getMergeRoleAttrs = getMergeRoleAttrs;

function gotoNext(to, next) {

  if(to.path === "/login") {
    next({path: "/"});
    return;
  }

  if(to.path.indexOf("/token") > -1) {
    next({path: "/"});
    return;
  }
  let _m = to.meta || {};
  if(hasPermission(_m.perm)) {
    next();
  } else {
    next({path: "/401", replace: true, query: {noGoBack: true}});
  }
}


export default {

  init() {
    let asyncRouteLoaded = false;
    authInit();
    router.beforeEach((to, from, next) => {
      const app = router.app;

      if(isInWhiteList(to.path)) { // 在免登录白名单，直接进入
        next();
        return;
      }
      let isLogged = app.isLogged || false;
      //NProgress.start() // start progress bar
      if(isLogged) { //如果登陆了
        gotoNext(to, next);
        return;
      }

      if(getToken()) { // determine if there has token
        /* has token*/

        AppService.authorization(getToken()).then(r => {
          const topDomain = getTopDomain()
          const { department, enterpriseWechatUserId, enterpriseWechatUserName, id, username } = r.user;
          const user = { department, enterpriseWechatUserId, enterpriseWechatUserName, id, username };
          Cookies.set('user_val', JSON.stringify(user), { domain: topDomain });

          let user_id = r.user.id;
          let user_name = r.user.username
          window.localStorage.setItem("user_id", user_id);
          window.localStorage.setItem("user_name",user_name);
          window.localStorage.setItem("department", r.user.department);
          window.localStorage.setItem("enterprise_wechat_userId", r.user.enterpriseWechatUserId);
          window.localStorage.setItem("enterprise_wechat_userName", r.user.enterpriseWechatUserName);
          router.app.isLogged = true;
          router.app.user = r.user;
          router.app.merchant = r.merchant || {};
          bus.$emit("app:authorizated", r);
          gotoNext(to, next);
        }).catch((error, disableDefaultHandler) => {
          console.log(error);

          //Message.error('认证失败，请重新登陆！')
          removeToken();
          next({path: "/login"});

        });
      } else {


        next("/login"); // 否则全部重定向到登录页
        //NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it

      }
    });

    router.afterEach((to, from) => {
      NProgress.done(); // finish progress bar
      let title
      if(from.path=='/'){
        title = null
      }else{
        title = JSON.parse(localStorage.menus).find((item)=>{
          return from.path.indexOf(item.path)>-1
        })?.title || from.meta.title
      }
      bus.$emit("routerChange");
      trackRequest(to, trackEvent.PAGE_VIEW, {
        referPagePath: from.path,
        referPageName: title,
      });
    });

  }
};
