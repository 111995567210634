import request from '@/utils/request'

export default {
  // 获取主体列表
  fetchPrincipalList() {
    return request({
      baseURL: '/base',
      url: `/principal/list/v2`,
      method: 'get'
    })
  },
  // 获取区域列表
  fetchRegionList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/base/region/by/pal`,
      method: 'get',
      params
    })
  }
}
