import RouteView from '../pages/layout/RouteView';
// import aigc from './aigc';

/**
 * 小红书代运营
 */
export default {
  path: '/xhsAgency',
  name: 'xhsAgency',
  component: RouteView,
  meta: { title: '小红书代运营', icon: 'bulb' },
  children: [
    // 笔记推送
    {
      path: 'xhsNotePush',
      name: 'xhsNotePush',
      component: () => import('@/pages/app/xhsAgency/xhsNotePush'),
      meta: { title: '笔记推送', noPadding: true },
    },
    // AI作文
    {
      path: 'makeAiStory',
      name: 'makeAiStory',
      component: () => import('@/pages/app/xhsAgency/copywritingList/makeAiStory'),
      meta: { title: 'AI作文', noPadding: true },
    },
    // 草稿箱
    {
      path: 'copywritingList',
      name: 'copywritingList',
      component: () => import('@/pages/app/xhsAgency/copywritingList'),
      meta: { title: '草稿箱', noPadding: true },
    },
    // AI作图
    {
      path: 'AIDrawing',
      name: 'AIDrawing',
      component: () => import('@/pages/app/xhsAgency/AIDrawing'),
      meta: { title: 'AI作图' },
    },
    // 媒体号列表
    {
      path: 'xhsMediaList',
      name: 'xhsMediaList',
      component: () => import('@/pages/app/xhsAgency/xhsMediaList'),
      meta: { title: '媒体号列表' },
    },
    // 媒体号分组管理
    {
      path: 'xhsMediaGroup',
      name: 'xhsMediaGroup',
      component: () => import('@/pages/app/xhsAgency/xhsMediaGroup'),
      meta: { title: '分组管理', noPadding: true },
    },
    // 笔记明细
    {
      path: 'xhsNoteAnalysis',
      name: 'xhsNoteAnalysis',
      component: () => import('@/pages/app/xhsAgency/xhsNoteAnalysis'),
      meta: { title: '笔记明细' },
    },
    // 卖点库
    {
      path: 'sellingPoints',
      name: 'sellingPoints',
      component: () => import('@/pages/app/xhsAgency/aigc/sellingPoints'),
      meta: { title: '卖点库' },
    },
    // 采集任务
    {
      path: 'collectTask',
      name: 'collectTask',
      component: () => import('@/pages/app/xhsAgency/aigc/collectTask'),
      meta: { title: '采集任务' },
    },
    // 选题库
    {
      path: 'xhsTopicList',
      name: 'xhsTopicList',
      component: () => import('@/pages/app/xhsAgency/xhsTopicList'),
      meta: { title: '选题库' },
    },
    // 知识库
    {
      path: 'knowledgeStore',
      name: 'knowledgeStore',
      component: () => import('@/pages/app/xhsAgency/aigc/knowledgeStore'),
      meta: { title: '知识库' },
    },
    // 卖点列表
    {
      path: 'sellingPoints',
      name: 'sellingPoints',
      component: () => import('@/pages/app/xhsAgency/aigc/sellingPoints'),
      meta: { title: '卖点列表' },
    },
    // 模板编辑器
    {
      path: 'imageTemplate',
      name: 'imageTemplate',
      component: () => import('@/pages/app/xhsAgency/aigc/imageTemplate'),
      meta: { title: '模板编辑器' },
    },
    // AI助手
    {
      path: 'AiAssistant',
      name: 'AiAssistant',
      component: () => import('@/pages/app/xhsAgency/aigc/AiAssistant'),
      meta: { title: '红闪闪AI助手' },
    },
    // 发布笔记
    {
      path: 'articleDetail',
      name: 'articleDetail',
      component: () => import('@/pages/app/xhsAgency/aigc/articleDetail'),
      meta: { title: '爱写笔记', noPadding: true },
    },

    // 拼图模板
    {
      path: 'imageLayout',
      name: 'imageLayout',
      component: () => import('@/pages/app/xhsAgency/aigc/imageLayout'),
      meta: { title: '拼图' },
    },
    // 运营计划
    {
      path: 'operatePlan',
      name: 'operatePlan',
      component: () => import('@/pages/app/xhsAgency/aigc/operatePlan'),
      meta: { title: '运营计划' },
    },
    // 运营执行
    {
      path: 'operateExecution',
      name: 'operateExecution',
      component: () => import('@/pages/app/xhsAgency/aigc/operateExecution'),
      meta: { title: '运营执行' },
    },
    // 自动化生产配置
    {
      path: 'AutoProduction',
      name: 'AutoProduction',
      component: () => import('@/pages/app/xhsAgency/AutoProduction'),
      meta: { title: '自动化生产配置' },
    },
    // 元素管理
    {
      path: 'element',
      name: 'element',
      component: () => import('@/pages/app/xhsAgency/Element'),
      meta: { title: '元素管理' },
    },

    // 元素分组管理
    {
      path: 'elementGroup',
      name: 'elementGroup',
      component: () => import('@/pages/app/xhsAgency/ElementGroup'),
      meta: { title: '元素分组管理' },
    },

    // AI日志
    {
      path: 'AiDrawLog',
      name: 'AiDrawLog',
      component: () => import('@/pages/app/xhsAgency/AiDrawLog'),
      meta: { title: 'AI任务' },
    },

    // AI作图管理
    {
      path: 'AiDrawManage',
      name: 'AiDrawManage',
      component: () => import('@/pages/app/xhsAgency/AiDrawManage'),
      meta: { title: 'AI作图管理' },
    },

    // AI视频制作
    {
      path: 'AiVideo',
      name: 'AiVideo',
      component: () => import('@/pages/app/xhsAgency/AiVideo'),
      meta: { title: '视频制作', noPadding: true },
    },

    // AI作文管理
    {
      path: 'AiWritingManage',
      name: 'AiWritingManage',
      component: () => import('@/pages/app/xhsAgency/AiWritingManage'),
      meta: { title: 'AI作文管理' },
    },
    // 团队管理
    {
      path: 'xhsTeamManage',
      name: 'xhsTeamManage',
      component: () => import('@/pages/app/xhsAgency/xhsTeamManage'),
      meta: { title: '团队管理' },
    },
    // 成员和品牌
    {
      path: 'xhsGroupBrandManage',
      name: 'xhsGroupBrandManage',
      component: () => import('@/pages/app/xhsAgency/xhsGroupBrandManage'),
      meta: { title: '成员和品牌' },
    },
    // 任务列表
    {
      path: 'missionList',
      name: 'missionList',
      component: () => import('@/pages/app/xhsAgency/missionList'),
      meta: { title: '任务列表' },
    },
    {
      path: 'dataCollectionDetail',
      name: 'dataCollectionDetail',
      component: () => import('@/pages/app/xhsAgency/missionList/dataCollectionDetail'),
      meta: { title: '任务详情' },
    },
    {
      path: 'missionExamine',
      name: 'missionExamine',
      component: () => import('@/pages/app/xhsAgency/missionList/missionExamine'),
      meta: { title: '任务审核' },
    },

    {
      path: 'publishStatistics',
      name: 'publishStatistics',
      component: () => import('@/pages/app/xhsAgency/publishStatistics'),
      meta: { title: '发布统计' },
    },
    {
      path: 'planStatistics',
      name: 'planStatistics',
      component: () => import('@/pages/app/xhsAgency/planStatistics'),
      meta: { title: '计划统计' },
    },
    {
      path: 'digitalOperation',
      name: 'digitalOperation',
      component: () => import('@/pages/app/xhsAgency/digitalOperation'),
      meta: { title: '数字化运营' },
    },
    {
      path: 'xhsPicStore',
      name: 'xhsPicStore',
      component: () => import('@/pages/app/xhsAgency/xhsPicStore'),
      meta: { title: '图片库', noPadding: true },
    },
    {
      path: 'personalPicStore',
      name: 'personalPicStore',
      component: () => import('@/pages/app/xhsAgency/xhsPicStore/personalPicStore.vue'),
      meta: { title: '我的图库', noPadding: true },
    },
    // ---------------------------- v6 路由整理 已废弃页面
    // 图片库
    // {
    //   path: 'xhsPicLibrary',
    //   name: 'xhsPicLibrary',
    //   component: () => import('@/pages/app/xhsAgency/xhsPicLibrary'),
    //   meta: { title: '图片库(旧)' },
    // },
    // {
    //   path: 'task',
    //   name: 'task',
    //   component: () => import('@/pages/app/xhsAgency/aigc/task'),
    //   meta: { title: '任务列表' },
    // },
    // {
    //   path: 'taskDetail',
    //   name: 'taskDetail',
    //   component: () => import('@/pages/app/xhsAgency/aigc/taskDetail'),
    //   meta: { title: '任务详情' },
    //   hidden: true,
    // },
    // {
    //   path: 'article',
    //   name: 'article',
    //   component: () => import('@/pages/app/xhsAgency/aigc/article'),
    //   meta: { title: '笔记列表' },
    // },
    // {
    //   path: 'copywritingDetail',
    //   name: 'copywritingDetail',
    //   component: () => import('@/pages/app/xhsAgency/copywritingList/copywritingDetail'),
    //   meta: { title: '笔记详情', noPadding: true },
    //   hidden: true,
    // },
    // {
    //   path: 'updateCopywriting',
    //   name: 'updateCopywriting',
    //   component: () => import('@/pages/app/xhsAgency/copywritingList/updateCopywriting'),
    //   meta: { title: '文案编辑' },
    //   hidden: true,
    // },
  ],
};
