import request from '@/utils/request'
export default {

  query(data) {
    return request({
      url: '/author/query',
      method: 'POST',
      data
    })
  },
  queryStat(data) {
    return request({
      url: '/author/queryStat',
      method: 'POST',
      data
    })
  },
  get(id) {
    return request({
      url: '/author/get/'+id,
      method: 'GET'
    })
  },
  findGap(data) {
    return request({
      url: '/author/findGap',
      method: 'POST',
      data
    })
  },
  queryGap(data) {
    return request({
      url: '/author/queryGap',
      method: 'POST',
      data
    })
  },
}
