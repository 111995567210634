import request from "@/utils/request";
import axios from "axios"
const baseURLTwo = '/jjt-mqtt'
const base = axios.create({
    baseURL: '/jjt-mqtt', // api的base_url
    timeout: 60000// request timeout
})

export default {
    getTableList(params) {
        return request({
            url: '/favoriteGroup/liveMonitoring',
            method: 'get',
            params,
        })
    },
    getBarrage(params) {
        return base.get('/jjt/mqtt/live/client-setting', {params})
    },
    livingStatus(params) {
        return request({
            url: '/live/livingStatus',
            method: 'get',
            params,
        })
    },
    addLiveRoom(params) {
        return base.post('/jjt/mqtt/live/add-live-room', params)
    }
}
