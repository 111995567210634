import request from "@/utils/request";

export default {
  findFavoriteBiz(params) {
    return request({
      url: "/aweme/favorite-list",
      method: "get",
      params
    });
  },
  findFavoriteBizStatu(data) {
    return request({
      url: "/authorisation/status/queryStatus",
      method: "POST",
      data
    });
  },
  batchFavorite(bizId, groupIds) {
    return request({
      url: "/favorite/batchFavorite/" + bizId,
      method: "POST",
      data: groupIds
    });
  },
  findByBiz(bizId) {
    return request({
      url: "/favorite/findByBiz/" + bizId,
      method: "GET"
    });
  },
  // countByGroupIds(groupIds) {
  //   return request({
  //     url: '/favorite/countByGroupIds',
  //     method: 'POST',
  //     data: groupIds
  //   })
  // },
  unFavorite(bizId, groupId) {
    return request({
      url: "/favorite/unFavorite/" + bizId + "/" + groupId,
      method: "POST",
    });
  },
  batchFavoriteBatch(data) {
    return request({
      url: "/favorite/batchFavorite",
      method: "POST",
      data
    });
  },
};