/* *
* 列表搜索按钮
* @module /src/components/BaseButton.vue
* @desc 列表搜索按钮
* @author wenghaocan@3ncto.com
* @date 2020年06月17日
* @param {String} [icon] - 按钮图标
* @param {String} [type] - 按钮类型, 默认："default"
* @param {Boolean} [loading] - 按钮加载状态, 默认：false
* @param {Boolean} [disabled] - 按钮禁用状态, 默认：false
* @param {String} [title] - 按钮名称
* @param {String} [size] - 按钮大小
* @example 调用示例
*  <base-button :icon="icon" :size="size" :type="type" :title="title" :loading="loading" :disabled="disabled" @onClickBtn="onClickBtn"></base-button>
* */
<template>
  <a-button
    :icon="icon"
    :size="size"
    :type="type"
    :loading="loading"
    :shape="shape"
    :disabled="disabled"
    @click="onClickBtn">
    {{ title }}
  </a-button>
</template>
<script>
export default {
    name: 'BaseButton',
    data() {
        return {}
    },
    props: {
        icon: String,
        type: String,
        loading: Boolean,
        disabled: Boolean,
        title: String,
        size: String,
        shape: String
    },
    methods: {
        // 点击
        onClickBtn() {
            this.$emit('onClickBtn')
        }
    },
    mounted() {}
}
</script>