import request from "@/utils/request";

export default {
  getPdmsRecordList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pdms/record/list`,
      method: "get",
      params: params
    });
  },
  getPdmsSuggestionsAll() {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pdms/suggestions/all`,
      method: "get",
    });
  },
  postPdmSrecord(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pdms/record`,
      method: "post",
      data
    });
  },
  putPdmStatus(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pdms/record/status/change`,
      method: "put",
      data
    });
  },
  delPdm(problemBatchNo) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pdms/record/${ problemBatchNo }`,
      method: "DELETE"
    });
  },
  getRecordAuth(dealerId, params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pdms/record/auth/${ dealerId }`,
      method: "get",
      params: params
    });
  },
  putPdmsSolve(data) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pdms/record/solve`,
      method: "put",
      data
    });
  },
  getProblemBatchNo(problemBatchNo) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pdms/record/solve/display/${ problemBatchNo }`,
      method: "get",
    });
  },
  getProblemDetail(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pdms/record/detail`,
      method: "get",
      params: params
    });
  },
  getList(params) {
    return request({
      baseURL: "/operation-efficiency-up",
      url: `/pdms/record/problem/statistics`,
      method: "get",
      params: params
    });
  },
};
