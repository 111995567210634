import request from '@/utils/request'

export default {

  query(data) {
    return request({
      url: '/favoriteDynamicGroup/query',
      method: 'POST',
      data
    })
  },
  save(id) {
    return request({
      url: '/favoriteDynamicGroup/save/' + id,
      method: 'POST'
    })
  },
  delete(id) {
    return request({
      url: '/favoriteDynamicGroup/delete/' + id,
      method: 'POST'
    })
  },
}
