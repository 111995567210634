import axios from 'axios'
import router from "@/router";
import {auth,bus } from '@/utils'

let currentLoadingCount = 0;
function incrLoadRequest(config={},incr){
  // console.log('incrLoadRequest', config, incr)
  if(enableShowLoading(config)){
    currentLoadingCount = currentLoadingCount+incr;
  }
  if(incr>0){
    bus.$emit('request:start',config);
  } else {
    bus.$emit('request:end',config)
  }

  if(currentLoadingCount<1){
    currentLoadingCount = 0;
    bus.$emit('request:allCompleted',config);
  }
}

// create an axios instance
const service = axios.create({
  timeout: 60*1000*10// request timeout
})

service.interceptors.request.use(config => {
  let token = auth.getToken()

  if (token) {
    // config.headers['Access-Token'] = token
    config.headers['Authorization'] = 'Bearer ' + token
  }
  config.headers['Access-TenantId'] = window.localStorage.getItem('tenantId');
  config.headers['Access-VisitorId'] = window.localStorage.getItem('visitorId');

  if (!config.headers['Content-Type'] && config.params) {
    // config.data = '';
    config.headers['Content-Type'] = 'application/json';
  }
  if (typeof config.cancelToken == 'function') {
    config.cancelToken = new axios.CancelToken(cancel => config.cancelToken(cancel))
  }

  resolveGet(config);

  incrLoadRequest(config,1);

  return config
}, error => {
  incrLoadRequest({},-1);
  Promise.reject(error)
})
//ie get cache
function resolveGet(config){
  if('get'!=config.method){
    return ;
  }
  let url = config.url;
  if(url.indexOf('?')<0){
    url+='?_random='+new Date().getTime();
  } else {
    url+='&_random='+new Date().getTime();
  }
  config.url=url;
}
async function responseErrorHandler(error) {

  const { config, response } = error
  incrLoadRequest(config,-1);
  let msg = ''
  let code = -1
  let statusCode;
  if (response && response instanceof Object) {
    // const {msg: errMsg, code: errCode} = (response.data || {msg: '', code: ''}).data
    let result = {};
    if(response.data instanceof Blob){ //文件下载
      try {
        let tmp = await response.data.text();
        result = JSON.parse(tmp);
      } catch (err){

      }
    } else {
      result = response.data;
    }
    const { msg: errMsg, code: errCode } = result
    statusCode = response.status
    code = errCode
    msg = errMsg

  } else if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    statusCode = 408;
    msg = '';
  } else {
    statusCode = 600
    msg = error.msg || 'Network Error'
  }

  let wrapError = new RequestError(statusCode, code, msg)
  wrapError.data = response.data
  let isShowDefaultError = true;
  let disableDefaultHandler = ()=>{
    isShowDefaultError = false;
  }
  setTimeout(() => {
    if (isShowDefaultError) {
      defaultHandler(wrapError);
    }
  });
  return Promise.reject({error:wrapError,disableDefaultHandler});
}
// respone interceptor
service.interceptors.response.use(
  response => {
    let {config} = response;
    incrLoadRequest(config,-1);
    let fileContentTypes = 'application/octet-stream|application/x-msdownload|application/msexcel|application/pdf';
    if (response.headers && response.headers['content-type']) {
      let contentType = response.headers['content-type'].split(';')[0];
      if(fileContentTypes.indexOf(contentType)>-1){
        downloadUrl(response)
      }
      return Promise.resolve(response.data);
    }
    return Promise.resolve(response.data)
  },responseErrorHandler);

function defaultHandler(reqError) {
  console.log(reqError);
  let msg = '';
  switch (reqError.httpCode) {
    case 400: msg = '请求错误(400)'; break;
    case 401: msg = '未授权，请重新登录(401)'; break;
    case 403: msg = '拒绝访问(403)'; break;
    case 404: msg = '请求出错(404)'; break;
    case 408: msg = '请求超时(408)'; break;
    case 500: msg = '服务器错误(500)'; break;
    case 501: msg = '服务未实现(501)'; break;
    case 502: msg = '网络错误(502)'; break;
    case 503: msg = '服务不可用(503)'; break;
    case 504: msg = '网络超时(504)'; break;
    case 505: msg = 'HTTP版本不受支持(505)'; break;
    case 418: msg = reqError.msg;break;
    default: msg = `连接出错(${reqError.httpCode})!`;
  }
  msg = reqError.msg||msg;
  if(reqError.httpCode!='401'||!auth.isTokenExpired()){
    router.app.$message.error(msg);
  }
  if(reqError.httpCode=='401'){
    bus.$emit("app:logout");
  }
}
//使用iframe框架下载文件
const downloadUrl = res => {

  const content = res.data
  const blob = new Blob([content])
  // const dis = res.headers['attache-disposition']||'';
  // let fm = /filename=[^;]*/.exec(dis);
  //let filename = fm[0].substring("filename=".length);
  let filename = decodeURIComponent(res.headers['attachment-name']||'undefined');
  if ('download' in document.createElement('a')) { // 非IE下载
    const elink = document.createElement('a')
    elink.download = filename
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href) // 释放URL 对象
    document.body.removeChild(elink)
  } else { // IE10+下载
    navigator.msSaveBlob(blob, filename)
  }
}
function RequestError(httpCode, code, msg) {
  this.httpCode = httpCode
  this.code = code
  this.msg = msg
  this.name = 'RequestError'
}

RequestError.prototype = new Error()
RequestError.prototype.constructor = RequestError;

RequestError.prototype.defaultHandler = defaultHandler;

let request = function(config={},options={}){
  Object.assign(config,options);
  let { baseURL, ...restConfig } = config

  if (baseURL) {
    baseURL = process.env.NODE_ENV === 'development' ? `/api${baseURL}` : baseURL
  } else {
    const baseAPI = process.env.VUE_APP_BASE_API

    baseURL = process.env.NODE_ENV === 'development' ? `/api${baseAPI}` : baseAPI
  }

  return service({
    baseURL,
    ...restConfig
  });
}
export const enableShowLoading=(config)=>{
  let headers = config.headers||{showLoading:null};

  let showLoading = headers.showLoading;
  // if((showLoading===undefined||showLoading ==null) && config.method=='post'){
  //   showLoading = true;
  // }
  return showLoading;
}
export default request;
