import Layout from "../pages/layout";

/**
 * 经销商管理
 */
export default {
  path: "/dealerManage",
  name: "dealerManage",
  component: Layout,
  meta: {title: "经销商管理", icon: "team"},
  children: [
    {
      path: "dealerList",
      name: "dealerList",
      component: () => import("@/pages/app/dealerList"),
      meta: {title: "经销商列表"}
    },
    {
      path: "dealerDetail",
      name: "dealerDetail",
      component: () => import("@/pages/app/trainingList/dealer-detail"),
      meta: {title: "经销商详情"},
      hidden: true
    },
    {
      path: "dealerGroupList",
      name: "dealerGroupList",
      component: () => import("@/pages/app/dealerGroupList"),
      meta: {title: "经销商公共分组"}
    },
    {
      path: "dealerGroupDetail/:groupId",
      name: "dealerGroupDetail",
      component: () => import("@/pages/app/dealerGroupDetail"),
      meta: {title: "经销商公共分组详情"},
      hidden: true
    },
    {
      path: "dealerTagList",
      name: "dealerTagList",
      component: () => import("@/pages/app/trainingList/dealerTagList"),
      meta: {title: "标签筛选结果"},
      hidden: true
    },
    {
      path: "problemStatistics",
      name: "problemStatistics",
      component: () => import("@/pages/app/problemStatistics/index"),
      meta: {title: "分组经销商问题统计"},
      hidden: true
    }
  ]
};
