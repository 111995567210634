<template>
  <div>
    <a-modal
      title="工单备注"
      :visible="showModal"
      :confirm-loading="remarkLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskClosable="false"
      :destroyOnClose="true"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="备注" prop="remark">
          <div class="textarea-wrapper">
            <a-input
              v-model="form.remark"
              type="textarea"
              placeholder="可对工单需求进行补充说明，确保各方信息同步"
              :auto-size="{ minRows: 4, maxRows: 5 }"
              :maxLength="200"
              @change="changeDes()"
            />
            <span class="m-count">{{ desCount }}</span>
          </div>
        </a-form-model-item>

        <a-form-model-item label="添加附件">
          <exc-upload
            @handleUrl="handleUrl"
            :limitFileLength="2"
            acceptList=""
            :limitSize="20"
          >
          </exc-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import ExcUpload from "@/components/exc-upload.vue";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    remarkLoading: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
      form: {
        remark: "",
      },
      rules: {
        remark: [
          {
            required: true,
            message: "请输入备注内容",
            trigger: "change",
          },
        ],
        nextNodeDealerMan: [
          { required: true, message: "请选择节点受理人", trigger: "change" },
        ],
      },
      excList: [],
      desCount: "0/200",
    };
  },
  components: {
    ExcUpload,
  },
  methods: {
    initFrom() {
      this.form = {
        remark: "",
      };
      this.excList = [];
      this.desCount = "0/200";
    },

    handleOk(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const { remark } = this.form;
          let arr = [];
          this.excList.map((item) => {
            arr.push({
              attachUrl: item.xhr,
              attachName: item.name,
              attachType: 1,
              attachSize: item.size,
            });
          });
          let value = {
            remark,
            attachList: arr,
          };
          this.$emit("saveRemark", value);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleUrl(val, type) {
      console.log(val, type);
      const { size, name, uid, xhr } = val;
      if (type == 3) {
        this.excList = this.excList.filter((item) => {
          return item.uid !== uid;
        });
      } else {
        let obj = {
          size,
          name,
          uid,
          xhr,
        };

        this.excList.push(obj);
      }
    },
    handleCancel(e) {
      this.$emit("closeModal", 4);
    },
    changeDes() {
      this.desCount = `${this.form.remark.length}/200`;
    },
  },
};
</script>
<style lang="scss" scoped>
.textarea-wrapper {
  position: relative;
  display: block;

  .m-textarea {
    padding: 8px 12px;
    height: 100%;
  }

  .m-count {
    color: #808080;
    background: #fff;
    position: absolute;
    font-size: 12px;
    bottom: 8px;
    right: 12px;
  }
}
</style>
