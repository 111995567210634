import Layout from "../pages/layout";

/**
 * 媒体中心
 */
export default {
  path: "/mediaCenter",
  name: "mediaCenter",
  component: Layout,
  meta: {title: "媒体中心", icon: "medium"},
  children: [
    {
      path: "authorList",
      name: "authorList",
      component: () => import("@/pages/app/authorList"),
      meta: { title: "播主列表" }
    },
    {
      path: "authorAnalysis/:authorId",
      name: "authorAnalysis",
      hidden: true,
      component: () => import("@/pages/app/authorAnalysis/Manage"),
      meta: { title: "播主分析", noPadding: true },
      props: true
    },
    {
      path: "authorAuth/:authorId",
      name: "authorAuth",
      component: () => import("@/pages/app/authorAuthorization/AuthorizationTabs"),
      meta: {title: "播主授权"},
      hidden: true,
      props: true
    },
    {
      path: "authorAuthKS/:authorId",
      name: "authorAuthKS",
      component: () => import("@/pages/app/authorAuthorizationKS/AuthorizationTabs"),
      meta: {title: "播主授权"},
      hidden: true,
      props: true
    },
    {
      path: "authorGroupList",
      name: "authorGroupList",
      component: () => import("@/pages/app/authorGroupList"),
      meta: { title: "播主公共分组" }  
    },
    {
      path: "authorGroupDetail/:groupId",
      name: "authorGroupDetail",
      component: () => import("@/pages/app/authorGroupDetail"),
      meta: { title: "播主公共分组详情" },
      hidden: true
    },
    {
      path: "authManage",
      name: "auth:manage",
      component: () => import("@/pages/app/authorizationManage/Manage.vue"),
      meta: { title: "授权管理" }
    },
    {
      path: "awemeList",
      name: "awemeList",
      component: () => import("@/pages/app/awemeList"),
      meta: {title: "短视频列表"}
    },
    {
      path: "awemeAnalysis/:awemeId",
      name: "awemeAnalysis",
      component: () => import("@/pages/app/awemeAnalysis"),
      meta: {title: "视频分析", noPadding: true},
      hidden: true,
      props: true
    },
    {
      path: "awemeAnalysisKS/:awemeId",
      name: "awemeAnalysisKS",
      component: () => import("@/pages/app/awemeAnalysisKS"),
      meta: {title: "视频分析", noPadding: true},
      hidden: true
    },
    {
      path: "liveAnalysis/:roomId",
      name: "liveAnalysis",
      hidden: true,
      component: () => import("@/pages/app/liveAnalysis/Manage"),
      meta: {title: "直播分析", noPadding: true},
      props: true
    },
    {
      path: "liveReviewAssistant/:roomId",
      name: "liveReviewAssistant",
      hidden: true,
      component: () => import("@/pages/app/liveReviewAssistant/index"),
      meta: {title: "直播复盘助手", noPadding: true},
      props: true
    }
  ]
}
