<!--封装分页表格-->
<template>
  <a-table
      :columns="columns"
      :data-source="data"
      :rowKey="rowKey"
      :pagination="pagable?pagination:pagable"
      :loading="loading||dataLoading"
      @change="onTableChange"
      :size="size"
      :scroll="scroll"
      :pagable="pagable"
      :row-selection="(enableSelection||multi)?rowSelection:null"
      :customRow="rowHandler"
      ref="table"
      :class="(multi||showSelectionRadio)?'':'vtable-selection-hidden'"
  >
    <div slot="customTitle">
      视频 （发布时间排序）
    </div>
    <!-- 自定义表格渲染项 -->
    <template
        v-for="(colCustom) in columnsCustom"
        #[colCustom.customRender]="text, record,index"
    >
      <slot
          :name="colCustom.customRender"
          :text="text"
          :row="record"
          :$index="index"
          :$rowNum="(pagination.current - 1) * pagination.pageSize + index"
      />
    </template>
  </a-table>
</template>
<style lang="less">
.vtable-selection-hidden {
  colgroup > col.ant-table-selection-col {
    display: none !important;
  }

  .ant-table-thead > tr > th.ant-table-selection-column,
  .ant-table-tbody > tr > td.ant-table-selection-column {
    display: none;
  }

  .ant-table-body {
    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
    }

    ul::-webkit-scrollbar { // 整个滚动条.
      width: 6px;
    }

    &::-webkit-scrollbar-thumb { // 滚动条上的滚动滑块
      border-radius: 8px;
      background-color: #7f7f7f;
    }

    &::-webkit-scrollbar-corner { // 当同时有垂直滚动条和水平滚动条时交汇的部分
      background_color: #242832;
    }

    &::-webkit-scrollbar-track { // 滚动条轨道
      border-radius: 0;
      background_color: #242832;
    }

  }
}
</style>
<script>

import { Tool } from "../utils";
import _ from "lodash";
import { cloneDeep, pull, uniq } from "lodash-es";

const defaultPagination = {
  pageSizeOptions: ["5", "10", "20", "50", "100"],
  pageSize: 20,
  current: 1,
  showSizeChanger: true,
  total: 0,
  showTotal: (total, range) => `${ range[0] }-${ range[1] } 共 ${ total } 条记录`
};

export default {
  name: "vTable",
  props: {
    columns: {
      type: Array,
      required: false,
      default: () => []
    },
    data: {
      type: Array,
      required: false,
      default: () => []
    },
    rowKey: {
      type: [String, Function],
      required: false,
      default: "id"
    },
    /**
     * 默认分页配置
     */
    pagable: {
      type: Boolean,
      required: false,
      default: true
    },
    pagination: {
      type: Object,
      required: false,
      default: () => Object.assign({}, defaultPagination)
    },
    /**
     * 参数（如:{orderBys:'id|desc',param:{code:'1'}}
     */
    query: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    sort: {
      type: Object,
      default: () => {
      }
    },
    /**
     * 远程方法：如$api.xxxService.query(qp);
     */
    remote: {
      type: Function,
      require: false,
      default: () => undefined
    },
    size: {
      type: String,
      required: false,
      default: "middle"
    },
    scroll: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    loading: {
      //是否加载中
      type: Boolean,
      required: false,
      default: false
    },
    enableSelection: {
      //开启可选（单选或多选）
      type: Boolean,
      required: false,
      default: false
    },
    multi: {
      //是否多选
      type: Boolean,
      required: false,
      default: false
    },
    showSelectionRadio: {
      //展示单选按钮(作用于单选)
      type: Boolean,
      required: false,
      default: false
    },
    autoSelect: {
      //是否自动选择（第一行）
      type: Boolean,
      required: false,
      default: false
    },
    autoClearSelected: {
      //是否自动清除所选(data size=0时)
      type: Boolean,
      required: false,
      default: true
    },
    sortSort: {
      type: String,
      default: ""
    },
    checked: Array, // 选中数据
    isSlippageCheck: Boolean // 是否隔页选中
  },
  data() {
    return {
      dataLoading: false,
      selectedRowKeys: [],
      defaultQuery: {}
    };
  },
  computed: {
    columnsCustom() {
      return this.columns
          .filter(item => {
            return item.scopedSlots;
          })
          .map(item => item.scopedSlots);
    },
    rowSelection() {
      const {selectedRowKeys} = this;
      const _this = this;
      // let showRadio = this.multi || this.showSelectionRadio;
      return {
        selectedRowKeys,
        type: this.multi ? "checkbox" : "radio",
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys; //必须赋值
          _this.$emit("rowselected", selectedRowKeys, selectedRows);
          // _this.$emit("update:checked", _this.selectItem(_this.checked || [], selectedRowKeys));
        },
        onSelect: (record, selected) => {
          (record[_this.rowKey] || record[_this.rowKey] === 0) && _this.handleChangeSlippage([record[_this.rowKey]], selected);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          const _change = changeRows?.map(v => v[_this.rowKey]);
          _this.handleChangeSlippage(_change, selected);
        }
      };
    }
  },
  watch: {
    data(newV) {
      let v1 = newV || [];
      if(this.autoClearSelected && v1.length < 1) {
        this.clearSelected();
      }
      let rowKeys = this.selectedRowKeys || [];
      if(this.autoSelect && v1.length > 0 && rowKeys.length == 0) {
        this.onRowClick(v1[0]);
      }
    },
    checked: {
      handler() {
        this.selectedRowKeys = cloneDeep(this.checked);
      },
      deep: true
    }
  },
  methods: {
    //pagination, filters, sorter, { currentDataSource }
    onTableChange(pagination, filters, sorter, opt) {
      // console.log(sorter.order);
      if(!this.remote) {
        this.$emit("tableChange", pagination, filters, sorter, opt);
      } else {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
        if(this.sortSort == "sort_sort") {
          if(sorter.order) {
            this.defaultQuery.sort_field =
                sorter.columnKey == "play_volume" ? 1 :
                    sorter.columnKey == "digg_count" ? 2 :
                        sorter.columnKey == "comment_count" ? 3 :
                            sorter.columnKey == "share_count" ? 4 :
                                sorter.columnKey == "publish_time" ? 5 :
                                    sorter.columnKey == "play_total_count_completion_rate" ? 6 :
                                        sorter.columnKey == "play_total_count_avg_time" ? 7 :
                                            sorter.columnKey == "fans_total_add" ? 8 :
                                                sorter.columnKey == "jump_homepage_count" ? 9 : 1;
            this.defaultQuery.sort = sorter.order == "ascend" ? "ASC" : "DESC";
          } else {
            delete this.defaultQuery.sorter;
          }
        } else {
          if(sorter.order) {
            this.defaultQuery.orderBys = `${ sorter.columnKey }|${ {
              ascend: "asc",
              descend: "desc"
            }[sorter.order] || "asc" }`;
          } else {
            delete this.defaultQuery.orderBys;
          }
        }
        this.load();
      }
    },
    getData() {
      return this.data;
    },
    clean() {
      this.data.splice(0, this.data.length);
    },
    updateRow(idx, r) {
      this.$set(this.data, idx, r);
    },
    load(options = {reset: false}) {
      if(!this.isSlippageCheck) {
        this.selectedRowKeys = [];
      }
      this.data = [];
      this.dataLoading = true;
      let qp = this.query || {};
      let params = Object.assign({}, this.query, this.defaultQuery);
      if(options.reset) {
        this.currentPage = 1;
      } else {
        this.currentPage = this.pagination.current;
      }
      if(this.sortSort && this.sortSort == "sort_sort") {
        let pageSize = this.pagination.pageSize;
        let current = this.pagination.current;
        params["page_size"] = pageSize;
        params["current"] = current;
      } else {
        let pageSize = this.pagination.pageSize;
        params["limit"] = pageSize;
        let start = (this.currentPage - 1) * pageSize;
        params["start"] = start < 0 ? 0 : start;
      }
      this.remote(params)
          .then(page => {
            this.dataLoading = false;
            this.pagination.total = page.total >= 9900 ? 9900 : page.total;
            this.pagination.current = this.currentPage;
            // this.$set(this.pagination,this.pagination);
            this.$emit("sendTime", page.data[0].ctime);
            let liveTableList = (page.data || []).map(v => _.merge(v, {__LOADING__: false}));
            let rs = liveTableList.map(item => {
              item.play_total_count_completion_rate = item.play_total_count_completion_rate ? Tool.roundToNumber(item.play_total_count_completion_rate * 100) + "%" : "-";
              item.play_total_count_avg_time = item.play_total_count_avg_time ? Tool.roundToNumber(item.play_total_count_avg_time) + "s" : "-";
              return item;
            });
            this.data.splice(0, this.data.length);
            this.data.push(...rs);
            // console.log(this.data);
            this.$emit("loaded", page);
          })
          .catch(err => {
            this.dataLoading = false;
          });
    },
    rowHandler(record) {
      //行事件
      return {
        on: {
          // 事件
          click: event => {// 点击行
            this.onRowClick(record);
          },
          dblclick: event => {
            this.$emit("rowdblclick", record, event.target);
          },
          contextmenu: event => {
          },
          mouseenter: event => {
          }, // 鼠标移入行
          mouseleave: event => {
          }
        }
      };
    },
    onRowClick(record) {
      let rowKey = _.get(record, this.rowKey);
      this.$emit("rowclick", rowKey, record);

      if(rowKey && !this.isSlippageCheck) {
        this.selectedRowKeys = [rowKey];
        this.$emit("rowselected", this.selectedRowKeys, [record]);
        this.$emit("update:checked", this.selectedRowKeys);
      }
    },
    clearSelected() {
      if(this.isSlippageCheck) return;
      this.selectedRowKeys = [];
      this.$emit("rowdeselect");
      this.$emit("update:checked", this.selectedRowKeys);
    },
    // 处理跨行多选数据改变
    handleChangeSlippage(change = [], checked = false) {
      const _checked = this.checked || [];
      let select = [];
      if(checked) {
        select = [..._checked, ...change];
      } else {
        select = pull(_checked, ...change);
      }
      this.$emit("update:checked", uniq(select));
    }
  },
  mounted() {
    if(this.pagination != null) {
      Object.entries(defaultPagination)
          .filter(([k]) => this.pagination[k] == undefined)
          .forEach(([k, v]) => this.$set(this.pagination, k, v));
    }
    if(this.isSlippageCheck) this.selectedRowKeys = cloneDeep(this.checked);
  },
  beforeDestroy() {
  },
  created() {
  }
};

</script>
