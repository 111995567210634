import request from '@/utils/request'
import exportExcel from "@/utils/exportExcel"

export default {
  // 获取区域列表
  fetchAreaList (params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/report/user/business/select/area-list`,
      method: 'GET',
      params
    })
  },

  // 获取活动分析活动列表
  fetchActivityListOper(params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/assistant/activity/list`,
      method: 'GET',
      params
    })
  },

  //
  fetchActivityKpiDay (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/assistant/activity/kpi/day`,
      method: 'GET',
      params
    })
  },

  //
  fetchActivityProductSummary (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/assistant/activity/product/summary`,
      method: 'GET',
      params
    })
  },

  // 获取作品动态列表
  fetchActivityProductList (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/assistant/activity/product/list`,
      method: 'GET',
      params
    })
  },

  // 导出作品动态列表
  exportActivityProductList (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/assistant/activity/product/list/export`,
      method: 'GET',
      params,
      responseType: 'blob'
    })
  },

  // 获取作品详情
  fetchProductDetail (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/assistant/activity/product/detail`,
      method: 'GET',
      params
    })
  },

  // 提交作品详情
  submitProuctDetail (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/assistant/activity/product/analysis/submit`,
      method: 'POST',
      data: params
    })
  },

  // 获取作品详情操作日志
  fetchProductDetailLog (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/assistant/activity/product/detail/log`,
      method: 'GET',
      params
    })
  },

  // 导出学情列表
  exportStudyList (params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/activities/study/list/export`,
      method: 'GET',
      params,
      responseType: 'blob'  
    })
  },

  // 导出模板列表
  exportTempList (params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/activities/template/list/export`,
      method: 'GET',
      params,
      responseType: 'blob'  
    })
  },

  // 获取活动分析(运营组长/客户经理)活动列表
  fetchActivityListLeader (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/leader/activity/list`,
      method: 'GET',
      params
    })
  },

  // 获取导师日报汇总(运营组长/客户经理)
  fetchAssistantSummaryLeader (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/leader/activity/assistant/summary`,
      method: 'GET',
      params
    })
  },

  // 获取作品汇总(运营组长/客户经理)
  fetchProductSummaryLeader (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/leader/activity/product/summary`,
      method: 'GET',
      params
    })
  },

  // 获取作品周列表
  fetchWeekListLeader (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/leader/activity/week/list`,
      method: 'GET',
      params  
    })
  },

  // 导出作品周列表
  exportWeekListLeader (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/leader/activity/list/week/export`,
      method: 'GET',
      params,
      responseType: 'blob'
    })
  },

  // 获取作品月列表
  fetchMonthListLeader (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/leader/activity/month/list`,
      method: 'GET',
      params  
    })
  },

  // 导出作品月列表
  exportMonthListLeader (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/leader/activity/list/month/export`,
      method: 'GET',
      params,
      responseType: 'blob'
    })
  },

  // 获取直播数据
  fetchLiveDataLeader (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/leader/activity/live/count`,
      method: 'GET',
      params
    })
  },

  // 获取短视频数据
  fetchAwemeDataLeader (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/leader/activity/detail/aweme/chart`,
      method: 'GET',
      params 
    })
  },

  // 获取周详情（运营组长）
  fetchWeekDetailLeader (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/leader/activity/week/detail/${params.id}`,
      method: 'GET'
    })
  },

  // 提交周总结（运营组长）
  submitWeekSummaryLeader (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/leader/activity/week`,
      method: 'POST',
      data: params 
    })
  },

  // 获取月详情（运营组长）
  fetchMonthDetailLeader (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/leader/activity/month/detail/${params.id}`,
      method: 'GET'
    })
  },

  // 提交月总结（运营组长）
  submitMonthSummaryLeader (params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/operation/leader/activity/month`,
      method: 'POST',
      data: params 
    })
  },

  exportAll(params) {
    return exportExcel({
      baseURL: `/bfz-dy`,
      url: `/creator/exportExcel/v1`,
      method: 'GET',
      params
    })
  },

  exportAuthor(params) {
    return exportExcel({
      baseURL: '/bfz-dy',
      url: `/training/activities/author/export`,
      method: 'GET',
      params
    })
  }
}
