import request from '@/utils/request';

export default {
  // 全部工单
  allWorkOrder(params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/fetchDataOrderEntity/list`,
      method: 'get',
      params,
    });
  },
  /* 我的工单
   * @ deal 待我处理
   * @ myself 我创建的
   * @ join 我参与的
   */
  getMyWorkOrder(payload) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/fetchDataOrderEntity/${ payload.type }`,
      method: 'get',
      params: payload.params,
    });
  },
  //    全部工单统计
  allWorkOrderTotal() {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/fetchDataOrderEntity/count`,
      method: 'get',
    });
  },
  // 查询所有数据模板
  getTemplateData(params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/excelModule/list`,
      method: 'get',
      params,
    });
  },
  // 创建工单
  createWordOrder(data) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/fetchDataOrderEntity/save`,
      method: 'post',
      data,
    });
  },
  // 取数工单-工单信息
  getWordOrderDetail(id) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/fetchDataOrderEntity/${ id }`,
      method: 'get',
    });
  },
  // 拒绝工单
  refuseWork(data) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/process/reject`,
      method: 'post',
      data,
    });
  },

  // 判断当前用户是否有权限操作
  judgeUser(params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/process/userAuthority`,
      method: 'get',
      params,
    });
  },
  // 接受工单
  acceptWork(data) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/process/receiveOrder`,
      method: 'post',
      data,
    });
  },
  // 技术评估
  skillWork(data) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/process/techProcess`,
      method: 'post',
      data,
    });
  },
  // 综合评估
  assessWork(data) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/process/comprehensiveProcess`,
      method: 'post',
      data,
    });
  },
  // 报表实施
  statementWork(data) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/process/reportExport`,
      method: 'post',
      data,
    });
  },
  // 审核交付
  auditDelivery(data) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/process/auditDelivery`,
      method: 'post',
      data,
    });
  },

  // 审核驳回
  turnDownAudit(data) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/process/auditReject`,
      method: 'post',
      data,
    });
  },

  // 取消工单
  cancelWorkOrder(data) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/process/cancel`,
      method: 'post',
      data,
    });
  },

  // 评价工单
  commentWorkOrder(data) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/process/appraise`,
      method: 'post',
      data,
    });
  },

  // 置换token
  getExchangeToken(data) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/process/auditDelivery`,
      method: 'post',
      data,
    });
  },

  // 选择节点受理人列表
  getExcutePeopleList(params) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/orderNodeReveicerEntity/choice`,
      method: 'get',
      params,
    });
  },

  // 取数工单-重新打开工单
  resetWork(orderCode) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/fetchDataOrderEntity/reopenOrder/${ orderCode }`,
      method: 'get',

    });
  },

  // 删除取数工单附件
  deletWorkFile(id) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/attach/${ id }`,
      method: 'DELETE',
    });
  },

  // 保存取数工单附件
  saveWorkFile(data) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/attach`,
      method: 'post',
      data,
    });
  },

  // 添加备注
  addWorkRemark(data) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/remark`,
      method: 'post',
      data,
    });
  },
  /*******************分割线***********************/

  // 我的工单统计
  getMyWordOrder() {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/fetchDataOrderEntity/side`,
      method: 'get',
    });
  },
  // 工单流转节点配置详情
  getWordDetail() {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/config/node`,
      method: 'get',
    });
  },
  // 获取企业微信通讯录树形数据
  getTreeData() {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/orderNodeReveicerEntity/tree`,
      method: 'get',
    });
  },

  // CAS根据企微id查询是否绑定百夫长账号
  checkUserOauthBatch(oauthUserIds) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/orderNodeReveicerEntity/validation/${ oauthUserIds }`,
      method: 'get',
    });
  },
  // 配置节点人信息
  postOrderNodeReveicerEntity(data) {
    return request({
      baseURL: '/bfz-mix',
      url: '/fetchData/orderNodeReveicerEntity',
      method: 'post',
      data,
    });
  },

  // 查询所有数据模板
  getExcelModulelist(params) {
    return request({
      baseURL: '/bfz-mix',
      url: '/fetchData/excelModule/list',
      method: 'get',
      params,
    });
  },

  // 查询所有数据模板
  deteleExcelModule(id) {
    return request({
      baseURL: '/bfz-mix',
      url: `/fetchData/excelModule/${ id }`,
      method: 'DELETE',
    });
  },

  // 查询所有数据模板
  uploadeExcelModule(data) {
    return request({
      baseURL: '/bfz-mix',
      url: '/fetchData/excelModule',
      method: 'post',
      data,
    });
  },
  // 获取数据服务阶段字典
  getServicePhaseList() {
    return request({
      baseURL: '/bfz-mix',
      url: '/fetchData/fetchDataOrderEntity/servicePhase/dict',
      method: 'get',
    });
  },
};
