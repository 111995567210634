import MathUtil from "@/utils/MathUtil";
import moment from "moment";

const mathUtil = new MathUtil();

export default {
  humanReadable(val, scale = 1, other = "0") {
    scale = typeof scale == "number" ? scale : 1;
    other = typeof other == "string" ? other : "0";
    const t = Math.pow(10, scale);
    if(val == undefined) {
      val = other;
    } else if(typeof val != "number") {
      val = "0";
    } else if(val >= 1e8) {
      val = Math.floor(val / (1e8 / t)) / t + "亿";
    } else if(val >= 1e4) {
      val = Math.floor(val / (1e4 / t)) / t + "w";
    } else if (val <= -1e4) {
      val = Math.floor(val / (1e4 / t)) / t + "w";
    } else if(val <= -1e8) {
      val = Math.floor(val / (1e8 / t)) / t + "亿";
    } else if(val != 0) {
      val = Math.floor(mathUtil.multiply(val * t)) / t;
    }
    return val;
  },
  formatSecToStr(seconds) {
    let daySec = 24 * 60 * 60;
    let hourSec = 60 * 60;
    let minuteSec = 60;
    let dd = Math.floor(seconds / daySec);
    let hh = Math.floor((seconds % daySec) / hourSec);
    let mm = Math.floor((seconds % hourSec) / minuteSec);
    let ss = seconds % minuteSec;
    if(dd > 0) {
      return dd + "天" + hh + "小时" + mm + "分钟" + ss + "秒";
    }
    if(hh > 0) {
      return hh + "小时" + mm + "分钟" + ss + "秒";
    }
    if(mm > 0) {
      return mm + "分钟" + ss + "秒";
    } else {
      return ss + "秒";
    }
  },
  format(fmt) {
    const date = new Date();
    const o = {
      "Y+": date.getFullYear(),
      "M+": date.getMonth() + 1,     // 月
      "D+": date.getDate(),          // 日
      "h+": date.getHours(),         // 时
      "m+": date.getMinutes(),       // 分
      "s+": date.getSeconds(),       // 秒
      "W": date.getDay()             // 周
    };
    for(var k in o) {
      if(new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(RegExp.$1, () => {
          if(k === "W") {                                    // 星期几
            const week = ["日", "一", "二", "三", "四", "五", "六"];
            return week[o[k]];
          } else if(k === "Y+" || RegExp.$1.length == 1) {    // 年份 or 小于10不加0
            return o[k];
          } else {
            return ("00" + o[k]).substr(("" + o[k]).length);  // 小于10补位0
          }
        });
      }
    }
    return fmt;
  },
// format('星期W')                  // 星期日
// format("YYYY-MM-DD hh:mm:ss")   // 2021-03-21 20:24:32
// format("MM/DD/YYYY hh:mm")      // 03-21-2021 20:24
// format("MM/DD/YYYY hh:mm")      // 03-21-2021 20:24
// format("YYYY年MM月DD日 hh:mm:ss 星期W") // 2021年03月21日 20:30:14 星期日


//   * 数字转整数 如 10000 转为1万
//   @param {需要转化的数} num
//  @param {需要保留的小数位数} point
  tranNumber(num, point, unit = "W") {
    let numStr = num.toString();
    // 一万以内直接返回
    if(num < 10000) {
      return numStr;
    }
    //大于8位数是亿
    else if(numStr.length > 100000000) {
      let n = parseFloat((num / 100000000).toFixed(point));
      return n + "亿";
      // let decimal = numStr.substring(numStr.length - 8, numStr.length - 8 + point);
      // return parseFloat(parseInt(num / 100000000) + '.' + decimal) + '亿';
    }
    //大于5位数是一万 (以1W分割 1W以下全部显示)
    else if(num >= 10000) {
      let n = parseFloat((num / 10000).toFixed(point));
      return n + unit;
      // let decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + point);
      // return parseFloat(Math.floor(num / 10000).toFixed(point)) + 'w';
    }
  },

  /**
   * 获取前 i 周的周一和周日日期，并以数组的方式返回。
   * 当 i=1，获取的是上周一和上周日的日期；
   * 当 i=2，获取的是上上周一和上上周日的日期
   * ...以此类推
   * @param i
   */
  getLastWeek(i) {
    let weekOfDay = parseInt(moment().format("E"));//计算今天是这周第几天
    let last_monday = moment().subtract(weekOfDay + 7 * i - 1, "days").format("YYYY-MM-DD");//周一日期
    let last_sunday = moment().subtract(weekOfDay + 7 * (i - 1), "days").format("YYYY-MM-DD");//周日日期
    return [last_monday, last_sunday];
  },

  /*
  *小数处理
  * number => 传入数字
  * point => 保留几位小数 (默认为2)
  * zeroFill => 是否去掉补零 （Boolean值，默认为false）
  */
  disposeDecimal(number, point, zeroFill) {
    let num = number
    let _num = 0
    if (num) {
      num = Number(number)
      if (typeof num === 'number' && num != NaN) {
        num = point ? num.toFixed(point) : num.toFixed(2)
        if (zeroFill) {
          return num
        } else {
          return parseFloat(num)
        }
      }
      return _num.toFixed(point)
    }
    return _num.toFixed(point)
  },
  // 秒 => 分
  toMin(time) {
    if (typeof (time) != 'number') {
      return '-'
    }
    return (time / 60).toFixed(0) + 'min'
  },
  // 秒 => 时
  toHours(time) {
    if (typeof (time) != 'number') {
      return '-'
    }
    return +(time / 60 / 60).toFixed(1) + '时'
  }
};
