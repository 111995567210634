let MathUtil = function(options={}){
  //精度
  let decimalLength = options.scale==null?2:options.scale;

  var null2Zero=function(n){
    if(!n) return 0;
    let tmp = parseFloat(n);
    if(tmp.toString()==='NAN'){
      throw new Error('NAN');
    }
    return tmp;
  }
  function countDecimals(n){
    if(!n){return 0};
    if(Math.floor(n)!=n){
      return n.toString().split('.')[1].length||0;
    }
    return 0;
  }
  function getMaxDecimalLength(args){
    if(args==null||args.length<1) return 0;
    let decimalLen = 0;
    for(let i=0;i<args.length;i++){
      let tmpD = countDecimals(null2Zero(args[i]));
      if(decimalLen<tmpD){
        decimalLen = tmpD;
      }
    }
    return decimalLen;
  }
  //加法运算
  this.add=function(){
    let decimalLen = getMaxDecimalLength(...arguments);
    let m = Math.pow(10,decimalLen);
    let total = 0;
    for(let i=0;i<arguments.length;i++){
      total+=null2Zero(arguments[i])*m;
    }
    return (total/m).toFixed(decimalLength);
  }
  //减法运算
  this.subtract=function(){
    
    let decimalLen = getMaxDecimalLength(...arguments);
    let m = Math.pow(10,decimalLen);
    let total = arguments[0]*m;
    for(let i=1;i<arguments.length;i++){
      total-=null2Zero(arguments[i])*m;
    }
    return (total/m).toFixed(decimalLength);
  }
  //乘法运算
  this.multiply=function(){
    
    let decimalLen = getMaxDecimalLength(...arguments);
    let m = Math.pow(10,decimalLen);
    let total = 1;
    for(let i=0;i<arguments.length;i++){
      total=total*(null2Zero(arguments[i])*m);
    }
    return (total/Math.pow(m,arguments.length)).toFixed(decimalLength);
  }
  //除法运算
  this.divide=function(){
    
    let decimalLen = getMaxDecimalLength(...arguments);
    let m = Math.pow(10,decimalLen);
    let total = arguments[0];
    for(let i=1;i<arguments.length;i++){
      total=(total*m)/(null2Zero(arguments[i])*m);
    }
    return total.toFixed(decimalLength);
  }
}

export default MathUtil;