import request from '@/utils/request';

/**
 * 跟拍模板列表
 */
export default {
  //获取活动列表
  getActivityList(params) {
    return request({
      url: `/activity/competition/activity/query`,
      method: 'get',
      params: params,
    });
  },
  //获取详情
  getActivityDetail(id) {
    return request({
      url: `/activity/competition/activity/${id}`,
      method: 'get',
    });
  },
  //获取导拍模板
  getGuideShootList(params) {
    return request({
      url: `/guide/guideShootPage`,
      method: 'post',
      data: params,
    });
  },
  //获取活动确认导拍模板
  getActivityGuideShootList(params) {
    return request({
      url: `/activity/competition/template/page`,
      method: 'post',
      data: params,
    });
  },
  //活动模板绑定
  bindGuideShoot(params) {
    return request({
      url: `/activity/competition/template/bind`,
      method: 'post',
      data: params,
    });
  },
  //活动模板删除
  deleteGuideShoot(params) {
    return request({
      url: `/activity/competition/template/delete`,
      method: 'post',
      data: params,
    });
  },
  //活动模板恢复
  recoverGuideShoot(params) {
    return request({
      url: `/activity/competition/template/recover`,
      method: 'post',
      data: params,
    });
  },
  // 批量上传导入模板
  uploadCompetitionTemplate(params, activityId) {
    return request({
      baseURL: '/bfz-dy',
      url: `/activity/competition/template/bind/import/${activityId}`,
      method: 'post',
      data: params,
    });
  },
  // 批量上传导入模板
  downloadCompetitionTemplate() {
    return request({
      baseURL: '/bfz-dy',
      url: `/activity/competition/template/bind/import/url`,
      method: 'get',
    });
  },
  // 获取模板预览地址
  getPreviewList(data) {
    return request({
      baseURL: '/fhl-admin',
      url: `/diyms/guideshootToJjtBfz/batch/guideshootList`,
      method: 'POST',
      data,
    });
  },
};
