import request from '@/utils/request'
let aftContent = '/afanti-content'

export default {

    /**
     * 获取OSS
     */
    getOssToken() {
        return request({
            url: '/word-group/get-sts-token',
            method: 'get'
        })
    },
    getOssTokenNew(params) {
        return request({
            baseURL: aftContent,
            url: '/v1/oss/getOssUploadConfig',
            method: 'post',
            data: params
        })
    },
    
}
