import request from '@/utils/request'

export default {

  query(data) {
    return request({
      url: '/live/online/query',
      method: 'POST',
      data
    })
  },
}
