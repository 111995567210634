import api from '@/api/proxy/LiveNow'
// import store from '..'
import common from './common'

const {
  getHotCarType,
  getHotWord,
  getAudience,
  getLiveMessage,
  // getDiggTrend,
  getCommentTrend,
  getLiveRoomTrend,
  getAudienceAdress,
  getMqttConfig,
  getLiveVideo,
  getMqttFans,

  getInfo,
  addLiveRoom
} = api

export default {
  namespaced: true,
  state: {
    infoData: null,
    mqttConfigData: null,
    addLiveRoomResult: null
  },
  mutations: {

    changeState(state, payload) {
      const {
        data,
        name
      } = payload
      state[name] = data
    },
  },

  actions: {

    // 获取mqtt配置
    getMqttConfig({ commit }, payload) {
      let {
        platformType,
        authorId,
        deviceId
      } = payload;
      return getMqttConfig({
        platformType,
        authorId,
        deviceId
      })
        .then((res) => {
          commit('changeState', { name: 'mqttConfigData', data: res.data })
        })
    },
    // 获取基本信息
    getInfo({ commit }, payload) {
      let {
        room_id
      } = payload;
      return getInfo({
        room_id
      })
        .then((res) => {
          commit('changeState', { name: 'infoData', data: res.data })
        })
    },
    // 添加直播室id
    addLiveRoom({ commit }, payload) {
      let {
        roomId
      } = payload;
      return addLiveRoom({
        roomId
      })
        .then((res) => {
          commit('changeState', { name: 'addLiveRoomResult', data: res.data })
        })
    },

  }
}
