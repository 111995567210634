import Layout from "../pages/layout";

/**
 * 个人中心
 */
export default {
  path: "/personalCenter",
  name: "personalCenter",
  component: Layout,
  meta: {title: "个人中心", icon: "profile"},
  children: [
    {
      path: "/myDealerList",
      name: "myDealerList",
      component: () => import("@/pages/app/myDealerList"),
      meta: { title: "我的经销商" }
    },
    {
      path: "/myAuthorList",
      name: "myAuthorList",
      component: () => import("@/pages/app/myAuthorList"),
      meta: { title: "我的播主" }
    },
    {
      path: "/myCollectAwemeList",
      name: "myCollectAwemeList",
      component: () => import("@/pages/app/myCollectAwemeList"),
      meta: { title: "我收藏的短视频" }
    },
    {
      path: "dealerTagList",
      name: "dealerTagList",
      component: () => import("@/pages/app/myDealerList/dealerTagList"),
      meta: { title: "标签筛选结果" },
      hidden: true
    }
  ]
}
