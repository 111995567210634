import request from "@/utils/request";
import axios from "axios";


const baseURL = "/content-center";
const urls = axios.create({
  baseURL: baseURL, // api的base_url
  timeout: 60000// request timeout
});
export default {
  getTemplateList (params) {
    return request({
      url: '/training/activities/dealer/sort/list',
      method: 'get',
      params
    });
  },
  updateTemplateList (params) {
    return request({
      url: '/training/activities/dealer/sort/update',
      method: 'post',
      data: params
    });
  }
};
