import request from '@/utils/request';

const module = '/hjk';
export default {
  // 获取视频列表
  getVideoList(params) {
    return request({
      baseURL: module,
      url: `/cac/queryPphtController/list`,
      method: 'GET',
      params,
    });
  },
  // 获取品牌列表
  getPrincipalList(params) {
    return request({
      baseURL: module,
      url: `/cac/queryPphtController/principal/option`,
      method: 'GET',
      params,
    });
  },
  // 获取抖音发布状态列表
  getDyPublishStatusList() {
    return request({
      baseURL: module,
      url: `/cac/queryPphtController/public/status`,
      method: 'GET',
    });
  },
  // 获取视频类型列表
  getVideoTypeList() {
    return request({
      baseURL: module,
      url: `/cac/queryPphtController/video/type`,
      method: 'GET',
    });
  },
  // 获取临时授权
  getOssAuth() {
    return request({
      baseURL: module,
      url: `/cac/queryPphtController/clip/get-sts-token?path_name='path_name'`,
      method: 'POST',
    });
  },
  // 下载视频埋点
  report(data) {
    return request({
      baseURL: module,
      url: `/cac/queryPphtController/report`,
      method: 'POST',
      data,
    });
  },
  // 发布抖音弹窗详情数据
  getPublishDetail(params) {
    return request({
      baseURL: module,
      url: `/cac/queryPphtController/publish/detail`,
      method: 'GET',
      params,
    });
  },
  // 发布抖音弹窗下拉数据
  getPublishAuthorList(params) {
    return request({
      baseURL: module,
      url: `/cac/queryPphtController/publish/author`,
      method: 'GET',
      params,
    });
  },
  //刷新发布状态
  refreshStatus(params) {
    return request({
      baseURL: module,
      url: `/cac/publicPphtController/video/status`,
      method: 'GET',
      params,
    });
  },
  //发布抖音视频
  refreshVideo(data) {
    return request({
      baseURL: module,
      url: `/cac/queryPphtController/publish/video`,
      method: 'POST',
      data,
    });
  },
  // 获取抖音发布记录列表
  getDyPublishControllerList(params) {
    return request({
      baseURL: module,
      url: `/cac/publicPphtController/list`,
      method: 'GET',
      params,
    });
  },
  //刷新发布记录列表的视频状态
  refreshVideoStatus(params) {
    return request({
      baseURL: module,
      url: `/cac/publicPphtController/video/status`,
      method: 'GET',
      params,
    });
  },
  //刷新发布记录列表的视频状态
  cancelPublishControllerTask(params) {
    return request({
      baseURL: module,
      url: `/cac/publicPphtController/cancel/task`,
      method: 'POST',
      params,
    });
  },
  //审核回退
  rollback(data) {
    return request({
      baseURL: module,
      url: `/cac/publicPphtController/aweme/audit/rollback`,
      method: 'POST',
      data,
    });
  },
};
