<template>
  <span>
    <template v-for="(item, i) in texts">{{item}}<em :key="i" v-if="i < texts.length - 1" class="v-text-keywords">{{keyword_trim}}</em></template>
  </span>
</template>

<script>
  export default {
    name: "vText",
    props: {
      value: {
        type: String,
        default: ''
      },
      keyword: {
        type: String
      }
    },
    computed: {
      keyword_trim() {
        return this.keyword.trim()
      },
      texts() {
        return this.keyword_trim ? (this.value || '').split(this.keyword_trim) : [this.value]
      }
    },
    data: () => ({
    }),
    methods: {
    },
    mounted() {
    }
  }
</script>

<style scoped>
.v-text-keywords {
  color: red;
  font-style: normal;
}
</style>