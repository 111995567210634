import Vue from 'vue'
import SvgIcon from './SvgIcon'// svg component

// register globally
Vue.component('svg-icon', SvgIcon)

// const req = require.context('@/svg', false, /\.svg$/)
// const requireAll = requireContext => requireContext.keys().map(requireContext)
// const res = requireAll(req)
// export default res
