<template>
  <a-spin :spinning="loading">
    <a-row type="flex" :justify="justify" align="middle">
      <template v-for="(item, i) in columns">
        <a-col :flex="flex" :key="item.dataIndex" @click="item.click && item.click()">
         <a-statistic :reverse="reverse" :title="item.title" :value="defaultFormatter(data[item.dataIndex])"
                      :formatter="item.formatter" :prefix="item.prefix" :suffix="item.suffix" value-style="font-weight: bold;"/>
        </a-col>
        <a-divider :key="i" v-if="divider && (i < columns.length - 1)" type="vertical"></a-divider>
      </template>
    </a-row>
  </a-spin>
</template>
<style scoped>
.ant-statistic {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.ant-statistic[reverse] {
  flex-flow: column-reverse;
}
.ant-col {
  margin: 0 10px;
  cursor: pointer;
}
.ant-divider {
  height: 45px;
}
</style>
<script>
  export default {
    name: "v-statistics",
    components: {},
    props: {
      value: {
        required: false,
        default: () => []
      },
      columns: {
        type: Array,
        required: true,
        default: () => []
      },
      data: {
        type: Object,
        required: true,
        default: () => {}
      },
      flex: {
        type: String,
        default: '0 1 auto'
      },
      justify: {
        type: String,
        default: 'start'
      },
      reverse: {
        type: Boolean,
        default: true
      },
      divider: {
        type: Boolean,
        default: false
      },
      emptyValue: {
        type: String,
        default: '-'
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    watch: {
    },
    computed: {
    },
    data: () => ({
    }),
    methods: {
      defaultFormatter(val) {
        return val == undefined ? this.emptyValue : val
      }
    },
    mounted() {

    }
  }
</script>
