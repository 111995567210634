/*
   * 参数说明：
   * number：要格式化的数字
   * decimals：保留几位小数
   * dec_point：小数点符号
   * thousands_sep：千分位符号
   * roundtag:舍入参数，默认 "ceil" 向上取,"floor"向下取,"round" 四舍五入
   * */
  function formatNumber(number, decimals, dec_point, thousands_sep, roundtag) {
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    roundtag = roundtag || "ceil"; //"ceil","floor","round"
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
      dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
      s = '',
      toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec);
        return '' + parseFloat(Math[roundtag](parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2)) / k;
      };
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    var re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
      s[0] = s[0].replace(re, "$1" + sep + "$2");
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
  }
 
  /* 
  * 判断是否为数组
  */
  function judgeObject(arg){
    if (!Array.isArray) {
        return Object.prototype.toString.call(arg)=='[object Array]';
      }
  }

  /* 
  *小数处理
  * number => 传入数字
  * point => 保留几位小数 (默认为2)
  * zeroFill => 是否去掉补零 （Boolean值，默认为false）
  */
  function disposeDecimal(number,point,zeroFill){
    let num = number
     if(num){
        num = Number(number);
        if(typeof num === 'number' && num != NaN){
            num = point ? num.toFixed(point) : num.toFixed(2);
            if(zeroFill){
                return num
            }else{
                return parseFloat(num)
            }
        }
        return 0
     }
     return 0;
  }

  // 补零颗粒
  function disposeZero(val) {
    return disposeDecimal(val, 4, true)
  }

  /* 
  *数据处理
  *value => 传入数据
  * type => 处理类型(0:补亿或万（可不传，转为零)；1:补逗号；）
  * point => 保留几位小数 (默认为2)
  * zeroFill => 是否去掉补零 （Boolean值，默认为false）
  */
  function changeNum(value,point,zeroFill,type){
    let num = disposeDecimal(value,point,zeroFill);
    let types = type?type:0;
    switch (types) {
        case 0:
            // num = disposeDecimal(value,point,zeroFill);
            if(Math.abs(num) >= 100000000){
                num = `${disposeDecimal(num/100000000,point,zeroFill)}亿`
            }else if(Math.abs(num) >= 10000){
                num = `${disposeDecimal(num/10000,point,zeroFill)}w`
            }   
            break;
        case 1:
            num = Number.parseFloat(num).toLocaleString('en-US')
        default:
            break;
    }
    return num
  }

  // 添加逗号颗粒
  function numCode(val){
   return changeNum(val,'','',1)
  }

  // {{ 1232134 | changeNum }} 万位亿位处理，保留两位小数,不补零
  // {{ 1232134 | numCode }} 添加逗号，保留两位小数，不补零
  // {{ 1232134 | disposeDecimal }} 保留两位小数，不补零
  // {{ 1232134 | disposeZero }} 保留四位小数，补零
  // {{ this.filtrate.changeNum(439942394,2,true) }} 调用函数传参

  export default {
    formatNumber,
    judgeObject,
    disposeDecimal,
    changeNum,
    disposeZero,
    numCode,
  }
