import request from '@/utils/request';
const dayjs = require('dayjs');
// 发送埋点接口
export const postBuriedPoint = (data) => {
  return request({
    url: '/v1/point-all/add',
    method: 'post',
    baseURL: '/tracking',
    data,
  });
};

/**
 * 事件类型
 * @enum {string}
 */
export const trackEvent = {
  PAGE_VIEW: 'page_view',
};
/**
 * @param {object} route 埋点路由
 * @param {object} options 埋点参数
 * @param {trackEvent} event 事件名称
 * @param {trackEvent} options.event kv事件名称
 * @param {string} [options.action] 操作名称
 * @param {number} [options.default] 默认选中 1是, 0否
 * @param {string} [options.tabsName] 选中标签页名称
 */
export const trackRequest = async (route, event, options = {}) => {
  let title
  console.log(route,'route')
  if(route.path == '/'){
    title = null
  }else{
    title = JSON.parse(localStorage.menus).find((item)=>{
      return route.path.indexOf(item.path)>-1
    })?.title || route.meta.title
  }
  const data = {
    ap: 'bfz',
    cm: {
      muid: 3,
      rn: '',
      ub: '',
      ubid: '',
      upid: '',
      upn: '',
      uid: window.localStorage.getItem('user_id'),
      un: window.localStorage.getItem('user_name'),
      t: Date.now(),
    },
    et: {
      en: event,
      kv: {
        pageName: title,
        pagePath: route.path,
        department: window.localStorage.getItem('department'),
        enterpriseWechatUserId: window.localStorage.getItem('enterprise_wechat_userId'),
        enterpriseWechatUserName: window.localStorage.getItem('enterprise_wechat_userName'),
        pageViewTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        userId: window.localStorage.getItem('user_id'),
        userName: window.localStorage.getItem('user_name'),
        ...options,
      },
    },
  };
  console.log('埋点数据:', data);
  postBuriedPoint(data);
};
