import requests from '@/api/BaseAliOss'
import _ from 'lodash'

let OSS = require('ali-oss')

export default function ossProtype(bucket) {
  return requests.getOssToken().then(res => {
    if (res.access_key_id) {
      const accessKeyId = _.get(res, 'access_key_id')
      const accessKeySecret = _.get(res, 'access_key_secret')
      const stsToken = _.get(res, 'security_token')
      let ossObj = new OSS({
        accessKeyId,
        accessKeySecret,
        stsToken,
        region: 'oss-cn-hangzhou',
        bucket: bucket
      })
      return ossObj
    } else {
      console.error(res)
      return
    }
  })

}