import RouteView from '../pages/layout/RouteView';

/**
 * 审核管理
 */
export default {
  path: '/materialManage',
  name: 'materialManage',
  component: RouteView,
  meta: { title: '素材管理', icon: 'profile' },
  redirect: '/castVideoManage',
  children: [
    // 投放视频管理
    // 投放视频管理列表
    {
      path: '/castVideoManage',
      name: 'castVideoManage:index',
      component: () => import('@/pages/app/materialManage/castVideoManage/index'),
      meta: { title: '投放视频管理' },
    },
    // 上传视频
    {
      path: '/castVideoManage/uploadVideo',
      name: 'castVideoManage:uploadVideo',
      component: () => import('@/pages/app/materialManage/castVideoManage/uploadVideo'),
      meta: { title: '上传视频' },
      hidden: true,
    },
    // 赠送视频
    {
      path: '/castVideoManage/giftVideo',
      name: 'castVideoManage:giftVideo',
      component: () => import('@/pages/app/materialManage/castVideoManage/giftVideo'),
      meta: { title: '赠送视频' },
      hidden: true,
    },
    // 更多视频信息
    {
      path: '/castVideoManage/detail',
      name: 'castVideoManage:detail',
      component: () => import('@/pages/app/materialManage/castVideoManage/detail'),
      meta: { title: '更多视频信息' },
      hidden: true,
    },

    // 抖音号视频管理
    {
      path: '/dyVideoManage',
      name: 'dyVideoManage',
      component: () => import('@/pages/app/materialManage/dyVideoManage/index'),
      meta: { title: '抖音号视频管理' },
    },

    // 自动合成视频库
    {
      path: '/autoVideoLib',
      name: 'autoVideoLib',
      component: () => import('@/pages/app/materialManage/autoVideoLib/index'),
      meta: { title: '自动合成视频库' },
    },
    // 自动合成视频经销商
    {
      path: '/autoVideoDealer',
      name: 'autoVideoDealer',
      component: () => import('@/pages/app/materialManage/autoVideoDealer/index'),
      meta: { title: '自动合成视频经销商' },
    },
    // 自动合成视频发布记录
    {
      path: '/autoVideoLibReleaseList',
      name: 'autoVideoLibReleaseList',
      component: () => import('@/pages/app/materialManage/autoVideoLibReleaseList/index'),
      meta: { title: '自动合成视频发布记录' },
    },
    // 下载任务
    {
      path: '/downloadTask',
      name: 'downloadTask',
      component: () => import('@/pages/app/materialManage/autoVideoLib/downloadTask'),
      meta: { title: '下载任务' },
    },

    // 橙子建站模板管理
    // 橙子建站模板管理列表
    {
      path: '/websiteTemplateManage',
      name: 'websiteTemplateManage:index',
      component: () => import('@/pages/app/materialManage/websiteTemplateManage/index'),
      meta: { title: '橙子建站模板管理' },
    },
    // 详情
    {
      path: '/websiteTemplateManage/detail',
      name: 'websiteTemplateManage:detail',
      component: () => import('@/pages/app/materialManage/websiteTemplateManage/detail'),
      meta: { title: '橙子建站模板详情' },
      hidden: true,
    },

    // 橙子建站落地页管理
    // 橙子建站落地页管理列表
    {
      path: '/landingPageManage',
      name: 'landingPageManage:index',
      component: () => import('@/pages/app/materialManage/landingPageManage/index'),
      meta: { title: '橙子建站落地页管理' },
    },

    // 橙子建站程序化落地页管理
    // 橙子建站程序化落地页管理列表
    {
      path: '/programmaticLandingPage',
      name: 'programmaticLandingPage:index',
      component: () => import('@/pages/app/materialManage/programmaticLandingPage/index'),
      meta: { title: '橙子建站程序化落地页管理' },
    },
    // 绑定
    {
      path: '/programmaticLandingPage/bindList',
      name: 'programmaticLandingPage:bindList',
      component: () => import('@/pages/app/materialManage/programmaticLandingPage/bindList'),
      meta: { title: '绑定' },
      hidden: true,
    },
    // 详情
    {
      path: '/programmaticLandingPage/detail',
      name: 'programmaticLandingPage:detail',
      component: () => import('@/pages/app/materialManage/programmaticLandingPage/detail'),
      meta: { title: '橙子建站程序化落地页详情' },
      hidden: true,
    },

    // 青鸟线索通表单模板管理
    // 青鸟线索通表单模板管理列表
    {
      path: '/formManage',
      name: 'formManage:index',
      component: () => import('@/pages/app/materialManage/formManage/index'),
      meta: { title: '青鸟线索通表单模板管理' },
    },
    // 详情
    {
      path: '/formManage/detail',
      name: 'formManage:detail',
      component: () => import('@/pages/app/materialManage/formManage/detail'),
      meta: { title: '青鸟线索通表单模板详情' },
      hidden: true,
    },

    // 巨量创意组件模板管理
    // 巨量创意组件模板管理列表
    {
      path: '/creativeTemplateManage',
      name: 'creativeTemplateManage:index',
      component: () => import('@/pages/app/materialManage/creativeTemplateManage/index'),
      meta: { title: '巨量创意组件模板管理' },
    },
    //详情
    {
      path: '/creativeTemplateManage/detail',
      name: 'creativeTemplateManage:detail',
      component: () => import('@/pages/app/materialManage/creativeTemplateManage/detail'),
      meta: { title: '巨量创意组件模板管理详情' },
      hidden: true,
    },

    // 创意标题模板管理
    // 创意标题模板管理列表
    {
      path: '/creativeTitleManage',
      name: 'creativeTitleManage:index',
      component: () => import('@/pages/app/materialManage/creativeTitleManage/index'),
      meta: { title: '创意标题模板管理' },
    },
    //详情
    {
      path: '/creativeTitleManage/detail',
      name: 'creativeTitleManage:detail',
      component: () => import('@/pages/app/materialManage/creativeTitleManage/detail'),
      meta: { title: '创意标题模板详情' },
      hidden: true,
    },

    // 巨量车系映射表
    // 巨量车系映射表列表
    {
      path: '/seriesMapping',
      name: 'seriesMapping:index',
      component: () => import('@/pages/app/materialManage/seriesMapping/index'),
      meta: { title: '巨量车系映射表' },
    },

    // 广告主素材统计
    // 广告主素材统计列表
    {
      path: '/advertiserMaterialStatistics',
      name: 'advertiserMaterialStatistics:index',
      component: () => import('@/pages/app/materialManage/advertiserMaterialStatistics/index'),
      meta: { title: '广告主素材统计' },
    },

    // 模板覆盖情况
    // 模板覆盖情况列表
    {
      path: '/templateCover',
      name: 'templateCover:index',
      component: () => import('@/pages/app/materialManage/templateCover/index'),
      meta: { title: '模板覆盖情况' },
    },

    //巨量产品库详情
    {
      path: '/JLProductLibraryDetail',
      name: 'JLProductLibraryDetail:index',
      component: () => import('@/pages/app/materialManage/JLProductLibraryDetail/index'),
      meta: { title: '巨量产品库详情' },
    },

    //创意标签模板管理
    //创意标签模板管理列表
    {
      path: '/creativeTagManage',
      name: 'creativeTagManage:index',
      component: () => import('@/pages/app/materialManage/creativeTagManage/index'),
      meta: { title: '创意标签模板管理' },
    },
    //详情
    {
      path: '/creativeTagManage/detail',
      name: 'creativeTagManage:detail',
      component: () => import('@/pages/app/materialManage/creativeTagManage/detail'),
      meta: { title: '创意标签模板详情' },
      hidden: true,
    },

    //人群包数据源
    //人群包数据源列表
    {
      path: '/crowdPackDataSource',
      name: 'crowdPackDataSource:index',
      component: () => import('@/pages/app/materialManage/crowdPackDataSource/index'),
      meta: { title: '人群包数据源' },
    },
    //人群包数据源详情
    {
      path: '/crowdPackDataSource/detail',
      name: 'crowdPackDataSource:detail',
      component: () => import('@/pages/app/materialManage/crowdPackDataSource/detail'),
      meta: { title: '人群包数据源详情' },
      hidden: true,
    },
  ],
};
