import request from '@/utils/request';

let module = '/hjk';

export default {
  //巨量广告管理
  //获取操作日志
  getActionLogs(data) {
    return request({
      baseURL: module,
      url: `/tool/logSearch`,
      method: 'post',
      data,
    });
  },


  //广告计划管理
  getAdPlanList(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdPlan/page`,
      method: 'post',
      data,
    });
  },
  //获取详情
  getAdPlanDetail(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdPlan/${ id }`,
      method: 'get',
    });
  },
  // 获取广告计划状态列表
  getAdPlanStatusList() {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdPlan/adPlanStatusList`,
      method: 'get',
    });
  },
  //批量操作
  batchByAdPlanIds(params) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdPlan/${ params.type }`,
      method: 'put',
      data: params.data,
    });
  },
  //删除
  deleteAdPlan(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdPlan/batchDeleteByIds`,
      method: 'put',
      data,
    });
  },
  //复制
  copyAdPlan(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdPlan/copyAdPlanById/${ id }`,
      method: 'post',
    });
  },
  //计划托管
  trusteeAdPlan(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdPlan/trusteeAdPlan`,
      method: 'post',
      data,
    });
  },


  //广告组管理
  //广告组管理列表
  getAdGroupList(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdGroup/page`,
      method: 'post',
      data,
    });
  },
  //获取详情
  getAdGroupDetail(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdGroup/${ id }`,
      method: 'get',
    });
  },
  //删除广告组
  deleteAdGroup(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdGroup/${ id }`,
      method: 'delete',
    });
  },
  //批量有效、停用，valid=有效
  batchByAdGroupIds(params) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdGroup/${ params.type == 'valid' ? 'batchEnableByIds' : 'batchDisableByIds' }`,
      method: 'put',
      data: params.data,
    });
  },
  //获取广告组状态列表
  getAdGroupStatus() {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdGroup/adGroupStatusList`,
      method: 'get',
    });
  },


  //广告创意管理
  //广告创意列表
  getCreativeList(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdCreative/page`,
      method: 'post',
      data,
    });
  },
  //获取创意状态列表
  getAdCreativeStatus() {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdCreative/adCreativeStatus`,
      method: 'get',
    });
  },
  //获取创意详情
  getAdCreativeDetail(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdCreative/findById/${ id }`,
      method: 'get',
    });
  },
  getAdCreativeLogs(id) {
    return request({
      baseURL: module,
      url: ``,
      method: 'get',
    });
  },

  //关键词广告管理
  //关键词列表
  getKeywordList(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdKeyword/page`,
      method: 'post',
      data,
    });
  },
  //获取关键词详情
  getKeywordDetail(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdKeyword/${ id }`,
      method: 'get',
    });
  },
  //批量操作
  batchByKeywordIds(params) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdKeyword/${ params.type }`,
      method: 'put',
      data: params.data,
    });
  },


  // 巨量项目管理
  // 巨量项目状态列表
  getJLProjectTypeList() {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdProject/adProjectStatusList',
      method: 'get',
    });
  },
  // 巨量项目管理列表
  getJLProjectManageList(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdProject/page',
      method: 'post',
      data,
    });
  },
  // 巨量项目管理列表-合计
  getJLProjectManageTotal(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdProject/projectTotal',
      method: 'post',
      data,
    });
  },
  // 查询巨量项目管理详情
  getJLProjectDetail(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdProject/${ id }`,
      method: 'get',
    });
  },
  // 查询巨量项目日志/广告管理日志
  getJLProjectLog(data) {
    return request({
      baseURL: module,
      url: '/tool/logSearch',
      method: 'post',
      data,
    });
  },

  // 广告管理
  // 广告管理状态列表
  getAdvTypeList() {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdPromotion/adPromotionStatusList',
      method: 'get',
    });
  },
  // 广告管理列表
  getAdvManageList(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdPromotion/page',
      method: 'post',
      data,
    });
  },
  // 广告列表合计
  getAdvManageTotal(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdPromotion/promotionTotal',
      method: 'post',
      data,
    });
  },
  // 查询广告管理详情
  getAdvManageDetail(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdPromotion/${ id }`,
      method: 'get',
    });
  },

  // 素材开关
  // 素材类型列表
  getMaterialTypeList() {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdMaterial/materialSwitchList',
      method: 'get',
    });
  },
  // 素材状态列表
  getMaterialStatusList() {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdMaterial/adMaterialStatusList',
      method: 'get',
    });
  },
  // 素材开关列表
  getMaterialList(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdMaterial/page',
      method: 'post',
      data,
    });
  },
  // 获取素材链接
  getMaterialUrl(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdMaterial/getPlayUrl/${ id }`,
      method: 'get',
    });
  },
  // 批量启用
  materialPatchEnable(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdMaterial/batchEnableByIds',
      method: 'put',
      data,
    });
  },
  // 批量禁用
  materialPatchDisable(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdMaterial/batchDisableByIds',
      method: 'put',
      data,
    });
  },

  // 广告主账套列表
  getHjkAccountqAccountList(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAccount/page/account',
      method: 'post',
      data,
    });
  },
  // 项目列表
  getHjkAccountProjectList(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAccount/list/project',
      method: 'post',
      data,
    });
  },
  // 广告列表
  getHjkAccountPromotionList(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAccount/list/promotion',
      method: 'post',
      data,
    });
  },
  // 广告优化看板-列表查询
  getAdAutomateRuleActionLogList(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/adAutomateRuleActionLog/optimize/page',
      method: 'post',
      data,
    });
  },
  // 优化建议枚举下拉
  getOptimizeAdviceList(params) {
    return request({
      baseURL: module,
      url: '/sysAdmin/adAutomateRuleActionLog/optimize/advice',
      method: 'get',
      params,
    });
  },
};
