// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';

import antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(antd, {});

import Icon from 'vue-svg-icon/Icon.vue';
Vue.component('icon', Icon);

import vCharts from 'v-charts';
import 'v-charts/lib/style.css';
Vue.use(vCharts);

import vueFilter from './filter';
Vue.use(vueFilter);

import Chat from 'vue-beautiful-chat';
Vue.use(Chat);

import { auth } from '@/utils';

// 巨量引擎
import MUI from '@bytedance-ad/mui-vue2';
import '@bytedance-ad/mui-vue2/dist/style.css';

Vue.use(MUI);

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import InfiniteXScroll from '@/directives/infiniteXScroll';
Vue.use(InfiniteXScroll);

import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Vue.use(Viewer);

// https://www.npmjs.com/package/vue-json-viewer
import JsonViewer from 'vue-json-viewer'
Vue.component('JsonViewer', JsonViewer);
// Viewer.setDefaults({
//   Options: {
//     inline: true,
//     button: true,
//     navbar: true,
//     title: true,
//     toolbar: true,
//     tooltip: true,
//     movable: true,
//     zoomable: true,
//     rotatable: true,
//     scalable: true,
//     transition: true,
//     fullscreen: true,
//     keyboard: true,
//     url: 'data-source',
//   },
// });

import { setConfig } from '@bytedance-ad/mui-vue2';
setConfig({
  // 可选，如果您搭建的后端接口域名和前端域名不一样，在此修改，默认为window.origin
  host: process.env.VUE_APP_HOST,
  // 可选，如果您搭建的后端接口路径和 MAPI 接口路径不一致，需在此处修改，默认为 MAPI 的路径
  apis: {
    getDistrictList: '/hjk/sysAdmin/regionInfo/admin/list', //地域
    getActionCategories: '/hjk/sysAdmin/oceanInterestActionWord/actionCategory', //行为类目
    getActionKeywords: '/hjk/sysAdmin/oceanInterestActionWord/actionKeywordQuery', //行为关键词
    getInterestCategories: '/hjk/sysAdmin/oceanInterestActionWord/interestCategoryList', //兴趣类目
    getInterestKeywords: '/hjk/sysAdmin/oceanInterestActionWord/interestKeywordList', //兴趣关键词
    getActionInterestKeywordSuggest: '/hjk/sysAdmin/oceanInterestActionWord/suggestList', //行为兴趣推荐关键词
    getInterestActionInfoByIs: '/hjk/sysAdmin/oceanInterestActionWord/id2Word', //兴趣行为类目关键词id转词

    getAwemeFanCategories: '/hjk/sysAdmin/awemeTopAuthor/awemeMultiLevelCategory', //获取抖音达人分类列表
    getAwemeAuthorInfo: '/hjk/sysAdmin/awemeTopAuthor/awemeAuthorInfo', //查询抖音号id对应的达人信息
    getAwemeSearchInfo: '/hjk/sysAdmin/awemeTopAuthor/awemeInfoSearch', //查询抖音帐号和类目信息
    getAwemeSimilarAccounts: '/hjk/sysAdmin/awemeTopAuthor/awemeSimilarAuthorSearch', //查询抖音类似帐号
    getAwemeFanAccounts: '/hjk/sysAdmin/awemeTopAuthor/awemeCategoryTopAuthor', //获取抖音达人id列表
  },
  headers: {
    // 可选，自定义公共请求头参数
    Authorization: `Bearer ${auth.getToken()}`,
  },
});

// 全局注册Base组件
import BasePagination from '@/components/BasePagination';
import BaseButton from '@/components/BaseButton';

import 'lodash';
import './components';
import './directives';
import './assets/css/base.less';
import moment from 'moment';
import axios from 'axios';
import echarts from 'echarts';
import { bus } from './utils';
import api from './api';
Vue.prototype.$axios = axios;
Vue.prototype.$echarts = echarts;
Vue.prototype.$bus = bus;
Vue.prototype.$api = api;
// import mq from './mq';
import permission from './permission';
permission.init();
// mq.init();
import filters from '@/mirror-modules/filters';
Object.entries(filters).forEach(([k, v]) => Vue.filter(k, v));
import { store } from './mirror-modules';
Vue.prototype.filtrate = filters;
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;

// sentry
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

// process.env.NODE_ENV === 'production' && Sentry.init({
//   Vue: Vue,
//   dsn: "https://369ee86a66c843daa2c875505b847553@sentry.afanticar.cn/3",
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],
//   tracingOptions: {
//     trackComponents: true,
//   },
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   release: "bfz-dy@1.0",
// });

Vue.mixin({
  components: {
    BasePagination,
    BaseButton,
  },
});

new Vue({
  router,
  store,
  watch: {
    $route(nv) {
      nv.query.dataType && (this.dataType = parseInt(nv.query.dataType));
      nv.params.dataType && (this.dataType = parseInt(nv.params.dataType));
    },
    dataType(nv) {
      // this.flushDateSource(nv)
    },
  },
  computed: {
    dataTypeLabel() {
      return this.$dict.getText(this.dataType, this.$dict.store.DATATYPE);
    },
  },
  data: {
    dataType: 1,
  },
  methods: {
    // flushDateSource(dataType) {
    //   Vue.prototype.$api.ds =  { 1: this.$api.douyin, 2: this.$api.kuaishou }[dataType]
    //   Vue.prototype.$api.pds =  { 1: this.$api.proxy.douyin, 2: this.$api.proxy.kuaishou }[dataType]
    // }
  },
  mounted() {
    // this.flushDateSource(this.dataType)
  },
  render: (h) => h(App),
}).$mount('#app');

window._hmt = window._hmt || [];
(function () {
  const nodeEnv = process.env.NODE_ENV;
  const appEnv = process.env.VUE_APP_ENV;

  if (nodeEnv === 'production' && appEnv === 'pro') {
    var hm = document.createElement('script');
    hm.src = 'https://hm.baidu.com/hm.js?b7cb6ad848590ce53762b3b954a6ab45';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(hm, s);
  }
})();
// mq.init();
