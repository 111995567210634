<template>
  <a-spin :spinning="loading">
    <a-checkable-tag v-if="!loading && multi" v-model="all" :list="list" @change="onAll">全部</a-checkable-tag>
    <a-checkable-tag v-for="(item, i) in data" :key="i" v-model="item.value" :list="list" @change="onChange(i, $event)">
      {{item.text}}
    </a-checkable-tag>
  </a-spin>
</template>
<style scoped>
  .ant-tag {
    font-size: 13px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .ant-tag:not([list]) {
    margin-bottom: 0;
    padding: 5px 10px;
  }

  .ant-tag-checkable:not(.ant-tag-checkable-checked) {
    background-color: #fff;
  }
</style>
<script>
  export default {
    name: "v-checktags",
    components: {},
    props: {
      value: {
        required: false,
        default: () => []
      },
      options: {
        type: Array,
        required: true,
        default: () => []
      },
      textKey: {
        type: String,
        default: 'text'
      },
      valueKey: {
        type: String,
        default: 'value'
      },
      multi: {
        type: Boolean,
        required: false,
        default: false
      },
      list: {
        type: Boolean,
        required: false,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      value(nv, ov) {
        this.data.forEach(n => n.value = (this.multi ? nv.includes(n.key) : nv == n.key))
        this.all = this.data.every(n => n.state)
      },
      options() {
        this.load()
      }
    },
    computed: {},
    data() {
      return {
        all: false,
        data: []
      };
    },
    methods: {
      onAll(checked) {
        this.data.forEach(n => n.state = checked)
        this.all = this.data.every(n => n.state)
        this.data.forEach(n => n.value = false);
        this.all = true;

        let value = this.data.filter(n => n.value).map(n => n.key)
        this.$emit('input', value)
        this.$emit('change', value)
      },
      onChange(i, checked) {
        if (this.multi) {
          this.all = this.data.every(n => n.value)
        } else {
          if (checked) {
            this.data.filter((n, idx) => idx != i).forEach(n => n.value = false)
          } else {
            this.data[i].value = true
            return false
          }
        }
        let value = this.data.filter(n => n.value).map(n => n.key)
        !this.multi && (value = value[0])
        this.$emit('input', value)
        this.$emit('change', value)
      },
      refreshVal() {
        let value = this.data.filter(n => n.value).map(n => n.key)
        !this.multi && (value = value[0])
        if (this.multi ? this.value.filter(n => !value.includes(n)).length : this.value != value) {
          this.$emit('input', value)
          this.$emit('change', value)
        }
        this.$emit('refresh-val', value)
        return value
      },
      load() {
        let data = this.options.map(n => ({
          text: n[this.textKey],
          key: n[this.valueKey],
          value: this.multi ? this.value.includes(n[this.valueKey]) : this.value == n[this.valueKey]
        }))
        this.multi && (this.all = !!data.length && !data.filter(n => !n.value).length)
        this.data = data
      }
    },
    mounted() {
      this.load()
    }
  }
</script>
