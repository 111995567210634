import request from '@/utils/request';

let module = '/hjk';
let casModule = '/cas';
let adModule = '/ad-onedata';


export default {
  // 广告主管理
  // 广告主管理列表
  getAccountList(params) {
    return request({
      baseURL: module,
      url: `/advertiser/getAdvertiserList`,
      method: 'get',
      params,
    });
  },
  // 广告主管理获取详情
  getAccountDetail(id) {
    return request({
      baseURL: module,
      url: `/advertiser/${ id }`,
      method: 'get',
    });
  },
  // 广告主管理提交详情
  submitAccountDetail(data) {
    return request({
      baseURL: module,
      url: `/advertiser/saveOrUpdateAdvertiser`,
      method: 'post',
      data,
    });
  },
  // 投手列表
  getUsersList(params) {
    return request({
      baseURL: casModule,
      url: `/ums/users`,
      method: 'get',
      params,
    });
  },
  //状态修改
  updateAdvertiserStatus(data) {
    return request({
      baseURL: module,
      url: `/advertiser/updateAdvertiserStatus`,
      method: 'post',
      data,
    });
  },
  //获取授权链接列表
  getOauthUrlList(params) {
    return request({
      baseURL: module,
      url: `/advertiser/getOauthUrlList`,
      method: 'get',
      params,
    });
  },


  // 广告平台列表
  getAdPlatformList() {
    return request({
      baseURL: module,
      url: `/advertiser/platform/getAdPlatformList`,
      method: 'get',
    });
  },


  // 获取平台账套列表
  getPlatformAccountList(params) {
    return request({
      baseURL: module,
      url: `/advertiser/account/getPlatformAccountList`,
      method: 'get',
      params,
    });
  },
  // 获取托管账套列表
  getTrusteeAccountList(params) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdPlatformAccountAuthRecord/page`,
      method: 'get',
      params,
    });
  },
  // 设置托管账套状态
  setTrusteeAccountStatus(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdPlatformAccountAuthRecord/trusteeAccount`,
      method: 'post',
      data,
    });
  },
  //提交托管
  submitTrusteeAccountStatus(data) {
    return request({
      baseURL: module,
      url: `/advertiser/account/setTrusteeAccountStatusForMagnetic`,
      method: 'post',
      data,
    });
  },


  // 获取授权管理列表
  getAdvertiserOauthList(params) {
    return request({
      baseURL: module,
      url: `/advertiser/oauth/ocean/getAdvertiserOauthList`,
      method: 'get',
      params,
    });
  },
  // 获取授权管理详情
  getAdvertiserOauthDetail(id) {
    return request({
      baseURL: module,
      url: `/advertiser/oauth/ocean/getAdvertiserOauthDetail/${ id }`,
      method: 'get',
    });
  },
  // 修改组织绑定状态
  updateAdvertiserOrgBindStatus(data) {
    return request({
      baseURL: module,
      url: `/advertiser/oauth/updateAdvertiserOrgBindStatus`,
      method: 'post',
      data,
    });
  },


  // 获取广告主管理看板
  getAdvertiserOauthData(params) {
    return request({
      baseURL: module,
      url: `/advertiser/dashboard/getAdvertiserOauthData`,
      method: 'get',
      params,
    });
  },


  // 获取广告主账号余额
  getAccountBalanceList(params) {
    return request({
      baseURL: module,
      url: `/advertiser/account/getAccountBalanceList`,
      method: 'get',
      params,
    });
  },


  // 获取广告主监测列表
  getAdvertiserMonitorList(params) {
    return request({
      baseURL: module,
      url: `/advertiser/monitor/getAdvertiserMonitorList`,
      method: 'get',
      params,
    });
  },
  // 获取广告主监测链接详情
  getAdvertiserMonitorDetail(params) {
    return request({
      baseURL: module,
      url: `/advertiser/monitor/detail`,
      method: 'get',
      params,
    });
  },
  // 设置广告主监测链接(创建or修改)
  saveOrUpdateMonitorUrl(data) {
    return request({
      baseURL: module,
      url: `/advertiser/monitor/saveOrUpdateMonitorUrl`,
      method: 'post',
      data,
    });
  },


  //线索管理
  //获取全部线索
  getAdvertiserClueList(params) {
    return request({
      baseURL: adModule,
      url: `/advertiserClue/list`,
      method: 'get',
      params,
    });
  },
  //获取线索明细
  getAdvertiserClueDetail(params) {
    return request({
      baseURL: adModule,
      url: `/advertiserClue/getOne`,
      method: 'get',
      params,
    });
  },
  // 获取车系
  getSeriesList() {
    return request({
      baseURL: '/base',
      url: `/series/full/v2`,
      method: 'get',
    });
  },
  //获取车系详情
  getAdvertiserClueCarDetail(params) {
    return request({
      baseURL: adModule,
      url: `/advertiserClue/get-one`,
      method: 'get',
      params,
    });
  },
  //修改车系
  submitAdvertiserClueCarDetail(data) {
    return request({
      baseURL: adModule,
      url: `/advertiserClue/update`,
      method: 'put',
      data,
    });
  },


  //分页查询视频案例列表
  getVideoCaseStatisticsPage(data) {
    return request({
      baseURL: module,
      url: `/advertiser/videoCase/getVideoCaseStatisticsPage`,
      method: 'post',
      data,
    });
  },
  //查询视频案例详情
  getVideoCaseDetailById(params) {
    return request({
      baseURL: module,
      url: `/advertiser/videoCase/getVideoCaseDetailById`,
      method: 'get',
      params,
    });

  },
  //修改视频案例
  submitVideoCaseDetail(data) {
    return request({
      baseURL: module,
      url: `/advertiser/videoCase/update`,
      method: 'post',
      data,
    });
  },


  // 广告主分组管理
  // 分页查询广告主组别列表
  getAdvGroup(params) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdvertiserGroup/advertiserPage',
      method: 'get',
      params,
    });
  },
  // 更新广告主组别
  updateAdvBindGroup(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdvertiserGroup/bindAdvertiser',
      method: 'PUT',
      data,
    });
  },
  // 组别管理
  // 组别管理列表
  getAdvGroupManageList(params) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdvertiserGroup/page',
      method: 'get',
      params,
    });
  },
  // 新增组别
  addAdvGroup(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdvertiserGroup',
      method: 'post',
      data,
    });
  },
  // 修改组别
  updateAdvGroup(data) {
    return request({
      baseURL: module,
      url: '/sysAdmin/hjkAdvertiserGroup',
      method: 'PUT',
      data,
    });
  },
  // 删除组别
  delAdvGroup(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdvertiserGroup/${ id }`,
      method: 'DELETE',
    });
  },


  // 广告主销售目标城市管理
  // 城市管理列表
  getCityManageList(params) {
    return request({
      baseURL: module,
      url: '/advertiser/manageTargetCity',
      method: 'get',
      params,
    });
  },
  // 广告主销售目标城市详情
  // 获取区域 level 1：省、2：市、3：区县
  getLevelArea(level) {
    return request({
      baseURL: '/base',
      url: `/areas/v1?level=${ level }`,
      method: 'get',
    });
  },
  // 绑定目标城市
  updateBindCity(data) {
    return request({
      baseURL: module,
      url: '/advertiser/bindTargetCityList',
      method: 'PUT',
      data,
    });
  },

  // 重新拉取授权的记录
  submitReHandle(userId) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkAdPlatformAccountAuthRecord/reHandle/${ userId }`,
      method: 'post',
    });
  },
};
