import request from "@/utils/request";

let baseURL = "/content-center";
let aftContent = '/afanti-content'

export default {
  //获取视频上传地址和凭证
  createVod(params) {
    return request({
      baseURL,
      url: "/v1/content/vod/createUploadVideo",
      method: "post",
      data: params,
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  createVodNew(params) {
    return request({
      baseURL: aftContent,
      url: '/v1/cms/vod/upload/config',
      method: "post",
      data: params,
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  //刷新视频上传凭证
  refreshVod(params) {
    return request({
      baseURL,
      url: "/v1/content/vod/refreshUploadVideo",
      method: "post",
      data: params,
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  //获取模板数据
  getTemplateData(params) {
    return request({
      baseURL,
      url: "/v1/content/vod/getTemplateData",
      method: "post",
      data: params
    });
  },
  //上传文件
  uploadFile(params) {
    return request({
      baseURL,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      url: "/v1/file/upload-by-file",
      method: "post",
      data: params
    });
  },
  //获取cdn加速的域名
  getUrlAuthKey() {
    return request({
      baseURL,
      url: "/v1/content/vod/getUrlAuthKey",
      method: "get"
    });
  },
  //获取鉴权密钥
  getAuthUrl(params) {
    return request({
      baseURL: aftContent,
      headers: {
        "Content-Type": "application/json"
      },
      // url: "/v1/content/vod/getAuthUrl",
      url: '/v1/cms/vod/getAuthUrl',
      method: "get",
      params
    });
  },
  // 获取已收藏的视频数据
  getFavoritet(id) {
    return request({
      url: `/favorite/findByUserId/${ id }`,
      method: "get",
    });
  },

  // 经销商列表
  getDealerList(params) {
    return request({
        baseURL: '/base',
        url: '/dealer/search/v3',
        method: 'get',
        params: params
    })
  },
  // 获取经销商详情
  getDealerDetail(params) {
    return request({
        baseURL: '/base',
        url: `/dealer/${params}/v2`,
        method: 'get',
        params: params
    })
  },
  //主体
  getPrincipalList() {
    return request({
      baseURL: '/base',
      url: '/principal/list/v1',
      method: 'get',
    })
  },
  //主体下的车系
  getPrincipalSeriesList (data) {
    return request({
      baseURL: `/base`,
      url: `/series/principals-series-info`,
      method: 'post',
      data
    })
  },
  getPrincipalOneSeriesList (id) {
    return request({
      baseURL: `/base`,
      url: `/${id}/series`,
      method: 'get',
      // data
    })
  },
};
