import axios from 'axios'
import _ from 'lodash'
import { auth } from '@/utils'

export default function (config = {}) {
  const fileName = '资料'
  let { baseURL, ...restConfig } = config

  if (baseURL) {
    baseURL = process.env.NODE_ENV === 'development' ? `/api${baseURL}` : baseURL
  } else {
    const baseAPI = process.env.VUE_APP_BASE_API

    baseURL = process.env.NODE_ENV === 'development' ? `/api${baseAPI}` : baseAPI
  }

  return new Promise(async(resolve, reject) => {
    try {
      const res = await axios({
        ...restConfig,
        baseURL,
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        responseType: 'blob'
      })
      // console.log(res)

      const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
      const objectUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      let name = _.get(res, 'headers.content-disposition', '')

      if (name) {
        name = name.split(';')[1]
        if (name) {
          name = window.decodeURIComponent(name)
          name = name.split('=')[1].replace(/\.xlsx$/, '')
        } else {
          name = fileName
        }
      } else {
        name = fileName
      }

      a.href = objectUrl
      a.download = `${name}.xlsx`
      a.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      )
      window.URL.revokeObjectURL(blob)

      resolve()
    } catch (e) {
      reject(new Error('导出Excel失败'))  
    } 
  })
}
