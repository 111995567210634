import request from '@/utils/request'

export default {
  getList (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/kpi`,
      method: 'get',
      params: params
    })
  },
  putKpiForm (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/kpi`,
      method: 'post',
      data: params
    })
  },
  getKpiData (id) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/kpi/${id}`,
      method: 'get'
    })
  },
  deleteKpi (id) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/kpi/${id}`,
      method: 'delete'
    })
  },
  getTemplateList (params) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/kpi/module`,
      method: 'get',
      params: params
    })
  },
  getModuleMameList (module_ids) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/kpi/module-name?module_ids=${module_ids}`,
      method: 'get'
    })
  },
  getmoduleTagList (name) {
    return request({
      baseURL: '/cmd-admin',
      url: `/afanti/train/kpi/select/module-tag`,
      method: 'get'
    })
  }
}
