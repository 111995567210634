import request from '@/utils/request'

let baseURL = '/hjk'


export default {
    // 导拍列表
    getTemplateList(params){
        return request({
            baseURL,
            url: '/sysAdmin/hjkDeliveryVideo/getGuideShootList',
            method: 'get',
            params
        })
    },

    //集客场景
    getCustomerScene() {
        return request({
            baseURL,
            url: `/sysAdmin/customerScene/list`,
            method: 'get',
        })
    },

    //广告主列表
    getLikeAdvertiserNameList(params){
        return request({
            baseURL,
            url: '/advertiser/getLikeAdvertiserName',
            method: 'get',
            params
        })
    },

    //账套列表
    getPlatformAccountList(params){
        return request({
            baseURL,
            url: '/advertiser/account/listByAdvertiserIdAndAccountNameForOcean',
            method: 'get',
            params
        })
    },


    //投放视频
    //列表
    getDeliveryVideoList(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkDeliveryVideo/list`,
            method: 'post',
            data
        })
    },
    //新增
    submitDeliveryVideoDetail(body) {
        return request({
            baseURL,
            url: `${body.type == 'add' ? '/sysAdmin/hjkDeliveryVideo' : '/sysAdmin/hjkDeliveryVideo/updateAdVideo'}`,
            method: `${body.type == 'add' ? 'post' : 'put'}`,
            data: body.data
        })
    },
    //详情
    getDeliveryVideoDetail(id) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkDeliveryVideo/${id}`,
            method: 'get',
        })
    },
    //赠送
    giftDeliveryVideoDetail(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkDeliveryVideo/giftVideo`,
            method: 'post',
            data
        })
    },
    //赠送视频-详情
    getGiftDeliveryVideoDetail(params) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkDeliveryVideo/getBriefDetailByHjkAdVideoId`,
            method: 'get',
            params
        })
    },
    //赠送视频-详情
    getGiftDeliveryVideoList(params) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkDeliveryVideo/pageAdvertiser/hjkAdVideoId`,
            method: 'get',
            params
        })
    },
    //根据hjkAdVideoId查询可播放视频的地址
    getUrlByHjkAdVideoId(hjkAdVideoId) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkDeliveryVideo/getUrlByHjkAdVideoId/${hjkAdVideoId}`,
            method: 'get',
        })
    },
    //批量有效、停用，valid=有效
    batchByAdVideoIds(params) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkDeliveryVideo/${params.type == 'valid' ? 'batchValidByAdVideoIds' : 'batchDisableByAdVideoIds'}`,
            method: 'put',
            data: params.data
        })
    },
    


    //创意标题
    //列表
    getCreativeTitleTemplateList(params) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkCreativeTitleTemplate/page`,
            method: 'get',
            params
        })
    },
    //提交
    submitCreativeTitleDetail(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkCreativeTitleTemplate`,
            method: 'post',
            data
        })
    },
    //更新状态
    updateCreativeTitle(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkCreativeTitleTemplate/updateStatus`,
            method: 'put',
            data
        })
    },


    //创意模板
    //列表
    getCreativeTemplateList(params) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkCreativeComponentTemplate/page`,
            method: 'get',
            params
        })
    },
    //提交
    submitCreativeTemplateDetail(body) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkCreativeComponentTemplate`,
            method: body.method,
            data: body.data
        })
    },
    //详情
    getCreativeTemplateDetail(id) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkCreativeComponentTemplate/${id}`,
            method: 'get',
        })
    },


    //青鸟线索
    //列表
    getFormTemplateList(params) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkFormTemplate/page`,
            method: 'get',
            params
        })
    },
    //模版列表
    getStencilList() {
        return request({
            baseURL,
            url: `/sysAdmin/hjkFormTemplate/formTemplateType/list`,
            method: 'get',
        })
    },
    //提交
    submitFormTemplateDetail(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkFormTemplate`,
            method: 'post',
            data
        })
    },
    //更新状态
    updateFormTemplate(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkFormTemplate/updateStatus`,
            method: 'put',
            data
        })
    },
    
    
    //橙子建站模板管理
    //列表
    getLandingPageTemplateList(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkLandingPageTemplate/page`,
            method: 'post',
            data
        })
    },
    //提交
    submitLandingPageTemplateDetail(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkLandingPageTemplate/repairTemplate`,
            method: 'put',
            data
        })
    },
    //详情
    getLandingPageTemplateDetail(id) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkLandingPageTemplate/${id}`,
            method: 'get',
        })
    },
    //批量有效
    batchValidByIds(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkLandingPageTemplate/batchValidByIds`,
            method: 'put',
            data
        })
    },
    //批量停用
    batchDisableByIds(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkLandingPageTemplate/batchDisableByIds`,
            method: 'put',
            data
        })
    },
    //批量赠送
    batchGiftByIds(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkLandingPageTemplate/batchGiftToSamePrincipalAdvertiser`,
            method: 'post',
            data
        })
    },
    //批量获取最新
    batchLatestByIds() {
        return request({
            baseURL,
            url: `/sysAdmin/hjkLandingPageTemplate/captureAdLandingPageForOcean`,
            method: 'get',
        })
    },
    //获取更新模板的时间
    getLastUpdateTime() {
        return request({
            baseURL,
            url: `/sysAdmin/hjkLandingPageTemplate/getLastUpdateTime`,
            method: 'get',
        })
    },
    


    //橙子建站落地页管理
    //列表
    getLandingPageList(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkAdLandingPage/page`,
            method: 'post',
            data
        })
    },
    //批量发布
    batchUpdateLandingPage(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkAdLandingPage/batchUpdateLandingPage`,
            method: 'post',
            data
        })
    },
    


    //橙子建站程序化落地页管理
    getAdLandingPageGroupList(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkAdLandingPageGroup/page`,
            method: 'post',
            data
        })
    },
    //提交
    submitAdLandingPageGroupDetail(body) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkAdLandingPageGroup`,
            method: body.method,
            data: body.data
        })
    },
    //详情
    getAdLandingPageGroupDetail(id) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkAdLandingPageGroup/${id}`,
            method: 'get',
        })
    },
    //获取落地页
    getPageByAdPlatformAccountIdList(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkAdLandingPage/pageByAdPlatformAccountId`,
            method: 'post',
            data
        })
    },
    //判断站点
    getByAdLandingPageId(params) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkAdLandingPage/getByAdLandingPageId`,
            method: 'get',
            params
        })
    },
    //新增站点
    addSite(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkAdLandingPageGroup/addSite`,
            method: 'put',
            data
        })
    },
    //更新站点状态
    updateSiteStatus(data) {
        return request({
            baseURL,
            url: `/sysAdmin/hjkAdLandingPageGroup/updateSiteStatus`,
            method: 'put',
            data
        })
    },
    

    
    //素材统计
    getMaterialStatisticsList(params) {
        return request({
            baseURL,
            url: `/advertiser/getAdvertiserMaterialStatisticsList`,
            method: 'get',
            params
        })
    },


    //模版覆盖统计
    //数据
    getTemplateCoverageSummaryList() {
        return request({
            baseURL,
            url: `/advertiser/getTemplateCoverageSummary`,
            method: 'get',
        })
    },
    //列表
    getTemplateCoverStatisticsList(params) {
        return request({
            baseURL,
            url: `/advertiser/getTemplateCoverStatisticsList`,
            method: 'get',
            params
        })
    },
    
    

    //巨量车系
    //列表
    getAllForCarSeriesList(data) {
        return request({
            baseURL,
            url: `/advertiser/getAllForCarSeriesList`,
            method: 'post',
            data
        })
    },
    
    //巨量产品库
    getProductLibraryPage(data) {
        return request({
            baseURL,
            url: `/advertiser/product/getProductLibraryPage`,
            method: 'post',
            data
        })
    },
    // 更新指定平台账套下的产品库信息
    updateByPlatformAccountId(params) {
        return request({
            baseURL,
            url: `/advertiser/product/updateByPlatformAccountId`,
            method: 'get',
            params
        })
    },
    

    //创意标签模板管理
    //列表
    getTagTemplateList(params) {
        return request({
            baseURL,
            url: `/advertiser/creativeLabelTemplate/getPageList`,
            method: 'get',
            params
        })
    },
    //新增、修改
    creativeTagTemplate(body) {
        return request({
            baseURL, 
            url: `/advertiser/creativeLabelTemplate/${ body.type == 'add' ? 'insert' : 'update' }`,
            method: 'post',
            data: body.data
        })
    },
    //获取详情
    getTagTemplateDetail(id) {
        return request({
            baseURL,
            url: `/advertiser/creativeLabelTemplate/${id}`,
            method: 'get',
        })
    },
    
    
    //人群包管理
    //列表
    getCrowdPackDataSourceList(params) {
        return request({
            baseURL,
            url: `/advertiser/dmp/getAudienceDataSourcePage`,
            method: 'get',
            params
        })
    },
    //详情
    getCrowdPackDataSourceDetail(params) {
        return request({
            baseURL,
            url: `/advertiser/dmp/getCustomAudiencePage`,
            method: 'get',
            params
        })
    },
    //获取人群包
    getAudienceDataSourceList(params) {
        return request({
            baseURL,
            url: `/advertiser/dmp/getAudienceDataSourceList`,
            method: 'get',
            params
        })
    },

    // 抖音号视频管理
    getAwemeVideoList(params) {
        return request({
            baseURL,
            url: '/sysAdmin/hjkAwemeVideo/pageAdmin',
            method: 'get',
            params
        })
    },
}