import request from '@/utils/request'
export default {
  getAuthorMonitor(groupId) {
    return request({
      url: '/dashboard/getAuthorMonitor/' + groupId,
      method: 'GET'
    })
  },
  getAwemeMonitor(groupId) {
    return request({
      url: '/dashboard/getAwemeMonitor/' + groupId,
      method: 'GET'
    })
  },
  countGroupInfo() {
    return request({
      url: '/dashboard/countGroupInfo',
      method: 'POST',
    })
  },
  liveGroupQuery() {
    return request({
      url: '/dashboard/liveGroupQuery',
      method: 'POST',
    })
  },
  liveRanking() {
    return request({
      url: '/dashboard/liveRanking',
      method: 'POST',
    })
  },
  awemeRanking() {
    return request({
      url: '/dashboard/awemeRanking',
      method: 'POST',
    })
  },
}
