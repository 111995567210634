import Layout from "../pages/layout";

/**
 * 项目管理
 */
export default {
  path: "/projectManagement",
  name: "projectManagement",
  component: Layout,
  meta: {title: "项目管理", icon: "project"},
  children: [
    {
      path: "/projectList",
      name: "projectList",
      component: () => import("@/pages/app/projectManagement/projectList"),
      meta: {title: "项目列表"}
    },
    {
      path: "/projectDetail",
      name: "projectDetail",
      component: () => import("@/pages/app/projectManagement/projectDetail"),
      meta: {title: "项目详情"},
      hidden: true
    },
    {
      path: "/KPIPageDetail",
      name: "KPIPageDetail",
      component: () => import("@/pages/app/projectManagement/KPIPageDetail"),
      meta: {title: "学员KPI结果明细"},
      hidden: true
    },
    {
      path: "/KPIAssessmentSetting",
      name: "KPIAssessmentSetting",
      component: () => import("@/pages/app/projectManagement/KPIAssessmentSetting"),
      meta: {title: "KPI考核设置"},
      hidden: true
    },
  ]
};
