// indexedDB.js，浏览器本地数据库操作

export default {
  // indexedDB兼容
  indexedDB: window.indexedDB || window.webkitindexedDB || window.msIndexedDB || mozIndexedDB,
  dbInstance:'TAPP',
  db:null,
  getDB(){
    
    let self = this;
    return new Promise((resolve,rej)=>{
      if(self.db!=null&&self.db!=undefined){
        
        resolve(self.db);
        return ;
      }
      var req = this.indexedDB.open(this.dbInstance, 2);
      
      req.onsuccess = e => {
        self.db = req.result;
        resolve(self.db);
      }
      req.onerror = e =>{
        rej(e);
      };
    });
  },
  init(){ //实例化数据库
    if (!this.indexedDB) {
        throw new Error("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.")
    }
    var req = this.indexedDB.open(this.dbInstance, 2);
    req.onupgradeneeded = e => {
      let db = req.result;
      //最近查询记录[id:'',type:'',value:a,createTime:11111]
      if (!db.objectStoreNames.contains('search_recent')) {
        let obStore = db.createObjectStore("search_recent", {
          keyPath: "id",
          // autoIncrement: true
        });  
        obStore.createIndex("idx_sr_type", "type", { unique: false });
      }

      this.closeDB(db);
    }
    req.onerror = e =>{
      console.error(e);
    };
  },
  
  executeTpl(createRequest){
    return new Promise((res,rej)=>{
      this.getDB().then(db=>{
        let request = createRequest(db);
        request.onsuccess = function (event) {
          res(event);
        };
      
        request.onerror = function (event) {
          rej(event);
        }
      }).catch(err=>{
        rej(err);
      });
    });
    
  },
  insert(objname,val){
    return this.executeTpl((db)=>{
      let request = db.transaction([objname], 'readwrite').objectStore(objname).add(val);
      return request;
    });
    
  },
  update(objname,val){
    return this.executeTpl((db)=>{
      let request = db.transaction([objname], 'readwrite').objectStore(objname).put(val);
      return request;
    });
  },
  findByPK(objname,pk){
    return this.executeTpl((db)=>{
      let request = db.transaction([objname], 'read').objectStore(objname).get(pk);
      return request;
    });
  },
  deleteByPK(objname,pk){
    return this.executeTpl((db)=>{
      let request = db.transaction([objname], 'readwrite').objectStore(objname).delete(pk);
      return request;
    });
  },
  openCursor(objname){
    return this.executeTpl((db)=>{
      let request = db.transaction([objname], 'readwrite').objectStore(objname).openCursor();
      return request;
    });
  },
  findAll(objname){
    return this.executeTpl((db)=>{
      let request = db.transaction([objname], 'readwrite').objectStore(objname).getAll();
      return request;
    });
  },
  // 删除数据库
  deleteDB: function (dbname, callback) {
    var deleteQuest = this.indexedDB.deleteDatabase(dbname);
    deleteQuest.onerror = function () {
      console.log('删除数据库出错');
    };
    deleteQuest.onsuccess = function () {
      if (callback && (typeof callback === 'function')) {
        callback();
      }
    }
  },
  // 关闭数据库
  closeDB: function (db) {
    db.close();
    console.log('数据库已关闭');
  },
}
