import RouteView from '../pages/layout/RouteView';

/**
 * 巨量广告管理
 */
export default {
  path: '/JLAdvertManage',
  name: 'JLAdvertManage:JLAdvertIndex',
  component: RouteView,
  meta: { title: '巨量广告管理', icon: 'hdd' },
  redirect: '/advertisingPlanManage',
  children: [
    //广告优化看板
    {
      path: '/advertisingOptimizationBoard',
      name: 'advertisingPlanManage:advertisingOptimizationBoard',
      component: () => import('@/pages/app/JLAdvertManage/advertisingOptimizationBoard/index'),
      meta: { title: '广告优化看板' },
    },
    //广告计划管理
    {
      path: '/advertisingPlanManage',
      name: 'advertisingPlanManage:advertisingPlanIndex',
      component: () => import('@/pages/app/JLAdvertManage/advertisingPlanManage/index'),
      meta: { title: '广告计划管理' },
    },
    //平台计划托管
    {
      path: '/advertisingPlanTrustShip',
      name: 'advertisingPlanManage:advertisingPlanTrustShip',
      component: () => import('@/pages/app/JLAdvertManage/advertisingPlanManage/trustship'),
      meta: { title: '平台计划托管' },
      hidden: true,
    },

    //广告组管理
    {
      path: '/advertisingGroupManage',
      name: 'advertisingGroupManage:advertisingGroupIndex',
      component: () => import('@/pages/app/JLAdvertManage/advertisingGroupManage/index'),
      meta: { title: '广告组管理' },
    },

    //广告创意管理
    {
      path: '/advertisingIdeaManage',
      name: 'advertisingIdeaManage:advertisingIdeaIndex',
      component: () => import('@/pages/app/JLAdvertManage/advertisingIdeaManage/index'),
      meta: { title: '广告创意管理' },
    },

    //关键词广告管理
    {
      path: '/advertisingKeywordManage',
      name: 'advertisingKeywordManage:advertisingKeywordIndex',
      component: () => import('@/pages/app/JLAdvertManage/advertisingKeywordManage/index'),
      meta: { title: '关键词广告管理' },
    },
    //平台账套管理
    {
      path: '/platformCreditManage',
      name: 'platformCreditManage',
      component: () => import('@/pages/app/JLAdvertManage/platformCreditManage/index'),
      meta: { title: '平台账套管理' },
    },

    //巨量项目管理
    {
      path: '/advertisingProjectManage',
      name: 'advertisingProjectManage',
      component: () => import('@/pages/app/JLAdvertManage/advertisingProjectManage/index'),
      meta: { title: '巨量项目管理' },
    },

    // 广告管理
    {
      path: '/advertisingManage',
      name: 'advertisingManage',
      component: () => import('@/pages/app/JLAdvertManage/advertisingManage/index'),
      meta: { title: '广告管理' },
    },

    // 素材管理开关
    {
      path: '/materialSwitchManage',
      name: 'materialSwitchManage',
      component: () => import('@/pages/app/JLAdvertManage/materialSwitchManage/index'),
      meta: { title: '素材开关管理' },
    },
  ],
};
