import Vue from 'vue'

// --表单验证 --

const validator = {
  isPriceNumber(rule, _keyword, callback) {
    if (!_keyword) {
      if (rule.required) {
        return callback(new Error('金额不能为空'));
      }
      return callback()

    }
    if (_keyword == "0" || _keyword == "0." || _keyword == "0.0" || _keyword == "0.00") {
      _keyword = "0";
      return callback();
    } else {
      var index = _keyword.indexOf("0");
      var length = _keyword.length;
      if (index == 0 && length > 1) { /*0开头的数字串*/
        var reg = /^[0]{1}[.]{1}[0-9]{1,2}$/;
        if (!reg.test(_keyword)) {
          return false;
        } else {
          return true;
        }
      } else { /*非0开头的数字*/
        var reg = /^[1-9]{1}[0-9]{0,10}[.]{0,1}[0-9]{0,2}$/;
        if (!reg.test(_keyword)) {
          return callback(new Error('格式不正确'));
        } else {
          return callback();
        }
      }
      return callback();
    }
  },
  // 手机号验证
  isValidPhone(rule, value, callback) {
    if (!value) {
      if (rule.required) {
        return callback(new Error('手机号不能为空'));
      }
      return callback()

    }
    const reg = /^1[2|3|4|5|6|7|8][0-9]\d{8}$/
    if (reg.test(value)) {
      return callback()
    } else {
      return callback(new Error('手机号格式不正确'))
    }
  },
  isValidEmail(rule, value, callback) {
    if (!value) {
      if (rule.required) {
        return callback(new Error('邮箱不能为空'));
      }
      return callback()

    }
    const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
    if (reg.test(value)) {
      return callback()
    } else {
      return callback(new Error('邮箱格式不正确'))
    }
  },
  
  isValidVinCode(rule, value, callback) {
    if (!value) {
      if (rule.required) {
        return callback(new Error('VIN码不能为空'));
      }
      return callback()
    }
    const reg = /^[0-9A-Za-z_-]{17}$/;
    if (reg.test(value)) {
      return callback()
    } else {
      return callback(new Error('格式不正确[格式：17位数字、字母、-、_或其组合]'))
    }
  },
  isValidIdcardNo(rule, value, callback) {  
    if(!value){
      if (rule.required) {
        return callback(new Error('身份证不能为空'));
      }
      return callback()
    }
    const reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/;  
    if(reg.test(value)){ 
      return callback()
    } else {
      return callback(new Error('身份证格式不正确或长度不够'))
    }
  },
  isVehicleNumber(rule, vehicleNumber, callback) {
      if(!vehicleNumber){
        if (rule.required) {
          return callback(new Error('车牌号不能为空'));
        }
        return callback()
      }
      var result = false;
      if (vehicleNumber.length == 7||vehicleNumber.length == 8){
        var express = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1,2}$/;
        result = express.test(vehicleNumber);
      }
      if(result){ 
        return callback()
      } else {
        return callback(new Error('车牌号格式不正确'))
      }
  }
  
}

Vue.prototype.$validator = validator
export default validator
