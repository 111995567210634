import SparkMD5 from 'spark-md5';

export function timeFix() {
  const time = new Date();
  const hour = time.getHours();
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好';
}

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了'];
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents');
  event.initEvent('resize', true, true);
  event.eventType = 'message';
  window.dispatchEvent(event);
}

export function handleScrollHeader(callback) {
  let timer = 0;

  let beforeScrollTop = window.pageYOffset;
  callback = callback || function () {};
  window.addEventListener(
    'scroll',
    (event) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        let direction = 'up';
        const afterScrollTop = window.pageYOffset;
        const delta = afterScrollTop - beforeScrollTop;
        if (delta === 0) {
          return false;
        }
        direction = delta > 0 ? 'down' : 'up';
        callback(direction);
        beforeScrollTop = afterScrollTop;
      }, 50);
    },
    false
  );
}

export function isIE() {
  const bw = window.navigator.userAgent;
  const compare = (s) => bw.indexOf(s) >= 0;
  const ie11 = (() => 'ActiveXObject' in window)();
  return compare('MSIE') || ie11;
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return;
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id));
  }, timeout);
}

// base64转上传oss配置的file文件
export function base64ToFile(base64Data, fileName, filePath = '/bfz/xhs/', ossFilePath = '/bfz/image/', appId = 'bfz') {
  const timeStr = Date.now().toString();
  fileName = fileName ? fileName : `file_${timeStr}.png`;
  var arr = base64Data.split(',');
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const file = new File([u8arr], fileName, { type: mime });
  return new Promise((resolve) => {
    let fileReader = new FileReader();
    let spark = new SparkMD5.ArrayBuffer(); //获取文件二进制数据
    fileReader.readAsArrayBuffer(file); //file就是获取到的文件 //异步执行函数
    fileReader.onload = function (e) {
      spark.append(e.target.result);
      let md5 = spark.end();
      file.md5 = md5;
      file.params_data = {
        appId,
        filePath,
        identifier: file.md5,
        name: file.name,
        title: file.name.split('.')[0],
        ossFilePath,
      };
      resolve(file);
    };
  });
}

export const aliImgTransform = (url, width) => {
  return `${url}${url.includes('?') ? '&' : '?'}x-oss-process=image/resize,w_${width},m_lfit`;
};

export function generateRandomString(length = 8) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
