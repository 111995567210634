<!--菜单 -->
<template>
  <a-menu
    mode="inline"
    theme="dark"
    :default-selected-keys="activeKeys"
    v-if="permRouters.length > 0"
    v-model="activeKeys"
    :open-keys.sync="openKeys"
    @click="handleClick"
    @select="onSelected"
    @openChange="onOpenChange"
    :inlineIndent="15"
  >
    <template v-for="(item, index) in permRouters">
      <template v-if="!item.hidden && (!item.admin || $router.app.user.username == 'admin')">
        <a-menu-item v-if="item.children.length === 0" :key="item.name">
          <a-icon v-if="item.icon && item.icon !== 'null'" :type="item.icon" />
          <span v-if="item.title">{{ item.title }}</span>
        </a-menu-item>
        <sub-menu v-else :key="item.name" :menu-info="item" />

        <!-- <a-sub-menu :key="item.name" v-if="item.children.length > 0">
          <template slot="title">
            <a-icon :type="item.icon" v-if="item.children && item.icon" />
            <span v-if="item && item.title">{{ item.title }}</span>
          </template>
          <template v-for="(child, j) in item.children">
            <a-menu-item
              v-if="!child.hidden && child.children.length == 0"
              :key="child.name"
            >
              <router-link
                v-if="child && child.title"
                :to="{
                  name: child.name,
                  params: child.params,
                  query: child.query,
                }"
                >{{ child.title }}</router-link
              >
            </a-menu-item>

            <a-sub-menu :key="child.name" v-if="child.children.length > 0">
              <template slot="title">
                <a-icon :type="child.icon" v-if="child.children && child.icon" />
                <span v-if="child && child.title">{{ child.title }}</span>
              </template>
              <a-menu-item
                v-if="!child.hidden"
                :key="k.name"
                v-for="k in child.children"
              >
                <router-link
                  v-if="k && k.title"
                  :to="{
                    name: k.name,
                    params: k.params,
                    query: k.query,
                  }"
                  >{{ k.title }}</router-link
                >
              </a-menu-item>
            </a-sub-menu>
          </template>
        </a-sub-menu>
        <a-menu-item :key="item.name" v-if="item.children.length === 0">
          <a-icon :type="item.icon" v-if="item && item.icon" />
          <router-link
            v-if="item && item.title"
            :to="{ name: item.name, params: item.params, query: item.query }"
            >{{ item.title }}</router-link
          >
        </a-menu-item> -->
      </template>
    </template>
  </a-menu>
  <a-alert v-else title :closable="false" type="warning" style="margin: 5px" :message="mess" />
</template>

<style scoped>
.ant-menu-item {
  overflow: unset !important;
}
.ant-menu-item > a {
  display: inline;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon + a {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
</style>

<script>
import { routers } from '@/router';
import { Menu } from 'ant-design-vue';

const SubMenu = {
  template: `
    <a-sub-menu :key="menuInfo.name" v-bind="$props" v-on="$listeners">
      <span slot="title">
        <a-icon v-if="menuInfo.icon && menuInfo.icon !== 'null'" :type="menuInfo.icon" />
        <span>{{ menuInfo.title }}</span>
      </span>
      <template v-for="item in menuInfo.children">
        <template v-if="!item.hidden">
          <a-menu-item v-if="item.children.length === 0" :key="item.name">
            <a-icon v-if="item.icon && item.icon !== 'null'" :type="item.icon" />
            <span>{{ item.title }}</span>
          </a-menu-item>
          <sub-menu v-else :key="item.name" :menu-info="item" />
       </template>
      </template>
    </a-sub-menu>
  `,
  name: 'SubMenu',
  props: {
    ...Menu.SubMenu.props,
    // Cannot overlap with properties within Menu.SubMenu.props
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  // must add isSubMenu: true
  isSubMenu: true,
};

export default {
  components: {
    SubMenu,
  },
  data() {
    return {
      collapse: false,
      activeKeys: [],
      currentDomain: '', // 当前域
      permRouters: [],
      openKeys: [],
      msgPage: {
        data: [],
        total: 0,
      },
      isNest: false,
      mess: '暂无权限',
    };
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        if (!this.activeKeys.includes(this.$route.name)) {
          this.activeKeys = this.getSelectedKeys(this.$route);
          this.openKeys = this.collapse ? [] : this.getOpenKeys(this.$route);
        }
      });
    },
  },
  methods: {
    handleClick({ item, key, keyPath }) {
      if (key !== this.$route.name) {
        this.$router.push({ name: key });
      }
    },
    onOpenChange(openKeys) {
      console.log({ openKeys });
    },
    onSelected({ item, key, keyPath }) {
      /*
        let idxs = item.key.split(",")
        let child = this.permRouters[idxs[0]].children[idxs[1]]
        this.$route.name != child.name && this.$router.push({name:child.name,params:child.params,query:child.query})
        */
      // if (key != this.$route.name) {
      //   this.$router.push({
      //     name: key
      //   });
      // }
    },
    toggoleCollapse(flag) {
      this.collapse = flag;
      // this.openKeys = this.collapse ? [] : this.getOpenKeys(this.$route);
      this.openKeys = this.collapse ? [] : this.getCurrentOpenKeys(this.permRouters);
      this.activeKeys = [this.$route.name];
    },
    getShowingChildren(children) {
      const showingChildren = children.filter((item) => {
        return !item.hidden && this.$hasPermission(item.meta.perm);
      });
      return showingChildren;
    },
    hasOneShowingChildren(children) {
      const showingChildren = this.getShowingChildren(children);
      if (showingChildren.length === 1) {
        return true;
      }
      return false;
    },
    hasMoreThanOneShowingChildren(children) {
      const showingChildren = this.getShowingChildren(children);
      if (showingChildren.length > 1) {
        return true;
      }
      return false;
    },
    generateTitle(title) {
      return title;
    },
    linkTo(path) {
      this.$router.push(path);
    },
    getPermRouter(_r) {
      let r = _.cloneDeep(_r);
      let m = r || {};
      let result = null;
      if (r.children) {
        let childs = [];
        for (let i = 0; i < r.children.length; i++) {
          let c = r.children[i];
          let tmp = this.getPermRouter(c);
          if (tmp != null) {
            childs.push(tmp);
          }
        }
        if (childs.length > 0) {
          result = r;
          result.children = childs;
        }
        return result;
      } else {
        if (this.$hasPermission(m.perm)) {
          return r;
        } else {
          return null;
        }
      }
    },
    getPermRouters() {
      let rs = [];
      routers.forEach((r) => {
        let tmp = this.getPermRouter(r);
        tmp && rs.push(tmp);
      });
      return rs;
    },
    //
    flattenTree(tree) {
      const result = [];

      function traverse(node) {
        result.push(node);
        if (node.children) {
          for (const child of node.children) {
            child.path += node.path;
            traverse(child);
          }
        }
      }

      for (const item of tree) {
        traverse(item);
      }
      return result;
    },
    async getRouteList() {
      let rs = [];
      await this.$api.AppService.getMenus().then((res) => {
        if (res.code == 200) {
          console.log('routeList', res.data);
          this.permRouters = res.data;
          const menusData = this.flattenTree(res.data).filter((item) => {
            return item.level != 1;
          });
          window.localStorage.setItem('menus', JSON.stringify(menusData));
          this.openKeys = this.collapse ? [] : this.getCurrentOpenKeys(res.data);
          this.activeKeys = [this.$route.name];
        }
      });
      return rs;
    },
    getOpenKeys(route) {
      let name = route.name;
      let rs = [];

      for (let i = 0; i < routers.length; i++) {
        let r = routers[i];
        let children = r.children || [];
        let tmp = children.filter((c) => c.name == name);
        tmp.length > 0 && r.name && rs.push(r.name);
      }
      return rs;
    },
    getCurrentOpenKeys(routeList, fName = undefined) {
      let openKeys = [];
      routeList.forEach((route) => {
        if (route.children.length) {
          const isChild = route.children.findIndex((val) => val.name === this.$route.name);
          isChild !== -1 && openKeys.push(...[route.name, fName]);
          openKeys = openKeys.concat(this.getCurrentOpenKeys(route.children, route.name));
          openKeys.forEach((keys) => {
            const isFather = route.children.findIndex((child) => child.name === keys);
            isFather !== -1 && openKeys.push(route.name);
            return;
          });
        }
      });

      return [...new Set(openKeys)];
    },
    getSelectedKeys(route) {
      let name = route.name;
      let rs = [];

      for (let i = 0; i < routers.length; i++) {
        let r = routers[i];
        let children = r.children || [];
        let tmp = children.filter((c) => c.name == name);
        for (let j = 0; j < tmp.length; j++) {
          rs.push(tmp[0].name);
        }
      }
      rs[0] == 'index' && (rs[0] = 'dashboard');
      return rs;
    },
  },
  mounted() {
    this.permRouters = [];
    // let p = this.getPermRouters();
    // this.permRouters = p;
    this.getRouteList();
    this.activeKeys = this.getSelectedKeys(this.$route);
    // 路由改变时计算菜单折叠
    this.$bus.$on('routerChange', ()=>{
      if(this.permRouters.length){
        this.openKeys = this.collapse ? [] : this.getCurrentOpenKeys(this.permRouters);
        this.activeKeys = [this.$route.name];
      }
    })
    //设置默认
    // if(this.openKeys.length<1){
    //     for(let i=0;i<this.permRouters.length;i++){
    //       let r = this.permRouters[i];
    //       let tmp = this.getShowingChildren(r.children||[]);
    //       if(r.name&&tmp.length>0){
    //         this.openKeys = [r.name];
    //         break;
    //       }
    //     }
    // }
  },
};
</script>
