import request from "@/utils/request";
import _ from "lodash";
import transMediaList from "@/utils/transMediaList.js";
import axios from "axios";


const baseURL = "/content-center";
const nr = axios.create({
  baseURL: baseURL, // api的base_url
  timeout: 60000// request timeout
});
const creator = axios.create({
  baseURL: '/afanti-creator', // api的base_url
  timeout: 60000// request timeout
});
const base = axios.create({
  baseURL: '/base', // api的base_url
  timeout: 60000// request timeout
});
nr.interceptors.response.use((respone) => {
  return respone.data;
});

const baseURLTwo = '/dyopen';
const baseURLKs = '/ksopen';
const module = '/base';
const sr = axios.create({
  baseURL: baseURLTwo, // api的base_url
  timeout: 10000// request timeout
});
sr.interceptors.response.use((respone) => {
  return respone.data;
});

const kr = axios.create({
  baseURL: baseURLKs, // api的base_url
  timeout: 10000// request timeout
});
kr.interceptors.response.use((respone) => {
  return respone.data;
});

export default {
  getMediaInfo (params) {
    return request({
      baseURL,
      url: '/v1/ice/media-info',
      method: 'get',
      params
    })
      .then((res) => {
        let fileUrl = _.get(
          res,
          "data.body.MediaInfo.FileInfoList[0].FileBasicInfo.FileUrl",
          ""
        );
        if (fileUrl) {
          return fileUrl;
        } else {
          return Promise.reject(res);
        }
      });
  },
  getProjectMaterialsInfo (params) {
    return request({
      baseURL,
      url: "/v1/ice/project-materials-info",
      method: 'get',
      params
    })
      .then((res) => {
        const data = _.get(res, "data.body.MediaInfos", []);
        const list = transMediaList(data);
        return list;
      });
  },
  getPublicMediaInfo (body) {
    return request({
      method: 'post',
      url: '/v1/ice/public-media-basic-infos',
      data: body,
      baseURL
    });
    // return nr.post('/public-media-basic-infos', body)
  },
  postProjectMaterials (body) {
    return request({
      url: '/v1/ice/project-materials-info',
      method: 'post',
      data: body,
      baseURL
    }).then(res => {
      const data = _.get(res, 'data.body.MediaInfos', []);
      const list = transMediaList(data);
      return list;
    });
  },
  deleteEditingProjectMaterials (body) {
    return request({
      url: '/v1/ice/editing-project-materials',
      method: 'delete',
      baseURL,
      data: body
    });
    // return nr.delete('/editing-project-materials', { data: body })
  },
  getEditingProject (params) {
    return request({
      baseURL,
      url: "/v1/ice/project-info",
      method: 'get',
      params
    }).then(res => {
      return res.data.body;
    });
  },
  putEditingProject (body) {
    return request({
      baseURL,
      url: '/v1/ice/editing-project',
      method: 'put',
      data: body
    });
  },
  getStickerCategories () {
    return nr.get();
  },
  postMediaProductingJob (body) {
    return request({
      baseURL,
      url: '/v1/ice/media-producing-job',
      method: 'post',
      data: body
    });
    // return nr.post('/media-producing-job', body)
  },
  getTailorList (params) {
    return request({
      baseURL,
      url: '/v1/ice/material/pages',
      method: 'get',
      params
    });
    // return nr.get('/material/pages',  { params })
  },
  getDouyinShouquan (params) {
    return sr.get('/auth/get-auth-url', {params});
  },
  getJudgeVideoStatus (params) {
    return request({
      method: 'get',
      url: '/v1/ice/media-producing-job',
      baseURL,
      params
    });
    // return nr.get('/media-producing-job', { params })
  },
  getLiveAddOss (data) {
    return request({
      method: 'post',
      url: '/v1/ice/editing-project-add-oss-resource',
      baseURL,
      data
    });
    // return nr.post('/editing-project-add-oss-resource', body)
  },
  getLiveAddStatus (params) {
    return request({
      method: 'get',
      url: '/v1/ice/room/ice-project/status',
      baseURL,
      params
    });
  },
  getQRcode () {
    return request({
      url: '/public-service-auth/getQRCode',
      method: "post"
    });
  },
  getServiceAuth (data, cancelToken) {
    console.log(cancelToken);
    return request({
      url: '/public-service-auth/getScanCodeStatus2',
      method: 'post',
      data,
      timeout: 72000,
      cancelToken
    });
  },
  getKuaishouAccredit (params) {
    return kr.get('/auth/scan-code-url/v2', {params});
  },
  exportTimes (data) {
    return request({
      url: '/v1/live/media/item/export/fragment',
      method: 'post',
      data,
      baseURL
    });
  },
  getdyTailorTabelList (params) {
    return request({
      baseURL,
      url: '/v1/bi/douyin/attention/author/live-record',
      method: 'get',
      params
    });
  },
  getksailorTabelList (params) {
    return request({
      baseURL,
      url: '/v1/bi/kuaishou/attention/author/live-record',
      method: 'get',
      params
    });
  },
  getCliptTableList (params) {
    return request({
      baseURL,
      url: '/v1/live/media/item/delar/code/list',
      method: 'get',
      params
    });
  },
  getTagList (params) {
    return request({
      baseURL,
      url: '/v1/media/tag/list',
      method: 'get',
      params
    });
  },
  getMaterialDetails (params) {
    return request({
      baseURL,
      url: '/v1/live/media/item/page',
      method: 'get',
      params
    });
  },
  getMmappingList (data) {
    return request({
      baseURL: '/base',
      url: '/series/list-all-series',
      method: 'post',
      data
    });
    // return base.get(`/series/page/v1?current=${params.current}&page_size=${params.page_size}&filter=${params.filter}`)
  },
  // getMmappingList(data){
  //   return base({
  //     url:'/series/page/v1',
  //     method:'post',
  //     data,
  //   })
  // },
  saveTelation (data) {
    return request({
      baseURL,
      url: '/v1/media/tag/relation',
      method: 'post',
      data
    });
  },
  checkAccountExist (data) {
    return creator.post('/douyin/creator/cookie/check-account-exist', data);
  },
  checkAccountLogin (data) {
    return creator.post('/douyin/creator/cookie/check-account-login', data);
    // return request({
    //   url: '/afanti-creator/douyin/creator/cookie/check-account-login',
    //   method: 'post',
    //   data: params
    // });
  }
};
