import request from '@/utils/request';

let module = '/hjk';

export default {
  //推广管理
  //投放协议管理
  getAdDeliveryAgreementList(params) {
    return request({
      baseURL: module,
      url: `/advertiser/adDeliveryAgreement/queryByPageForAdmin`,
      method: 'get',
      params,
    });
  },
  //获取投放协议详情
  getAdvertiserAgreementDetail(id) {
    return request({
      baseURL: module,
      url: `/advertiser/adDeliveryAgreement/queryDetailByIdForAdmin/${id}`,
      method: 'get',
    });
  },
  //提交投放协议详情
  submitAdDeliveryAgreementDetail(params) {
    return request({
      baseURL: module,
      url: `/advertiser/adDeliveryAgreement`,
      method: params.method,
      data: params.data,
    });
  },
  //更新广告投放协议显示状态
  updateAdvertiserAgreementStatus(data) {
    return request({
      baseURL: module,
      url: `/advertiser/adDeliveryAgreement/updateShowStatus`,
      method: 'put',
      data,
    });
  },

  //任务管理
  //任务列表
  getTaskList(params) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/page`,
      method: 'get',
      params,
    });
  },
  //通过任务id查询匹配的广告主列表
  getTaskMatchAdvertiser(missionId) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/matchAdvertiser/${missionId}`,
      method: 'get',
    });
  },
  //开启投放
  turnOnDelivery(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/turnOnDelivery`,
      method: 'post',
      data,
    });
  },
  //获取任务结束时间
  findBriefDetailById(missionId) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/findBriefDetailById/${missionId}`,
      method: 'get',
    });
  },
  //删除任务
  delTask(missionId) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/${missionId}`,
      method: 'delete',
    });
  },
  //开启、暂停任务
  taskAction(body) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/${body.type}/${body.missionId}`,
      method: 'put',
    });
  },
  //提交详情
  submitTaskData(body) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission`,
      method: body.method,
      data: body.data,
    });
  },
  //获取详情
  getTaskDetail(missionId) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/${missionId}`,
      method: 'get',
    });
  },
  //绑定任务
  bindProject(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/bindProject`,
      method: 'put',
      data,
    });
  },
  //通过任务id查询任务预算与kpi详情
  findBudgetAndKpiDetailByMissionId(missionId) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/findBudgetAndKpiDetailByMissionId/${missionId}`,
      method: 'get',
    });
  },
  //更新任务预算与车系kpi
  updateBudgetAndKpi(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/updateBudgetAndKpi`,
      method: 'put',
      data,
    });
  },
  //
  getMissionProgressList(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/pageForProgress`,
      method: 'post',
      data,
    });
  },
  //通过任务名称模糊查询任务简要信息
  getMissionBriefPageQuery(params) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/missionBriefPageQuery`,
      method: 'get',
      params,
    });
  },

  //任务进度
  //任务进度列表
  getMissionProgressList(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/pageForProgress`,
      method: 'post',
      data,
    });
  },
  //任务管理
  //任务列表
  getTaskList(params) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/page`,
      method: 'get',
      params,
    });
  },
  //通过任务id查询匹配的广告主列表
  getTaskMatchAdvertiser(missionId) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/matchAdvertiser/${missionId}`,
      method: 'get',
    });
  },
  //开启投放
  turnOnDelivery(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/turnOnDelivery`,
      method: 'post',
      data,
    });
  },
  //获取任务结束时间
  findBriefDetailById(missionId) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/findBriefDetailById/${missionId}`,
      method: 'get',
    });
  },
  //删除任务
  delTask(missionId) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/${missionId}`,
      method: 'delete',
    });
  },
  //开启、暂停任务
  taskAction(body) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/${body.type}/${body.missionId}`,
      method: 'put',
    });
  },
  //提交详情
  submitTaskData(body) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission`,
      method: body.method,
      data: body.data,
    });
  },
  //获取详情
  getTaskDetail(missionId) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/${missionId}`,
      method: 'get',
    });
  },
  //绑定任务
  bindProject(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/bindProject`,
      method: 'put',
      data,
    });
  },
  //通过任务id查询任务预算与kpi详情
  findBudgetAndKpiDetailByMissionId(missionId) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/findBudgetAndKpiDetailByMissionId/${missionId}`,
      method: 'get',
    });
  },
  //更新任务预算与车系kpi
  updateBudgetAndKpi(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/updateBudgetAndKpi`,
      method: 'put',
      data,
    });
  },
  //
  getMissionProgressList(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/pageForProgress`,
      method: 'post',
      data,
    });
  },
  //通过任务名称模糊查询任务简要信息
  getMissionBriefPageQuery(params) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkMission/missionBriefPageQuery`,
      method: 'get',
      params,
    });
  },

  //项目管理
  //列表
  getProjectList(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkProject/page`,
      method: 'post',
      data,
    });
  },
  //删除
  deleteProject(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkProject/${id}`,
      method: 'delete',
    });
  },
  //暂停、开启
  actionProject(body) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkProject/${body.type}/${body.id}`,
      method: 'put',
    });
  },
  //获取详情
  getProjectDetail(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkProject/${id}`,
      method: 'get',
    });
  },
  //提交详情
  submitProjectDetail(body) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkProject`,
      method: body.method,
      data: body.data,
    });
  },
  //获取负责人
  getManagerList(params) {
    return request({
      baseURL: '/cas',
      url: `/ums/users`,
      method: 'get',
      params,
    });
  },

  //项目进度
  //项目进度列表
  getProjectProgressList(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkProject/pageForProgress`,
      method: 'post',
      data,
    });
  },

  //项目管理
  //列表
  getProjectList(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkProject/page`,
      method: 'post',
      data,
    });
  },
  //删除
  deleteProject(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkProject/${id}`,
      method: 'delete',
    });
  },
  //暂停、开启
  actionProject(body) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkProject/${body.type}/${body.id}`,
      method: 'put',
    });
  },
  //获取详情
  getProjectDetail(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkProject/${id}`,
      method: 'get',
    });
  },
  //提交详情
  submitProjectDetail(body) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkProject`,
      method: body.method,
      data: body.data,
    });
  },
  //获取负责人
  getManagerList(params) {
    return request({
      baseURL: '/cas',
      url: `/ums/users`,
      method: 'get',
      params,
    });
  },

  //项目进度
  //项目进度列表
  getProjectProgressList(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/hjkProject/pageForProgress`,
      method: 'post',
      data,
    });
  },

  //自动规则
  //自动规则管理 - 获取列表
  getAdRuleList(params) {
    return request({
      baseURL: module,
      url: `/sysAdmin/adAutomateRule/list/query?page=${params.page}&size=${params.size}&ruleTypeList=${params.ruleTypeList}&status=${params.status}&advertiserName=${params.advertiserName}&ruleId=${params.ruleId}`,
      method: 'get',
    });
  },
  //自动规则管理 - 更改状态
  updateStatus(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/adAutomateRule/update/status`,
      method: 'put',
      data,
    });
  },
  //自动规则管理 - 删除
  deleteRule(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/adAutomateRule/delete/${id}`,
      method: 'delete',
    });
  },
  //自动规则管理 - 保存
  saveRule(data) {
    return request({
      baseURL: module,
      url: `/sysAdmin/adAutomateRule/saveRule`,
      method: 'post',
      data,
    });
  },
  //自动规则管理 - 获取详情
  getRuleDetail(id) {
    return request({
      baseURL: module,
      url: `/sysAdmin/adAutomateRule/detail/${id}`,
      method: 'get',
    });
  },
  //自动规则管理 - 获取规则类型
  getAdRuleTypeList() {
    return request({
      baseURL: module,
      url: `/sysAdmin/adAutomateRuleActionLog/adRuleTypeList`,
      method: 'get',
    });
  },
  //自动规则管理 - 获取规则
  getAdAutomateRuleList(params) {
    return request({
      baseURL: module,
      url: `/sysAdmin/adAutomateRuleMetadata/metaList`,
      method: 'get',
      params,
    });
  },
  //自动规则执行记录
  getAdAutomateRuleActionLog(params) {
    return request({
      baseURL: module,
      url: `/sysAdmin/adAutomateRuleActionLog/getPageList`,
      method: 'get',
      params,
    });
  },

  // 目标列表
  getAdTargetList(params) {
    return request({
      baseURL: module,
      url: `/objective/manage/pageList`,
      method: 'GET',
      params,
    });
  },
  // 目标列表
  getAdvertiserList(params) {
    return request({
      baseURL: module,
      url: `/objective/manage/advertiser`,
      method: 'GET',
      params,
    });
  },
  // 新建目标
  addAdTargetList(data) {
    return request({
      baseURL: module,
      url: `/objective/manage/add`,
      method: 'POST',
      data,
    });
  },
  // 修改目标
  editAdTargetList(data) {
    return request({
      baseURL: module,
      url: `/objective/manage/update`,
      method: 'PUT',
      data,
    });
  },
  // 获取目标详情
  getAdTargetDetail(id) {
    return request({
      baseURL: module,
      url: `/objective/manage/detail/${id}`,
      method: 'GET',
    });
  },
  // 删除目标
  deleteAdTarget(id) {
    return request({
      baseURL: module,
      url: `/objective/manage/remove/${id}`,
      method: 'DELETE',
    });
  },
  // 获取目标看板列表
  getTargetBoardList(params) {
    return request({
      baseURL: module,
      url: `/objective/manage/board/pageList`,
      method: 'GET',
      params,
    });
  },

  // 昨日异常广告主-查询接口
  getYesterdayList(params) {
    return request({
      baseURL: module,
      url: '/objective/manage/yesterday/pageList',
      method: 'get',
      params,
    });
  },
  // 昨日异常广告主-设置预警值接口
  putYesterdayWarningApi(data) {
    return request({
      baseURL: module,
      url: '/objective/manage/yesterday/warning',
      method: 'put',
      data,
    });
  },

  // 今日异常广告主-查询接口
  getTodayList(params) {
    return request({
      baseURL: module,
      url: '/objective/manage/today/pageList',
      method: 'get',
      params,
    });
  },
};
