import request from '@/utils/request';
import { HJK } from '@/constant/api';
import { set } from 'lodash';

export default {
  getHjkTagListApi(params) {
    return request({
      baseURL: HJK,
      url: `/sysAdmin/hjkTag/page`,
      method: 'get',
      params,
    });
  },
  addOrUpdateHjkTagApi(data) {
    return request({
      baseURL: HJK,
      url: `/sysAdmin/hjkTag`,
      method: 'post',
      data,
    });
  },
  setHjkTagRelationApi(data) {
    return request({
      baseURL: HJK,
      url: `/sysAdmin/hjkTag/insertRelation`,
      method: 'post',
      data,
    });
  },
  getAccountIdListByTagIdApi(params) {
    return request({
      baseURL: HJK,
      url: `/sysAdmin/hjkTag/findAccountIdListByTagId`,
      method: 'get',
      params,
    });
  },
  getHjkTagByTagIdApi(params) {
    return request({
      baseURL: HJK,
      url: `/sysAdmin/hjkTag/${params.id}`,
      method: 'get',
    });
  },
  deleteHjkTagByTagIdApi(params) {
    return request({
      baseURL: HJK,
      url: `/sysAdmin/hjkTag/${params.id}`,
      method: 'delete',
    });
  },
};
