import _ from "lodash";

let Tool = {
  initLayout() {
    let $mainContainer = document.getElementById("mainContainer");
    let $mainTab = document.getElementById("navTab");
    let height = $mainContainer.offsetHeight - $mainTab.offsetHeight;

    let searchPanelHeight = document.getElementsByClassName("panel-search")[0].offsetHeight;

    document.getElementsByClassName("panel-main")[0].style.height = (height - searchPanelHeight - 80) + "px";


  },
  selectDom(selector) {//选择dom
    let eType = "queryeAll";
    if(selector.indexOf("#") === 0) {
      eType = "getElementById";
      selector = selector.substr(1, selector.length);
    }
    if(selector.indexOf(".") === 0) {
      eType = "getElementsByClassName";
      selector = selector.substr(1, selector.length);
    }
    console.log("selector" + selector + ":" + eType);
    return document[eType](selector);
  },
  hasClass(element, csName) {
    let tmp = element.getAttribute("class") || "";
    return tmp.match(RegExp("(\\s|^)" + csName + "(\\s|$)")); //使用正则检测是否有相同的样式
  },
  addClass(element, csName) {
    let tmp = element.getAttribute("class") || "";
    if(!this.hasClass(element, csName)) {
      element.setAttribute("class", tmp + " " + csName);
    }
  },

  removeClass(element, csName) {
    let tmp = element.getAttribute("class") || "";
    let newClass = tmp.replace(RegExp("(\\s|^)" + csName + "(\\s|$)"), " "); //利用正则捕获到要删除的样式的名称，然后把他替换成一个空白字符串
    element.setAttribute("class", newClass);
  },
  addEventListener(el, ev, fn) {
    if(el.addEventListener) {
      el.addEventListener(ev, fn, true);
    } else if(el.attachEvent) {
      el.attachEvent(ev, fn, true);
    } else {
      el["on" + ev] = fn;
    }
  },
  removeEventListener(el, ev, fn) {
    if(el.addEventListener) {
      el.removeEventListener(ev, fn);
    } else if(el.attachEvent) {
      el.detachEvent(ev, fn);
    } else {
      el["on" + ev] = null;
      delete el["on" + ev];
    }
  },
  /**
   * 重定义尺寸
   * @param {*} iframe
   */
  resizeIframe(iframe) {
    if(iframe) {
      var iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
      if(iframeWin.document.body) {

        iframe.height = iframeWin.document.documentElement.offsetHeight;

      }
    }
  },
  /**
   * 滚动至顶
   */
  scrollTop(el) {
    let $mainContent = el;
    let scrollid = $mainContent.getAttribute("scrollid");
    if(scrollid) {
      clearInterval(scrollid);
    }
    let times = 100;
    let initTop = $mainContent.scrollTop || 0;
    let step = initTop / times;
    step = step < 1 ? 1 : step;
    let lastTop = null;
    let interval = setInterval(() => {
      let scrollTop = $mainContent.scrollTop || 0;
      let tmp = scrollTop - step;
      if(scrollTop <= 0 || tmp == lastTop) {
        clearInterval(interval);
        $mainContent.removeAttribute("scrollid");
        return;
      }
      lastTop = tmp < 0 ? 0 : tmp;
      $mainContent.scrollTop = lastTop;
    }, 10);
    $mainContent.setAttribute("scrollid", interval);
    return interval;
  },
  scrollBottom(el) {

    let $mainContent = el;
    let scrollid = $mainContent.getAttribute("scrollid");
    if(scrollid) {
      clearInterval(scrollid);
    }


    let times = 100;
    let initTop = $mainContent.scrollTop || 0;
    let maxTop = $mainContent.scrollHeight || 0;
    let offsetHeight = $mainContent.offsetHeight || 0;
    let step = (maxTop - initTop) / times;
    step = step < 1 ? 1 : step;
    let lastTop = null;
    let interval = setInterval(() => {
      let scrollTop = $mainContent.scrollTop || 0;

      let tmp = scrollTop + step;
      if(scrollTop + offsetHeight >= maxTop || tmp == lastTop) {
        clearInterval(interval);
        $mainContent.removeAttribute("scrollid");
        return;
      }

      lastTop = tmp > maxTop ? maxTop : tmp;
      $mainContent.scrollTop = lastTop;
    }, 10);
    $mainContent.setAttribute("scrollid", interval);
    return interval;
  },
  printPDF(pdfUrl) {
    var iframe = this._printIframe;
    if(!this._printIframe) {
      iframe = this._printIframe = document.createElement("iframe");

      iframe.style.display = "none";
      iframe.onload = function() {
        setTimeout(function() {
          iframe.focus();
          iframe.contentWindow.print();
        }, 1);
      };
      document.body.appendChild(iframe);

    }

    iframe.src = pdfUrl;
  },
  objectEquals(a, b, fields) {
    if(a == null || b == null) {
      return a == b;
    }
    if(a.constructor != b.constructor) {
      return false;
    }
    if(a.constructor === String || a.constructor === Number || a.constructor === Boolean) {
      return a == b;
    }
    if(a.constructor === Array) {
      if(a.length != b.length) {
        return false;
      }
      for(var i = 0; i < a.length; i++) {
        let tmpFlag = this.objectEquals(a[i], b[i]);
        if(!tmpFlag) {
          return false;
        }
      }
      return true;
    }
    if(a.constructor != Object) {
      console.log(a.constructor + ":");
      return false;
    }
    let compareFields = null;
    if(fields) {
      if(fields.constructor === Array) {
        compareFields = fields;
      }
    }

    for(var attr in a) {
      let val = a[attr];
      if(compareFields != null && !compareFields.includes(attr)) {
        continue;
      }
      let flag = this.objectEquals(val, b[attr]);
      if(!flag) {

        return false;
      }
    }
    return true;
  },
  /**
   * 获取树节点
   * @param {*} nodes
   * @param {*} nodeId
   */
  getTreeNode(nodes, nodeId) {
    for(let i in nodes) {
      let node = nodes[i];
      if(node.id === nodeId + "") {
        return node;
      }
      if(node.children && node.children.length > 0) {
        let n = this.getTreeNode(node.children, nodeId);
        if(n != null) {
          return n;
        }
      }
    }
    return null;
  },
  getNodePaths(_node, rootNodes) { //获取节点路径
    let nodes = [];
    let allNodes = [];

    (function tree2Array(treeNodes) {
      for(let i = 0; i < treeNodes.length; i++) {
        let treeNode = treeNodes[i];
        allNodes.push(treeNode);
        if(treeNode.children != null && treeNode.children.length > 0) {
          tree2Array(treeNode.children);
        }
      }
    })(rootNodes);

    function getNodes(node) {
      if(!node.nodeVal) {
        return [];
      }
      nodes.push(node.nodeVal);
      if(node.nodeVal.pid != null) {
        let pNode = allNodes.filter(ctg => ctg.nodeVal != null && ctg.nodeVal.id == node.nodeVal.pid);
        if(pNode.length > 0) {
          return getNodes(pNode[0]);
        }
      }
      return nodes;
    }

    getNodes(_node);
    return nodes.reverse();
  },
  uuid() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for(var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
  },
  getScrollTop() {
    let scrollTop = 0;
    if(document.documentElement && document.documentElement.scrollTop) {
      scrollTop = document.documentElement.scrollTop;
    } else if(document.body) {
      scrollTop = document.body.scrollTop;
    }
    return scrollTop;
  },
  simpleSummary(param, summaryProperies) {
    const {
      columns,
      data
    } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if(index === 0) {
        sums[index] = "合计";
        return;
      }
      if((summaryProperies + ",").indexOf(column.property + ",") < 0) {
        return;
      }
      const values = data.map(item => Number(item[column.property]));
      if(!values.every(value => isNaN(value))) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if(!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] = Math.round(sums[index] * 100) / 100;
      } else {
        sums[index] = "";
      }
    });
    return sums;
  },
  getDateRange(daterange) { //转换为日期段
    if(daterange == null || daterange.length < 1) {
      return null;
    }
    return {
      startDate: daterange[0],
      endDate: daterange.length > 1 ? daterange[1] : null
    };
  },
  saveQueryParams(key, p) {
    window.localStorage.setItem("qp:" + key, JSON.stringify(p));
  },
  getQueryParams(key) {
    let qp = window.localStorage.getItem("qp:" + key);
    try {
      return JSON.parse(qp);
    } catch(e) {
      console.warn("parse qp error" + e.message);
      return null;
    }
  },
  getFirstDateRange(qp, field = "dateRanges") {
    let drs = (qp || {})[field];
    let key = null;
    let val = [];
    for(let _key in drs) {
      key = _key;
      let dr = drs[_key];

      if(dr != null) {
        let startDate = dr.startDate;
        let endDate = dr.endDate;
        if(!startDate) {
          break;
        }
        if(!endDate) {
          val = [new Date(startDate), null];
          break;
        }
        val = [new Date(startDate), new Date(endDate)];
      }
      break;
    }
    return {
      key: key,
      value: val
    };
  },
  confuseMobile(mobileNo) { //混淆手机号码
    if(mobileNo.length < 11) return mobileNo;
    let m = mobileNo.substring(0, 3) + "****" + mobileNo.substring(7);
    return m;
  },
  /**
   * 指定排序
   * @param arr 要排序的数组
   * @param index 要排序的元素索引
   * @param toIndex 要插入到哪个位置
   * @param key 指定排序的键
   */
  putSort(arr, index, toIndex, key = "sort") {
    const maxLength = arr?.length;
    // index -= 1;
    toIndex -= 1;
    // 设置的阈值大于 length 则不进行排序
    if(maxLength < index || maxLength < toIndex) return;
    // const newData = pullAt(arr, index);
    arr.splice(toIndex, 0, arr.splice(index, 1)?.[0]);
    return arr?.map((v, i) => {
      v[key] = i + 1;
      return v;
    });
  },
  // 计算文件大小函数(保留两位小数),Size为字节大小
  // size：初始文件大小
  getfilesize(size) {
    if(!size)
      return "";

    var num = 1024.00; //byte

    if(size < num)
      return size + "B";
    if(size < Math.pow(num, 2))
      return (size / num).toFixed(2) + "K"; //kb
    if(size < Math.pow(num, 3))
      return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
    if(size < Math.pow(num, 4))
      return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
    return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
  },
  formatDate(timestamp, formatLayout = "Y-m-d H:i:s") {
    let formatDate = "";
    formatLayout = formatLayout.toUpperCase();
    timestamp = (timestamp + "").length > 11 ? timestamp : timestamp * 1000;
    let time = new Date(timestamp);
    for(let i in formatLayout) {
      if(["Y", "M", "D", "W", "H", "I", "S"].indexOf(formatLayout[i]) >= 0) {
        switch(formatLayout[i]) {
          case "Y":
            formatDate += time.getFullYear();
            break;
          case "M":
            formatDate += time.getMonth() >= 9 ? time.getMonth() + 1 : "0" + (time.getMonth() + 1);
            break;
          case "D":
            formatDate += time.getDate() > 9 ? time.getDate() : "0" + time.getDate();
            break;
          case "W":
            formatDate += time.getDay() == 0 ? 7 : time.getDay();
            break;
          case "H":
            formatDate += time.getHours() > 9 ? time.getHours() : "0" + time.getHours();
            break;
          case "I":
            formatDate += time.getMinutes() > 9 ? time.getMinutes() : "0" + time.getMinutes();
            break;
          case "S":
            formatDate += time.getSeconds() > 9 ? time.getSeconds() : "0" + time.getSeconds();
            break;
        }
      } else {
        formatDate += formatLayout[i];
      }
    }

    return formatDate;
  },
  roundToNumber(num, precision = 2) {
    return _.toFinite(_.round(_.toFinite(num), precision));
  }

};

export default Tool;
