import api from '../api/proxy/LiveAnalyze'
import common from './common'

const {
  getLiveMessage,
  getCommentAnalysis,
  getHotCarType,
  getCommentList,
  getAudience,
  getAnalysisChart,
  getHotWord,
  getAudienceAdress,
  getLiveTime,
  getCarCommentList,
} = api

export default {
  namespaced: true,
  state: {
    liveRecord: {},
    liveComment: [],
    tableLoading: false,
    commentList: {
      list: [],
      pagination: {}
    },
    getAudience: {},
    hotCarType: [],
    analysisLine: {},
    commentTotal: '',
    room_id: '',
    author_id: '',
    current: 1,
    page_size: 25,
    loading: false,
    noMore: false,
    word: '',
    hotWord: [],
    fansStat: {},
    audienceAdress: [],
    begin_time: '',
    end_time: '',
    live_count: [],
    jubgeComment: true
  },
  mutations: {
    changeState(state, payload) {
      const {
        data,
        name
      } = payload
      state[name] = data
    },
    CHANGE_LOADING: (state, result) => {
      state.tableLoading = result
    },
    CHANGE_LINE_CHART: (state, payload) => {
      const { data } = payload;
      const nameTips = ['观看人数', '点赞量', '评论人数', '送礼人数']
      const lineData = [data.watch_cnt, data.like_count, data.comment_user_count, data.gift_uv_count]
      const time = data.time
      const d = {
        nameTips, lineData, time
      }
      state.analysisLine = d;
    },
    CHANGE_LIVE_COMMENT: (state, payload) => {
      const { data, name } = payload;
      state.liveComment = data
      // state.commentTotal = numEdit(total)
    },
    CHANGE_COMMENT_LIST: (state, payload) => {
      const { data } = payload;
      if (state.current == 2) {
        state.commentList = data
      } else {
        state.commentList.pagination = data.pagination;
        state.commentList.list = [...state.commentList.list, ...data.list]
      }
    },
    CHANGE_FANS_STAT(state, payload) {
      let { data } = payload;
      if (!data.count.length) {
        state.fansStat = null
        return;
      }
      let total = data.count[0] + data.count[1]
      let man = 0
      let girl = 0
      if (data.gender[0] == 1) {
        man = (data.count[0] / total).toFixed(2)
        girl = (data.count[1] / total).toFixed(2)
      } else {
        man = (data.count[1] / total).toFixed(2)
        girl = (data.count[0] / total).toFixed(2)
      }
      state.fansStat = {
        gender: { percentage: [man, girl], desc: ['男', '女'] },
      };
    },
    CHANGE_LIVE_RECORD(state, payload) {
      const { data } = payload;
      const { timeInterval, numEdit } = common
      let arr = [];
      for (let key in data) {
        const arrDate = data[key]
        for (let i = 0; i < arrDate.length; i++) {
          let obj = {
            room_id: arrDate[i].room_id,
            publish_time: (arrDate[i].publish_time).slice(11),
            time: timeInterval(arrDate[i].publish_time, arrDate[i].finish_time),
            count: numEdit(arrDate[i].user_count),
            statistics_max_online_user: arrDate[i].statistics_max_online_user
          }
          arr.push(obj)
        }

      }
      state.live_count = arr
    },
    CHANGE_HOTCAR_PICTURE(state, payload) {
      const { data } = payload;
      let count = data.map(item => item.count);
      count.sort(function (a, b) {
        return b - a;
      })
      for (let i = 0; i < data.length; i++) {
        data[i].image = data[i].image && (process.env.VUE_APP_OSS_HOST + '/' + data[i].image);
        data[i].rate = data[i].count / count[0] * 80
      }
      state.hotCarType = data
    },
    CHANGE_PROVINCE(state, payload) {
      const { data } = payload;
      let count = data.map(item => item.num);
      count.sort(function (a, b) {
        return b - a;
      })
      for (let i = 0; i < data.length; i++) {
        data[i].image = process.env.VUE_APP_OSS_HOST + '/' + data[i].image;
        data[i].rate = data[i].num / count[0] * 80
        data[i].tips = (data[i].count * 100).toFixed(1)
      }
      state.audienceAdress = data
    }
  },

  actions: {
    getLiveRecordAnaly({
      commit, state
    }) {
      const { room_id } = state
      return this._vm.$api.proxy.LiveAnalyze.getLiveMessage({
        roomId: room_id
      })
        .then((res) => {
          commit('changeState', {
            data: res.data,
            name: 'liveRecord'
          })
          commit('changeState', {
            data: res.data.author_id,
            name: 'author_id'
          })
        })
    },
    getCommentAnalysis({
      commit, state
    }) {
      const { room_id } = state
      return this._vm.$api.proxy.LiveAnalyze.getCommentAnalysis(room_id)
        .then((res) => {
          commit('CHANGE_LIVE_COMMENT', {
            data: res.data,
            name: 'liveComment'
          })
        })
    },
    getCommentHot({
      commit, state
    }) {
      const { room_id } = state
      return this._vm.$api.proxy.LiveAnalyze.getHotCarType(room_id)
        .then((res) => {
          commit('CHANGE_HOTCAR_PICTURE', {
            data: res.data,
            name: 'hotCarType'
          })
        })
    },
    getCommentList({
      commit, state
    }) {
      const { current, page_size, room_id, word, jubgeComment } = state
      commit('changeState', { data: current + 1, name: 'current' })
      commit('changeState', { data: true, name: 'loading' })
      let precise = '';
      if (jubgeComment) {
        precise = this._vm.$api.proxy.LiveAnalyze.getCommentList
      } else {
        precise = this._vm.$api.proxy.LiveAnalyze.getCarCommentList
      }
      return precise({ room_id, current, page_size, word })
        .then((res) => {
          commit('CHANGE_COMMENT_LIST', {
            data: res.data,
          })
          setTimeout(() => {
            if (current >= res.data.pagination.pages) {
              commit('changeState', {
                data: true,
                name: 'noMore'
              })
            }
            commit('changeState', {
              data: false,
              name: 'loading'
            })
          }, 200)
        })
    },
    getAudience({
      commit, state
    }) {
      const { room_id } = state
      return this._vm.$api.proxy.LiveAnalyze.getAudience(room_id)
        .then((res) => {
          commit('CHANGE_FANS_STAT', { data: res.data });
        })
    },
    getAnalysisChart({
      commit, state
    }) {
      const { room_id } = state
      return this._vm.$api.proxy.LiveAnalyze.getAnalysisChart(room_id)
        .then((res) => {
          commit('CHANGE_LINE_CHART', {
            data: res.data,
          })
        })
    },
    getHotWord({
      commit, state
    }) {
      const { room_id } = state
      return this._vm.$api.proxy.LiveAnalyze.getHotWord(room_id)
        .then((res) => {
          console.log("hotWord", res)
          let hotWord = (res.data || []).map(item => ({ name: item.word, value: item.frequece }))
          commit('changeState', {
            data: hotWord,
            name: 'hotWord'
          })
        })
    },
    getAudienceAdress({
      commit, state
    }) {
      const { room_id } = state
      return this._vm.$api.proxy.LiveAnalyze.getAudienceAdress(room_id)
        .then((res) => {
          commit('CHANGE_PROVINCE', {
            data: res.data || [],
            // name: 'audienceAdress'
          })
        })
    },
    getLiveCalender({
      commit, state
    }) {
      console.log(state)
      const { begin_time, end_time, author_id } = state;
      return this._vm.$api.proxy.LiveAnalyze.getLiveTime({ begin_time, end_time, author_id: author_id })
        .then((res) => {
          commit('CHANGE_LIVE_RECORD', {
            data: res.data,
          })
        })
    },
    // getCarCommentList({
    //   commit,state
    // }){
    //   const {current,page_size,room_id,word} = state;
    //   return this._vm.$api.proxy.LiveAnalyze.getCarCommentList(room_id)
    //   .then((res)=>{

    //   })
    // }
  }
}
