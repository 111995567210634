/**
 * 
 */
import AppService from './AppService'
import base from './base'
import core from './core'
import proxy from './proxy'

var context = require.context('./', false,/\.js$/);
let services = context.keys().filter(item => item !== './index.js')

let serviceMap = {}
var reg = new RegExp( '(\.\/)|(\.js)' , "g" )
services.forEach(s => {
    serviceMap[s.replace(reg,'')] = context(s).default;
});

Object.assign(serviceMap, { base, core, proxy })
export default serviceMap
