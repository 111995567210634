import request from '@/utils/request'
import exportExcel from '@/utils/exportExcel'

export default {
  // 获取播主列表
  fetchAuthorList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/v3/list`,
      method: 'GET',
      params
    })
  },
  // 导出经销商列表
  exportAuthorList(params) {
    return exportExcel({
      baseURL: '/bfz-dy',
      url: `/author/v3/excel`,
      method: 'GET',
      params
    })
  },
  // 获取主播分析-主播概况
  fetchAuthorDetail(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/v3`,
      method: 'GET',
      params
    })
  },
  // 新建播主分组
  addAuthorGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/group`,
      method: 'POST',
      data
    })
  },
  // 关注播主分组
  followAuthorGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/group/follow`,
      method: 'POST',
      data
    })
  },
  // 取消关注播主分组
  unfollowAuthorGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/group/unfollow`,
      method: 'POST',
      data
    })
  },
  // 复制分组和公转私数据源接口
  copySourceAutorGroup(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/group/copy`,
      method: 'GET',
      params
    })
  },
  // 复制播主分组
  copyAuthorGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/group/copy`,
      method: 'POST',
      data
    })
  },
  // 公转私
  changeAuthorGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/group/convert/private`,
      method: 'POST',
      data
    })
  },
  // 删除播主分组
  delAuthorGroup(params) {
    const { groupId } = params

    return request({
      baseURL: '/bfz-dy',
      url: `/author/group/${groupId}`,
      method: 'DELETE'
    })
  },
  // 获取播主分组详情
  fetchAuthorGroupDetail(params) {
    const { groupId } = params

    return request({
      baseURL: '/bfz-dy',
      url: `/author/group/detail/${groupId}`,
      method: 'GET'
    })
  },
  // 获取播主分组播主列表
  fetchAuthorGroupAuthorList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/group/detail/author/list`,
      method: 'GET',
      params
    })
  },
  // 导出播主分组播主列表
  exportAuthorGroupAuthorList(params) {
    return exportExcel({
      baseURL: '/bfz-dy',
      url: `/author/group/detail/author/excel`,
      method: 'GET',
      params
    })
  },
  // 删除播主分组下的播主
  deleteAuthorGroupAuthor(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/group`,
      method: 'DELETE',
      params
    })
  },
  // 重命名播主分组
  renameAuthorGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/group`,
      method: 'PUT',
      data
    })
  },
  // 获取播主分组创建人列表
  fetchAuthorGroupCreatorList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/group/creator`,
      method: 'GET',
      params
    })
  },
  // 获取播主公共分组列表
  fetchAuthorPublicGroupList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/group/list/commonGroup`,
      method: 'GET',
      params
    })
  },
  // 获取我的播主分组列表
  fetchMyAuthorGroupList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/my-author/group`,
      method: 'GET',
      params
    })
  },
  // 加入播主分组
  joinAuthorGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/group/join`,
      method: 'POST',
      data
    })
  }
}
