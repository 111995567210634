import RouteView from '../pages/layout/RouteView';

/**
 * 推广管理
 */
export default {
  path: '/deliverManages',
  name: 'deliverManages:deliverIndex',
  component: RouteView,
  meta: { title: '推广管理', icon: 'hdd' },
  redirect: '/deliverManage',
  children: [
    //投放协议管理
    {
      path: '/deliverManage',
      name: 'deliverManage:deliverIndex',
      component: () => import('@/pages/app/deliverManage/agreementManage/index'),
      meta: { title: '投放协议管理' },
    },
    //投放协议管理详情
    {
      path: '/deliverManage/detail',
      name: 'deliverManage:deliverDetail',
      component: () => import('@/pages/app/deliverManage/agreementManage/detail'),
      hidden: true,
      meta: { title: '投放协议管理详情' },
    },
    //任务管理
    {
      path: '/taskManage',
      name: 'taskManage:taskIndex',
      component: () => import('@/pages/app/deliverManage/taskManage/index'),
      meta: { title: '任务管理' },
    },
    //任务管理详情
    {
      path: '/taskManage/detail',
      name: 'taskManage:taskDetail',
      component: () => import('@/pages/app/deliverManage/taskManage/detail'),
      hidden: true,
      meta: { title: '任务管理详情' },
    },
    //开启投放列表
    {
      path: '/taskManage/openReleaseList',
      name: 'taskManage:taskOpenReleaseList',
      component: () => import('@/pages/app/deliverManage/taskManage/openReleaseList'),
      hidden: true,
      meta: { title: '开启投放列表' },
    },
    //任务详情
    {
      path: '/taskManage/taskFullDetail',
      name: 'taskManage:taskFullDetail',
      component: () => import('@/pages/app/deliverManage/taskManage/taskFullDetail'),
      hidden: true,
      meta: { title: '任务详情' },
    },
    //绑定项目
    {
      path: '/taskManage/bindProject',
      name: 'taskManage:bindProject',
      component: () => import('@/pages/app/deliverManage/taskManage/bindProject'),
      hidden: true,
      meta: { title: '绑定项目' },
    },
    //任务进度
    {
      path: '/taskSchedule/index',
      name: 'taskSchedule:projectIndex',
      component: () => import('@/pages/app/deliverManage/taskSchedule/index'),
      meta: { title: '任务进度' },
    },
    //任务预算和KPI详情
    {
      path: '/taskSchedule/detail',
      name: 'taskSchedule:projectDetail',
      component: () => import('@/pages/app/deliverManage/taskSchedule/detail'),
      hidden: true,
      meta: { title: '任务预算和KPI详情' },
    },

    //项目管理
    //项目管理列表
    {
      path: '/projectManage/index',
      name: 'projectManage:projectIndex',
      component: () => import('@/pages/app/deliverManage/projectManage/index'),
      meta: { title: '项目管理' },
    },
    //项目管理详情
    {
      path: '/projectManage/detail',
      name: 'projectManage:projectDetail',
      component: () => import('@/pages/app/deliverManage/projectManage/detail'),
      hidden: true,
      meta: { title: '项目管理详情' },
    },

    //项目进度
    {
      path: '/projectSchedule/index',
      name: 'projectSchedule:projectIndex',
      component: () => import('@/pages/app/deliverManage/projectSchedule/index'),
      meta: { title: '项目进度' },
    },

    //项目管理
    //项目管理列表
    {
      path: '/projectManage/index',
      name: 'projectManage:projectIndex',
      component: () => import('@/pages/app/deliverManage/projectManage/index'),
      meta: { title: '项目管理' },
    },
    //项目管理详情
    {
      path: '/projectManage/detail',
      name: 'projectManage:projectDetail',
      component: () => import('@/pages/app/deliverManage/projectManage/detail'),
      hidden: true,
      meta: { title: '项目管理详情' },
    },

    //项目进度
    {
      path: '/projectSchedule/index',
      name: 'projectSchedule:projectIndex',
      component: () => import('@/pages/app/deliverManage/projectSchedule/index'),
      meta: { title: '项目进度' },
    },

    //自动规则
    //自动规则管理
    {
      path: '/automaticRules/manage',
      name: 'automaticRules:rulesManage',
      component: () => import('@/pages/app/deliverManage/automaticRules/manage'),
      meta: { title: '自动规则管理' },
    },
    //自动规则执行记录
    {
      path: '/automaticRules/records',
      name: 'automaticRules:rulesRecords',
      component: () => import('@/pages/app/deliverManage/automaticRules/records'),
      meta: { title: '自动规则执行记录' },
    },

    // 目标管理
    {
      path: '/targetManage',
      name: 'targetManage',
      component: () => import('@/pages/app/deliverManage/targetManage'),
      meta: { title: '目标管理' },
    },
    {
      path: '/targetEdition',
      name: 'targetEdition',
      component: () => import('@/pages/app/deliverManage/targetManage/targetEdition'),
      meta: { title: '目标详情' },
    },
    {
      path: '/targetBoard',
      name: 'targetBoard',
      component: () => import('@/pages/app/deliverManage/targetManage/targetBoard'),
      meta: { title: '目标看板' },
    },

    //昨日异常广告主
    {
      path: '/abnormalAdvertiser/yesterday',
      name: 'abnormalAdvertiser:yesterday',
      component: () => import('@/pages/app/deliverManage/abnormalAdvertiser/Yesterday.vue'),
      meta: { title: '昨日异常广告主' },
    },
    //今日异常广告主
    {
      path: '/abnormalAdvertiser/today',
      name: 'abnormalAdvertiser:today',
      component: () => import('@/pages/app/deliverManage/abnormalAdvertiser/Today.vue'),
      meta: { title: '今日异常广告主' },
    },
  ],
};
