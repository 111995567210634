import request from "@/utils/request";

let baseURL = "/content-factory";
export default {
  getWorkOrderManagementList(params) {
    return request({
      baseURL,
      url: "/order/list",
      method: "get",
      params: params
    });
  },
  getOrderTypeIdList(params) {
    return request({
      baseURL,
      url: "/order/type",
      method: "get",
      params: params
    });
  },
  getPrincipalsList(params) {
    return request({
      url: "/report/user/business/select/principal-list",
      method: "get",
      params: params
    });
  },
  getSeriesIdList(params) {
    return request({
      baseURL,
      url: "/base/series/select",
      method: "get",
      params: params
    });
  },
  getOrderData(orderId) {
    return request({
      baseURL,
      url: `/order/${ orderId }`,
      method: "get"
    });
  },
  submitOrder(data) {
    return request({
      baseURL,
      url: "/order/add",
      method: "post",
      data
    });
  },
  updateOrder(data) {
    return request({
      baseURL,
      url: "/order/update",
      method: "put",
      data
    });
  },
  putOrderProcess(data) {
    return request({
      baseURL,
      url: "/order/process",
      method: "put",
      data
    });
  },
  getOrderProcess(orderId) {
    return request({
      baseURL,
      url: `/order/process/${ orderId }`,
      method: "get",
    });
  },
  getScriptList(params) {
    return request({
      baseURL,
      url: "/order/script/task/list",
      method: "get",
      params: params
    });
  },
  getProductList(params) {
    return request({
      baseURL,
      url: "/order/product/task/list",
      method: "get",
      params: params
    });
  },
  getTemplateList(params) {
    return request({
      baseURL,
      url: "/order/guideshoot/task/list",
      method: "get",
      params: params
    });
  },
  getAuthUsers(params) {
    return request({
      baseUrl: "/bfz-dy",
      url: "/auth/users",
      method: "get",
      params: params
    });
  },
  postOrderScriptTask(data) {
    return request({
      baseURL,
      url: "/order/script/task/add",
      method: "post",
      data
    });
  },
  postOrderProductTask(data) {
    return request({
      baseURL,
      url: "/order/product/task/update",
      method: "put",
      data
    });
  },
  postOrderTemplateTask(data) {
    return request({
      baseURL,
      url: "/order/guideshoot/task/update",
      method: "put",
      data
    });
  },
  postTaskGroup(data) {
    return request({
      baseURL,
      url: "/order/guideshoot/task/group",
      method: "post",
      data
    });
  },
  getGroupList(param) {
    return request({
      baseURL: "/ai-video-admin",
      url: "/gwp/module-group/get/list",
      method: "post",
      data: param
    });
  },
  getTaskDetail(taskId, type) {
    return request({
      baseURL,
      url: type == "template" ? `/order/guideshoot/task/${ taskId }` : `/order/${ type }/task/detail/${ taskId }`,
      method: "get",
    });
  },
  getAttachList(params) {
    return request({
      baseURL,
      url: "/order/attach/list",
      method: "get",
      params: params
    });
  },
  getSuggestionList(params) {
    return request({
      baseURL,
      url: "/order/suggestion/list",
      method: "get",
      params: params
    });
  },
  saveOrUpdate(data) {
    return request({
      baseURL,
      url: "/order/attach/saveOrUpdate",
      method: "post",
      data
    });
  },
  suggestionAdd(data) {
    return request({
      baseURL,
      url: "/order/suggestion/add",
      method: "post",
      data
    });
  },
  exportData(params, type) {
    return request({
      baseURL,
      url: type == "guideshoot" ? `/order/${ type }/task/export` : `/order/${ type }/task/excel`,
      method: "get",
      params,
      responseType: "blob"
    });
  },
  removeGroup(params) {
    return request({
      baseURL,
      url: "/order/guideshoot/task/group/remove",
      method: "delete",
      params,
    });
  },
  updateTest(data) {
    return request({
      baseURL,
      url: "/order/guideshoot/task/test",
      method: "put",
      data,
    });
  },
  updateStatus(data, type) {
    return request({
      baseURL,
      url: `/order/${ type }/task/process`,
      method: "put",
      data,
    });
  },
  putPhotoAddress(data) {
    return request({
      baseURL,
      url: "/order/product/task/photoAddress",
      method: "post",
      data,
    });
  },
  getScheduleList(params) {
    return request({
      baseURL,
      url: "/order/product/task/schedule",
      method: "get",
      params: params
    });
  },
  getBrandProgressList(params) {
    return request({
      baseURL,
      url: "/order/brandProgress",
      method: "get",
      params: params
    });
  },
  getScheduleDetailList(params) {
    return request({
      baseURL,
      url: "/order/product/task/schedule/detail",
      method: "get",
      params: params
    });
  },
  getStatusStatistics(params) {
    return request({
      baseURL,
      url: "/order/statusStatistics",
      method: "get",
      params: params
    });
  },
  getBrandProcessList(params, type) {
    return request({
      baseURL,
      url: `/order/${ type }/task/brandProcess`,
      method: "get",
      params: params
    });
  },
  getDirectorStatisticsList(params) {
    return request({
      baseURL,
      url: "/order/script/task/directorStatistics",
      method: "get",
      params: params
    });
  },
  getWorkerStatisticsList(params) {
    return request({
      baseURL,
      url: "/order/product/task/workerStatistics",
      method: "get",
      params: params
    });
  },
  getTotalStatistics(params) {
    return request({
      baseURL,
      url: "/order/guideshoot/task/test/totalStatistics",
      method: "get",
      params: params
    });
  },
  getNoPassStatisticsList(params) {
    return request({
      baseURL,
      url: "/order/guideshoot/task/test/noPassStatistics",
      method: "get",
      params: params
    });
  },
  getProductTrend(params) {
    return request({
      baseUrl: "/bfz-dy",
      url: "/module/conversion/product-trend",
      method: "get",
      params: params
    });
  },
  getUserAction(params) {
    return request({
      baseUrl: "/bfz-dy",
      url: "/module/conversion/user-action",
      method: "get",
      params: params
    });
  },
  getStoryboardTrend(params) {
    return request({
      baseUrl: "/bfz-dy",
      url: "/module/conversion/storyboard-trend",
      method: "get",
      params: params
    });
  },
  getTagRate(params) {
    return request({
      baseUrl: "/bfz-dy",
      url: "/module/conversion/tag-rate",
      method: "get",
      params: params
    });
  },
};
