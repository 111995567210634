import request from '@/utils/request';

export default {
  getList (params) {
    return request({
      url: `/training/query`,
      method: 'get',
      params: params
    });
  },
  getDiagnosticData (params) {
    return request({
      url: `/diagnostic/report`,
      method: 'get',
      params: params
    });
  },
  getPrincipals () {
    return request({
      url: `/principal/list`,
      method: 'get'
    });
  },
  getGroupList (params) {
    return request({
      url: `/training/group-list`,
      method: 'get',
      params: params
    });
  },
  getAccountList (params) {
    console.log(params);
    let url = 'training/activities/author';
    switch (params.type) {
      case 'author':
        url = 'training/activities/author';
        break;
      case 'live':
        url = 'training/live';
        break;
      case 'aweme':
        url = 'training/aweme';
        break;
    }
    return request({
      url: url,
      method: 'get',
      params: params.data
    });
  },
  getData (params) {
    let url = 'training/activities/unauthorized/count';
    return request({
      url: url,
      method: 'get',
      params: params.data
    });
  }
};
