import RouteView from '@/pages/layout/RouteView';

/**
 * AIGC
 */
export default {
  path: '/aigc',
  name: 'aigc',
  component: RouteView,
  meta: { title: 'AIGC', icon: 'global' },
  redirect: { name: 'promptDebugList' },
  children: [
    // prompt调试
    {
      path: 'promptDebug',
      name: 'promptDebug',
      component: () => import('@/pages/app/AIGC/promptDebug/promptDebug'),
      meta: { title: 'prompt调试' },
    },
    // prompt调试列表
    {
      path: 'promptDebugList',
      name: 'promptDebugList',
      component: () => import('@/pages/app/AIGC/promptDebug/promptDebugList'),
      meta: { title: 'prompt调试列表' },
    },
    // prompt组合
    {
      path: 'promptCombo',
      name: 'promptCombo',
      component: () => import('@/pages/app/AIGC/promptCombo/promptCombo'),
      meta: { title: 'prompt组合' },
    },
    // prompt组合列表
    {
      path: 'promptComboList',
      name: 'promptComboList',
      component: () => import('@/pages/app/AIGC/promptCombo/promptComboList'),
      meta: { title: 'prompt组合列表' },
    },
  ],
};
