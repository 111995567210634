import request from '@/utils/request';
import { BFZ_BFF } from '@/constant/api';
const qs = require('qs');

// 获取模板列表
export const getTemplateComponentListApi = (params) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/temp/page`,
    method: 'get',
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};
// 获取模板详情
export const getTemplateComponentDetailApi = (params) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/temp`,
    method: 'get',
    params,
  });
};
// 新增模板
export const addTemplateComponentApi = (data) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/temp`,
    method: 'post',
    data,
  });
};
// 更新模板
export const updateTemplateComponentApi = (data) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/temp`,
    method: 'put',
    data,
  });
};

// 获取全部模板分类
export const getAllCategoryListApi = (params) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/category/list`,
    method: 'GET',
    params,
  });
};

// 获取素材/贴纸模板列表
export const getMaterialComponentListApi = (params) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/material/page`,
    method: 'get',
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};
// 上传svg
export const uploadSvgMaterialApi = (data) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/material/svg/parser`,
    method: 'post',
    data,
  });
};

// 新增素材/贴纸
export const addMaterialComponentApi = (data) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/material`,
    method: 'post',
    data,
  });
};
// 获取素材/贴纸详情
export const getMaterialDetailApi = (params) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/material/detail`,
    method: 'get',
    params,
  });
};
// 编辑素材/贴纸
export const updateMaterialComponentApi = (data) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/material`,
    method: 'put',
    data,
  });
};

// 获取图片列表
export const getImageComponentListApi = (params) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/image/page`,
    method: 'get',
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};
// 新增图片
export const addImageComponentApi = (data) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/image`,
    method: 'post',
    data,
  });
};
// 获取图片
export const getImageDetailApi = (params) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/image`,
    method: 'get',
    params,
  });
};
// 编辑图片
export const updateImageComponentApi = (data) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/image`,
    method: 'put',
    data,
  });
};

// 获取字体列表
export const getFontListApi = (params) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/font/page`,
    method: 'get',
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};
// 新增字体
export const addFontApi = (data) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/font`,
    method: 'post',
    data,
  });
};
// 获取字体详情
export const getFontDetailApi = (params) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/font`,
    method: 'get',
    params,
  });
};
// 编辑字体
export const updateFontApi = (data) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/font`,
    method: 'PUT',
    data,
  });
};

// 获取分组列表
export const getElementGroupListApi = (params) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/category/page`,
    method: 'get',
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};
// 新增分组
export const addElementGroupApi = (data) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/category/add`,
    method: 'post',
    data,
  });
};
// 修改分组
export const updateElementGroupApi = (data) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/category/update`,
    method: 'put',
    data,
  });
};
// 删除分组
export const deleteElementGroupApi = (params) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/category/delete`,
    method: 'delete',
    params,
  });
};

// 获取滤镜列表
export const getFilterListApi = (params) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/filter/page`,
    method: 'get',
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};
// 新增滤镜
export const addFilterApi = (data) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/filter`,
    method: 'post',
    data,
  });
};
// 编辑滤镜
export const updateFilterApi = (data) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/filter`,
    method: 'put',
    data,
  });
};
// 获取滤镜详情
export const getFilterDetailApi = (params) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/filter`,
    method: 'get',
    params,
  });
};
// 删除模板/文字
export const deleteTempApi = (id) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/temp?id=${id}`,
    method: 'delete',
  });
};
// 删除贴纸
export const deleteMaterialApi = (id) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/material?id=${id}`,
    method: 'delete',
  });
};
// 删除背景
export const deleteBgApi = (id) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/image?id=${id}`,
    method: 'delete',
  });
};
// 删除字体
export const deleteFontApi = (id) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/font?id=${id}`,
    method: 'delete',
  });
};
// 删除滤镜
export const deleteFilterApi = (id) => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/filter?id=${id}`,
    method: 'delete',
  });
};

// 元素管理操作人列表-模板/文字
export const getModifierListByTemplate = () => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/temp/modifierList`,
    method: 'GET',
  });
};

// 元素管理操作人列表-贴纸
export const getModifierListBySticker = () => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/material/modifierList`,
    method: 'GET',
  });
};

// 元素管理操作人列表-字体
export const getModifierListByFont = () => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/font/modifierList`,
    method: 'GET',
  });
};

// 元素管理操作人列表-滤镜
export const getModifierListByFilter = () => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/filter/modifierList`,
    method: 'GET',
  });
};

// 元素管理操作人列表-背景
export const getModifierListByBackground = () => {
  return request({
    baseURL: BFZ_BFF,
    url: `/design/image/modifierList`,
    method: 'GET',
  });
};
