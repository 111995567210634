/**
* 将服务端的素材信息转换成 WebSDK 需要的格式
*/
export default function transMediaList(data) {
  if (!data) return [];

  if (Array.isArray(data)) {
    return data.map((item) => {
      const basicInfo = item.MediaBasicInfo;
      const fileBasicInfo = item.FileInfoList[0].FileBasicInfo;
      const mediaId = basicInfo.MediaId;
      const result = {
        mediaId
      };
      const mediaType = basicInfo.MediaType
      result.mediaType = mediaType;

      if (mediaType === 'video') {
        result.video = {
          title: fileBasicInfo.FileName,
          duration: Number(fileBasicInfo.Duration),
          coverUrl: basicInfo.CoverURL
        };
        const spriteImages = basicInfo.SpriteImages
        if (spriteImages) {
          try {
            const spriteArr = JSON.parse(spriteImages);
            const sprite = spriteArr[0];
            const config = JSON.parse(sprite.Config);
            const SpriteSnapshotConfig = config.SpriteSnapshotConfig
            result.video.spriteConfig = {
              num: config.Num,
              lines: SpriteSnapshotConfig.Lines,
              cols: SpriteSnapshotConfig.Columns,
              cellWidth: SpriteSnapshotConfig.CellWidth,
              cellHeight: SpriteSnapshotConfig.CellHeight
            };
            result.video.sprites = sprite.SnapshotUrlList;
          } catch (e) {
            console.error(e);
          }
        }
      } else if (mediaType === 'audio') {
        result.audio = {
          title: fileBasicInfo.fileName,
          duration: Number(fileBasicInfo.duration),
          coverURL: '' // 您可以给音频文件一个默认的封面图
        }
      } else if (mediaType === 'image') {
        result.image = {
          title: fileBasicInfo.fileName,
          coverUrl: fileBasicInfo.fileUrl
        }
      }

      return result;
    });
  } else {
    return [data];
  }
}

// export default function transMediaList(data) {
//   if (!data) return [];

//   if (Array.isArray(data)) {
//     return data.map((item) => {
//       const basicInfo = item.MediaBasicInfo;
//       const fileBasicInfo = item.FileInfoList[0].FileBasicInfo;
//       const mediaId = basicInfo.MediaId;
//       const result = {
//         mediaId
//       };
//       const mediaType = basicInfo.MediaType
//       result.mediaType = mediaType;

//       if (mediaType === 'video') {
//         result.video = {
//           title: fileBasicInfo.FileName,
//           duration: Number(fileBasicInfo.Duration),
//           coverUrl: basicInfo.CoverURL
//         };
//         const spriteImages = basicInfo.SpriteImages
//         if (spriteImages) {
//           try {
//             const spriteArr = JSON.parse(spriteImages);
//             const sprite = spriteArr[0];
//             const config = JSON.parse(sprite.Config);
//             result.video.spriteConfig = {
//               num: config.Num,
//               lines: SpriteSnapshotConfig?.Lines,
//               cols: SpriteSnapshotConfig?.Columns,
//               cellWidth: SpriteSnapshotConfig?.CellWidth,
//               cellHeight: SpriteSnapshotConfig?.CellHeight
//             };
//             result.video.sprites = sprite.SnapshotUrlList;
//           } catch (e) {
//             console.log(e);
//           }
//         }
//       } else if (mediaType === 'audio') {
//         result.audio = {
//           title: fileBasicInfo.FileName,
//           duration: Number(fileBasicInfo.Duration),
//           coverURL: '' // 您可以给音频文件一个默认的封面图
//         }
//       } else if (mediaType === 'image') {
//         result.image = {
//           title: fileBasicInfo.FileName,
//           coverUrl: fileBasicInfo.FileUrl
//         }
//       }

//       return result;
//     });
//   } else {
//     return [data];
//   }
// }