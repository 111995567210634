<!-- 菜单 -->
<template>
  <div class="nav-top">
    <!--
    <a-menu mode="horizontal" style="lineHeight:64px;" :selected-keys="[domainIndex]" @select="onDomainSelected">
      <a-menu-item key="domain_base"><span class="ele-icon el-icon-ipyunyingguanli"></span><span>运营</span></a-menu-item>
      <a-menu-item key="domain_maintenance"><span class="ele-icon el-icon-ipyunweiguanli"></span><span>运维</span></a-menu-item>
      <a-menu-item key="domain_sys"><span class="ele-icon el-icon-ipshezhi"></span><span>设置</span></a-menu-item>
    </a-menu>-->
    <a-menu mode="horizontal" style="lineHeight:64px;" :selected-keys="[activeIndex]">
      <!--<a-menu-item key="0">
        <a-popover placement="bottom" trigger="hover">
          <div slot="content" style="width: 300px;">
            <ul class="msgUL">
              <li v-for="r in msgPage.data" :key="r.id" @click="onMsgClick(r.id)">
                <a href="javascript:void(0)">{{r.title}}</a>
                <span style="color: #a0a0a0;font-size: 12px;">{{r.msgOn}}</span>
              </li>
            </ul>
            <a-alert title="" v-if="msgPage.total<1" message="暂无"></a-alert>
            <router-link to="/msg">
              <a-button type="text">更多</a-button>
            </router-link>
          </div>
          <a-badge>
            <a-icon type="bell"/>
            <span class="info-icon-text" style="line-height: 64px;">消息中心</span>
          </a-badge>
        </a-popover>
      </a-menu-item>-->
      <a-sub-menu key="1">
        <template slot="title">
          <a-avatar :size="32" :src="avatar_url" v-if="avatar_url" style="margin-right:8px"></a-avatar>
          <a-icon type="user" v-else></a-icon>
          <span>{{ user.realname || "unknowuser" }}</span>
        </template>
        <a-menu-item-group>
          <template slot="title">--- 个人中心 ---</template>
          <a-menu-item key="1-2" @click="onPersonalInfo">个人信息</a-menu-item>
        </a-menu-item-group>
        <a-menu-item-group>
          <template slot="title">--- 系统设置 ---</template>
          <a-menu-item key="2-3" @click="onModifyPwd">修改密码</a-menu-item>
          <a-menu-item key="2-4" @click="logout">退出登录</a-menu-item>
        </a-menu-item-group>
      </a-sub-menu>
    </a-menu>
    <a-drawer title="个人信息" :width="520" :visible="personalInfoShowFlag"
              :body-style="{ paddingBottom: '80px' }"
              @close="()=>{personalInfoShowFlag = false;}">
      <personal-info ref="personalInfoForm"></personal-info>
    </a-drawer>
    <a-drawer title="密码修改" :width="520" :visible="resetPwdDiagShowFlag"
              :body-style="{ paddingBottom: '80px' }"
              @close="()=>{resetPwdDiagShowFlag = false;}">
      <modify-pwd ref="modifyPwdPanel" @saved="onModifyOk"></modify-pwd>
    </a-drawer>
  </div>
</template>

<style lang="less">
.nav-top {
  display: flex;
  justify-content: space-between;
  margin: 0 10px 0 10px;
}
</style>

<script>
import router from "@/router";
import { auth } from "@/utils";
import ModifyPwd from "../ModifyPwd";
import PersonalInfo from "../PersonalInfo";

let timer = null;
export default {
  components: {ModifyPwd, PersonalInfo},
  data() {
    return {
      activeIndex: "0-3",
      domainIndex: "domain_parking",
      permRouters: [],
      msgPage: {
        data: [],
        total: 0
      },
      isNest: false,
      resetPwdDiagShowFlag: false,
      personalInfoShowFlag: false,
      user: router.app.user || {realName: "匿名"},
      avatar_url: undefined,
    };
  },
  watch: {
    "$router.app.user": function(val) {
      this.user = val;
    }
  },
  methods: {
    onPersonalInfo() {
      this.personalInfoShowFlag = true;
      this.$nextTick(() => {
        this.$refs.personalInfoForm.$emit("init", {
          userId: this.user.id
        });
      });
    },
    onMsgClick(msgId) {
      this.$router.push({name: "msg:view", query: {id: msgId}});
    },
    linkTo(path) {
      this.$router.push(path);
    },
    logout() {
      this.$api.AppService.logout().then((res) => {
        if(res.code != 200) {
          this.$message.error(res.message);
        }
        auth.removeToken();
        this.$bus.$emit("app:logout");
        this.$router.app.user = null;
        this.$router.push("/login");
      });
    },

    onModifyPwd() {
      this.resetPwdDiagShowFlag = true;
    },
    onModifyOk() {
      this.$success({
        title: "提示",
        content: "修改成功！请重新登录",
        onOk: () => {
          this.$router.push("/login");
        }
      });
    },
    onDomainSelected(item) {
      this.$bus.$emit("app:domain:selected", item.key.replace("domain_", ""));
    },
    loadMsg() {
      let {user} = router.app;
      // 加载消息
      this.$api.base.MsgInfo.query(
          {
            pageDTO: {start: 0, pageSize: 5},
            readState: 0,
            receiverUserId: (user || {}).id
          },
          {
            openLoading: false
          }
      ).then(rsp => {
        this.msgPage = rsp;
      });
    }
  },
  destroyed() {
    if(timer) {
      timer = clearInterval(timer);
    }
  },
  mounted() {
    let self = this;
    if(this.$router.app.openMsg) {
      self.loadMsg();
      timer = setInterval(function() {
        // 加载消息
        self.loadMsg();
      }, 10000);
    }
    this.$api.AppService.getUserInfo().then(res => {
      console.log("获取头像信息", res);
      if(res.code == 200) {
        this.avatar_url = res.data.avatar_url;
        sessionStorage.setItem("avatar_url", res.data.avatar_url);
      }
    });
  }
};
</script>
