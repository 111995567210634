import request from '@/utils/request'
import exportExcel from '@/utils/exportExcel'

export default {
  // 获取经销商列表
  fetchDealerList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/list`,
      method: 'GET',
      params
    })
  },
  // 导出经销商列表
  exportDealerList(params) {
    return exportExcel({
      baseURL: '/bfz-dy',
      url: `/dealer/list/excel`,
      method: 'GET',
      params
    })
  },
  // 获取经销商分组负责人列表
  fetchDealerGroupLeaderList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: '/operator/weChatWorkInfo',
      method: 'GET',
      params
    })
  },
  // 获取经销商分组创建人列表
  fetchDealerGroupCreatorList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/creator`,
      method: 'GET',
      params
    })
  },
  // 获取经销商公共分组列表
  fetchDealerPublicGroupList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/public/group/list`,
      method: 'GET',
      params
    })
  },
  // 获取经销商分组详情
  fetchDealerGroupDetail(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/public/group/detail`,
      method: 'GET',
      params
    })
  },
  // 获取经销商分组下的经销商列表
  fetchDealerGroupDealerList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/public/group/dealerList`,
      method: 'GET',
      params
    })
  },
  // 导出经销商分组下的经销商列表
  exportDealerGroupDealerList(params) {
    return exportExcel({
      baseURL: '/bfz-dy',
      url: `/dealer/public/group/dealerList/excel`,
      method: 'GET',
      params
    })
  },
  // 加入经销商分组
  joinDealerGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/group/join`,
      method: 'POST',
      data
    })
  },
  // 新建经销商分组
  addDealerGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/group`,
      method: 'POST',
      data
    })
  },
  // 重命名经销商分组
  renameDealerGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/author/group`,
      method: 'PUT',
      data
    })
  },
  // 删除经销商分组
  deleteDealerGroup(params) {
    const { groupId } = params

    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/public/group/${groupId}`,
      method: 'DELETE'
    })
  },
  // 复制经销商公共分组
  copyDealerPublicGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/public/group/copy`,
      method: 'POST',
      data
    })
  },
  // 关注经销商分组
  followDealerGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/public/group/follow`,
      method: 'POST',
      data
    })
  },
  // 取消关注经销商分组
  unfollowDealerGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/public/group/unfollow`,
      method: 'POST',
      data
    })
  },
  // 公转私
  changeDealerGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/public/group/toPrivate`,
      method: 'POST',
      data 
    })
  },
  // 派生播主分组
  deriveAuthorGroup(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/public/group/createAuthorGroup`,
      method: 'POST',
      data
    })
  },
  // 移除经销商分组下的经销商
  removeDealerGroupDealer(data) {
    return request({
      baseURL: '/bfz-dy',
      url: `/dealer/group/removeDealer`,
      method: 'POST',
      data
    })
  },
  // 获取我的经销商分组列表
  fetchMyDealerGroupList(params) {
    return request({
      baseURL: '/bfz-dy',
      url: `/my-dealer/group`,
      method: 'GET',
      params
    })
  }
}
