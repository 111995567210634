import Vue from "vue";
import FormatUtil from "@/utils/FormatUtil";

let Dict = {
  code: {},
  store: {
    DATATYPE: [[1, '抖音'], [2, '快手']],
    TICKET_LABEL: [[1, '音浪'], [2, '快币']],
    DEVICE_ACCOUNT_STATUS: [[0, '正常'], [1, '锁定'], [2, '过期'], [-1, '不可用']],
    WXACCOUNT_STATUS: [[1, '登录中'], [2, '在线'], [3, '离线'], [4, '已解绑']],
    WXFRIEND_APPLY_STATUS: [[0, '待处理'], [1, '已添加'], [2, '拒绝'], [3, '过期']],
    OPERATOR_STATUS: [[0, '禁用'], [1, '启用']],
    AUTHORIZATION_STATUS: [[0, '未授权'], [1, '已授权'],[2, '授权失效'],[3, '授权过期'],[4, '正在检查用户一致性'],[5, '用户一致性检查报错'],[6, '需要授权的帐号与扫码帐号不一致']],
    SERVICEPLATFORM_STATUS: [[0, '未授权'],[1, '有效'], [2, '失效']],
  },
  getEntry: function (key, storeArr) {
    let arr = storeArr || []
    return arr.find((entry) => entry[0] == key)
  },
  getText: function (key, storeArr) {
    let tmp = this.getEntry(key, storeArr)
    return tmp ? tmp[1] : '-'
  },
  humanReadable: FormatUtil.humanReadable
}
Vue.prototype.$dict = Dict


export default Dict
