import RouteView from '../pages/layout/RouteView'

/**
 * 投放效果报表
 */
export default {
    path: "/releaseEffectReport",
    name: "releaseEffectReport",
    component: RouteView,
    meta: {title: "投放效果报表", icon: "project"},
    redirect: '/JLAdvMaterialManage',
    children: [
        //巨量广告素材报表
        {
            path: "/JLAdvMaterialManage",
            name: "JLAdvMaterialManage",
            component: () => import("@/pages/app/releaseEffectReport/JLAdvMaterialManage/index"),
            meta: { title: "巨量广告素材报表" }
        },
        // 橙子建站落地页报表
        {
            path: "/orangeStnLandingReport",
            name: "orangeStnLandingReport",
            component: () => import("@/pages/app/releaseEffectReport/orangeStnLandingReport/index"),
            meta: { title: "橙子建站落地页报表" }
        },
        
    ]
}