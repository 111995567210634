export default {
  /**
   * 检验文件后缀，判断文件类型
   * @param {String} file 文件地址
   * @param {Array} suffix_list 允许通过的后缀类型
   */
  fileSuffixTypeUtil(file, suffix_list = []) {
    let suffix = "";
    let result = "";

    try {
        let fileUrl = ''
        if(file.indexOf('?') > -1) {
            fileUrl = file.split('?')[0]
        } else {
            fileUrl = file
        }

        let file_arr = fileUrl.split('.')
        suffix = file_arr[file_arr.length - 1]
    } catch (err) {
        suffix = ''
    }

    if(!suffix) {
      return false;
    }

    result = suffix_list.some(function(item) {
      return item == suffix;
    });

    if(result) {
      return true;
    } else {
      return false;
    }
  },
  // 计算两个日期之间的天数 不含结束日期当天
  getDiffDay(date_1, date_2) {
    // 计算两个日期之间的差值
    let totalDays, diffDate;
    let myDate_1 = Date.parse(date_1);
    let myDate_2 = Date.parse(date_2);
    // 将两个日期都转换为毫秒格式，然后做差
    diffDate = Math.abs(myDate_1 - myDate_2); // 取相差毫秒数的绝对值

    totalDays = Math.floor(diffDate / (1000 * 3600 * 24)); // 向下取整
    // console.log(totalDays)

    return totalDays;
  }
};
